import { Component, OnInit } from '@angular/core';
import { Globals } from '../../sharedComponents/global-variables/globals';
import {Router} from '@angular/router';
import {NavbarComponent} from  '../../navbar/navbar.component';
import { HttpClient } from '@angular/common/http';
import {HomeService} from '../../services/home/home.service';
import {LandingService} from "../../services/landing/landing.service";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  screenSecurity = undefined;
  landingmessage: any = undefined;
  loadingPage = false;
  constructor(
    public globals: Globals, public router: Router, public navbar: NavbarComponent, public http: HttpClient, public home: HomeService, public landing: LandingService ) {
    //this.landingmessage = this.landing.getlandingmessage();
    // this.initializePage();
    // this.landingmessage = this.globals.landingmessage;
  }
  // keeps track of highlighting
  activeLink = -1;
  initializePage(){
    this.loadingPage = true;
    this.landing.getlandingmessage().subscribe(response => {
      this.loadingPage = false;
      //console.log(response);
      if (response.length >= 1) {
        var todaydate = new Date();
         const  response1 = response.filter(row => new Date(this.globals.formatDateStr(row.endDate, false, false)) >= new Date(this.globals.formatDateStr(todaydate, false, false)));
          this.landingmessage = response1;

      }
     }, error => {
      this.loadingPage = false;
    });

  }
   ngOnInit() {
  }
  openState(){
    window.open(this.globals.i18n.link.stateucinfo);
  }
}


// controls will have property to render/view, default to true, server says what the flags will be
