import { Component, OnInit } from '@angular/core';
import {Globals} from "../../sharedComponents/global-variables/globals";

@Component({
  selector: 'app-ivr-instructions',
  templateUrl: './ivr-instructions.component.html',
  styleUrls: ['./ivr-instructions.component.css']
})
export class IvrInstructionsComponent implements OnInit {

  constructor(public globals: Globals) {

  }

  ngOnInit(): void {
  }


}
