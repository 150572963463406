import { masks } from '../../masks';

let search = {
  caseNbr: {
    minLen: null,
    inputMaxLen: 15,
    maxLen: 15,
    mask: masks.maskCaseNbr.mask,
    maskMessage: masks.maskCaseNbr.maskMessage,
    maskToolTip: masks.maskCaseNbr.maskToolTip,
    required: false
  },
  gmIn: {
    minLen: null,
    inputMaxLen: 9,
    maxLen: 9,
    mask: masks.maskGmin.mask,
    maskMessage: masks.maskGmin.maskMessage,
    maskToolTip: masks.maskGmin.maskToolTip,
    required: true
  },
};

let form = {
  gmIn: {
    minLen: null,
    inputMaxLen: 9,
    maxLen: 9,
    mask: masks.maskGmin.mask,
    maskMessage: masks.maskGmin.maskMessage,
    maskToolTip: masks.maskGmin.maskToolTip,
    required: true
  },
  caseNbr: {
    minLen: null,
    inputMaxLen: 15,
    maxLen: 15,
    mask: masks.maskCaseNbr.mask,
    maskMessage: masks.maskCaseNbr.maskMessage,
    maskToolTip: masks.maskCaseNbr.maskToolTip,
    required: false
  },
  investigator: {
    minLen: null,
    inputMaxLen: 50,
    maxLen: 50,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  },
  vioDate: {
    minLen: null,
    inputMaxLen: null,
    maxLen: null,
    mask: masks.date.mask,
    maskMessage: masks.date.maskMessage,
    maskToolTip: masks.date.maskToolTip,
    required: true
  },
  businessUnit: {
    minLen: null,
    inputMaxLen: 30,
    maxLen: 30,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  },
  actionTaken: {
    minLen: null,
    inputMaxLen: 50,
    maxLen: 50,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  },
  actionDate: {
    minLen: null,
    inputMaxLen: null,
    maxLen: null,
    mask: masks.date.mask,
    maskMessage: masks.date.maskMessage,
    maskToolTip: masks.date.maskToolTip,
    required: false
  },
  eligReinsDate: {
    minLen: null,
    inputMaxLen: null,
    maxLen: null,
    mask: masks.date.mask,
    maskMessage: masks.date.maskMessage,
    maskToolTip: masks.date.maskToolTip,
    required: false
  },
  reinsDate: {
    minLen: null,
    inputMaxLen: null,
    maxLen: null,
    mask: masks.date.mask,
    maskMessage: masks.date.maskMessage,
    maskToolTip: masks.date.maskToolTip,
    required: false
  },
  vehPrgm: {
    minLen: null,
    inputMaxLen: 50,
    maxLen: 50,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  },
  vioMaintcomntTxt: {
    minLen: null,
    inputMaxLen: 255,
    maxLen: 255,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  },
};

let exportFile = {
  exportFileName:{
    minLen: null,
    inputMaxLen: null,
    maxLen: null,
    mask: masks.exportFileName.mask,
    maskMessage: masks.exportFileName.maskMessage,
    maskToolTip: masks.exportFileName.maskToolTip,
    required: false
  }
};
export {search, form, exportFile};
