<div class="searchContainer">
  <div class="container">
    <div class="row"></div>
    <h4>
      You have entered a GM Identification Number (GMIN) that is not found to be a GM employee
    </h4>

    <div class="row">
      <span>Please verify your GMIN and <a (click)="showcaptchapage()">login</a> again.</span>
    </div>
  </div>
  <br>
  <div  class="row centeredFlex">
    <a (click)="globals.getprivacyinfo()">Privacy Information</a>
  </div>
</div>
