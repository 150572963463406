<app-expandable [expandable]="false" title= "{{globals.i18n.title.message.text4}}">
  <div class ="searchContainer">
    <div class="row">
      <table>
        <p>
          <tr class="row trHeight">
            <img src="../assets/images/ivr/stop.gif" width="50px" style="padding-left: 10px">&nbsp;Is the GM Global Business Services Center for You?
          </tr><br><br>
          <tr class="row trHeight2">
            (1) If you are from a plant covered by the AutoSUB Program, generally, you will not need to use the IVR.  Your benefit will be automatically processed.
          </tr>
          <tr class="row trHeight2">
            (2) You must submit a sub application form if you are applying for:
          </tr>
          <tr>
            <ul>
                <li>Any week more than 60 days old</li>
                <li>Any partial week application with S&A or Workers' Compensation (WC) payments</li>
                <li class="padding">Any week with a State Unemployment Compensation (UC) denial for a reason other than insufficient wages in the base period</li>
            </ul>
          </tr>
        <tr class="row trHeight">
          <img src="../assets/images/ivr/question.gif" width="50px" height="50px" style="padding-left: 10px">&nbsp;How to apply for a SUBenefit Using the IVR?
        </tr><br><br>
        <tr class="row trHeight2">
          <a href="../assets/images/ivr/sub-apply_gmin.pdf" target="_blank"> Application Instructions </a>
        </tr>
        </p>
        <tr class="row trHeight3">
          <img src="../assets/images/ivr/check.gif" width="50px" style="padding-left: 10px">&nbsp;CHECK: Before you call the GM Global Business Services Center IVR
        </tr><br><br>
        <tr>
          <ul>
            <li>Know your GM Identification Number (GMIN)</li>
            <li>Make sure that you have your UC determination or UC check stub</li>
            <li>Be familiar with phone symbols</li>
          </ul>
          <p class="p1">* asterisk</p>
          <p class="p1"># pound sign</p>
        </tr>
        <tr class="row trHeight">
          <img src="../assets/images/ivr/phone.gif" width="50px" style="padding-left: 10px;">&nbsp;GO: Call the GM Global Business Services Center IVR at:<br>

        </tr> <br>
        <tr >
          <span class="p2">{{globals.i18n.title.message.text6}}</span>
        </tr>
        <tr>
          <ul>
            <li>Use a  touch-tone telephone or rotary dial telephone</li>
            <li>U.S. Hearing and Speech Support:  Dial 711 to be connected to a TRS assistant</li>
          </ul>
        </tr>
      </table>
    </div>
  </div>
</app-expandable>
<br>
<br>
<br>
<br>
