<div class="searchContainer">
  <div class="container">
    <div class="row">
      <h4>We could not process your request because the system is experiencing problems, please try again later.</h4>
      <h4>If you continue to experience this problem, please call the Layoffs and Unemployment Center at 1-800-584-2000</h4>
    </div>
    <div class="row">
      <app-button class="margin" type="primary" value="Go Back"
                  (click)="goback()" (keyup.enter)="goback()"
      ></app-button>
      <p>Please use the button to return to the home page.</p>
    </div>
    <div  class="row centeredFlex">
      <a (click)="globals.getprivacyinfo()">Privacy Information</a>
    </div>
  </div>
</div>
