<app-loading *ngIf="loadingPage"></app-loading>
<div class="searchContainer">
  <app-expandable *ngIf="systemErrorPage" [expandable]="false" [title]="globals?.i18n.title.mylayoffbenefits">
    <br>
    <app-system-error></app-system-error>
  </app-expandable>

  <!-- Benefit init -->
  <app-expandable *ngIf="benefitInitPage" [expandable]="false" [title]="globals?.i18n.title.benefitInit">
    <div class="container">
      <div class="row ">
        <p>
          You selected the option to apply for a weekly SUBenefit. Please remember that you must have applied for and
          received your State, Federal or T.R.A. Unemployment Compensation benefit for the layoff period you are
          applying or you must have exhausted your benefits within a current State benefit year, or the GM Layoffs and
          Unemployment Center must have a copy of your U.C. denial for insufficient wages.
        </p>
        <p>
          If your U.C. benefits are exhausted or you have such a denial, you must be actively seeking work through your
          State U.C agency. You should have the U.C. benefit form in front of you to answer the questions that the
          system will ask you.
        </p>
        <p>
          All your answers will be kept by the GM Layoffs and Unemployment Center as a part of your benefit history. You
          will be asked to confirm your information after you complete each question.
        </p>


        <form name="cisaben">
          <p>
            Please select the Sunday weekending date for the week for which you would like to apply.
          </p>
          <div class="col-md-3 col-sm-1 widthval">
            <app-select [label]="''"
                        [options]="dates1"
                        [(selected)]="datestem.valuetem"
                        [mandatory]="true"
                        [showKey]="false" [showValue]="true"
            ></app-select>
          </div>
          <p>
            To determine if you are eligible to apply using this website, check <a routerLink="/faq">FAQ</a>.
          </p>
          <app-button value="{{globals?.i18n.application.button.selectWeekendingDate}}"
                      (click)="onSubmitWeekendingDate()"
                      type="primary"
          ></app-button>
        </form>
        <br>

      </div>
      <div class="row centeredFlex">
        <a (click)="getprivacyinfo()">Privacy Information</a>
      </div>
      <br>
    </div>
  </app-expandable>

  <!-- Inquire success -->
  <app-expandable *ngIf="processBenefitPage" [expandable]="false"
                  [title]="globals?.i18n.title.mylayoffbenefits">
    <div class="container">
      <br>
      <h2>SUBenefit Information</h2>
      <br>

      <!-- Questions table -->
      <table class="mat-elevation-z2 searchResultsTable" [border]="1" *ngIf="ucQuestionOne || ucQuestionTwo || ucQuestionThree || ucQuestionFour">
        <!-- 1 -->
        <ng-container *ngIf="ucQuestionOne">
          <tr>
            <td nowrap="nowrap">
              <app-radio-button-group
                [labelPosition]="'after'" [buttonValuesString]="ucOneRadioVerbiage" [removeColon]="true"
                [(value)]="ucRadioAnswer"
              ></app-radio-button-group>
            </td>
            <td>
              <div class="row">
                <p>Our records indicate for weekending <b>{{processResult.weekEndDate}}</b>,
                the amount of your State, Federal or T.R.A. Unemployment Compensation should be <b>$ {{ucamount}}</b>.</p>
              </div>
              <div class="row">
                <p>You must have applied for and be eligible to receive this amount. </p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="row leftCellRow">
                If answered incorrect above
              </div>
            </td>
            <td>
              <div class="row">
                If incorrect, please enter the U.C. amount you received:
              </div> <br>
              <div class="row">
                $<app-textbox-control class="numberBoxWidth" [isButtonDisplayed]="false" [(value)]="ucDollarAmount"
                                      [validator]="submitValidator.addProperty('ucOneDollars')"
                ></app-textbox-control>
                <p>.</p>
                <app-textbox-control class="numberBoxWidth1" [isButtonDisplayed]="false" [(value)]="ucCentsAmount"
                                     [validator]="submitValidator.addProperty('ucOneCents')"
                ></app-textbox-control>
              </div>
            </td>
          </tr>
        </ng-container>

        <!-- 2 -->
        <ng-container *ngIf="ucQuestionTwo">
          <tr>
            <td>
              <app-radio-button-group
                [labelPosition]="'after'" [buttonValuesString]="ucTwoRadioVerbiage" [removeColon]="true"
                [(value)]="ucWaitingAnswer"
              ></app-radio-button-group>
            </td>
            <td>
              <div class="row">
                <p>Our records indicate for weekending <b>{{processResult.weekEndDate}}</b>,
                  you should have been serving a State Unemployment waiting week.</p>
              </div>
              <div class="row">
                <p>You must have applied for and be serving the State waiting week. </p>
              </div>
              <div class="row">
                <p>Did you serve the State waiting week?</p>
              </div>
            </td>
          </tr>
        </ng-container>

        <!-- 3 -->
        <ng-container *ngIf="ucQuestionThree">
          <tr>
            <td>
              <app-radio-button-group
                [labelPosition]="'after'" [buttonValuesString]="ucThreeRadioVerbiage" [removeColon]="true"
                [(value)]="exhaustedAnswer"
              ></app-radio-button-group>
            </td>
            <td>
              <div class="row">
                <p>Our records show that you have exhausted your U.C. benefits within your current benefit year.</p>
              </div>
              <div class="row">
                <p>If your U.C. benefits are exhausted,
                  you must be actively seeking work through your State U.C..  </p>
              </div>
              <div class="row">
                <p>Have you exhausted your U.C. benefits?</p>
              </div>
            </td>
          </tr>
        </ng-container>

        <!-- 4 -->
        <ng-container *ngIf="ucQuestionFour">
          <tr>
            <td>
              <app-radio-button-group
                [labelPosition]="'after'" [buttonValuesString]="ucFourRadioVerbiage" [removeColon]="true"
                [(value)]="denialAnswer"
              ></app-radio-button-group>
            </td>
            <td>
              <div class="row">
                <p>Our records show you currently have been denied U.C. due to insufficient earnings to qualify.</p>
              </div>
              <div class="row">
                <p>You must be actively seeking work through your State U.C. agency.</p>
              </div>
              <div class="row">
                <p>Have you been denied U.C. benefits due to insufficient earnings?</p>
              </div>
            </td>
          </tr>
        </ng-container>
      </table>

      <br><br>
      <strong>
        For the week of layoff for which you are applying, you must answer the following questions concerning any earnings you may have received.
      </strong>

      <!-- Holiday Pay table -->
      <table class="mat-elevation-z2 searchResultsTable" [border]="1" *ngIf="holidayPayFlag">
        <tr>
          <td nowrap="nowrap" class="leftCell">
            <app-radio-button-group
              [labelPosition]="'after'" [buttonValuesString]="holidayPayVerbiage" [removeColon]="true"
              [(value)]="holidayPayAnswer"
            ></app-radio-button-group>
          </td>
          <td>
            <div class="row">
              <p>Our records show that for weekending <b>{{processResult.weekEndDate}}</b>,
                you received <b>$ {{holidayPayAmount}}</b>
                in GM Holiday pay.</p>
            </div>
            <br>
          </td>
        </tr>
      </table>
      <div *ngIf="holidayPayFlag">
        <hr>
      </div>

      <!-- GM earnings table -->
      <table class="mat-elevation-z2 searchResultsTable" [border]="1" *ngIf="gmEarningsFlag">
        <tr>
          <td nowrap="nowrap" class="leftCell">
            <app-radio-button-group
              [labelPosition]="'after'" [buttonValuesString]="gmEarningsVerbiage" [removeColon]="true"
              [(value)]="gmEarningsAnswer"
            ></app-radio-button-group>
          </td>
          <td>
            <div class="row">
              <p>Our records show that for weekending <b>{{processResult.weekEndDate}}</b>,
                you received <b>$ {{gmEarningsAmount}}</b>
                in GM Earnings.</p>
            </div>
            <br>
          </td>
        </tr>
      </table>
      <div *ngIf="gmEarningsFlag">
        <hr>
      </div>

      <!-- Pension table -->
      <table class="mat-elevation-z2 searchResultsTable" [border]="1" *ngIf="pensionFlag">
        <tr>
          <td nowrap="nowrap" class="leftCell">
            <app-radio-button-group
              [labelPosition]="'after'" [buttonValuesString]="pensionVerbiage" [removeColon]="true"
              [(value)]="pensionAnswer"
            ></app-radio-button-group>
          </td>
          <td>
            <div class="row">
              <p>Our records show that for weekending <b>{{processResult.weekEndDate}}</b>,
                you received <b>$ {{pensionAmount}}</b>
                in Mandatory Pension and Social Security Amount.</p>
            </div>
            <br>
          </td>
        </tr>
      </table>
      <div *ngIf="pensionFlag">
        <hr>
      </div>

      <!-- Other earnings table -->
      <table class="mat-elevation-z2 searchResultsTable" [border]="1">
        <tr>
          <td nowrap="nowrap">
            <app-radio-button-group
              [labelPosition]="'after'" [buttonValuesString]="otherEarningsVerbiage" [removeColon]="true"
              [(value)]="otherEarningsAnswer"
            ></app-radio-button-group>
          </td>
          <td>
            <div class="row">
              <p>For the week of layoff for which you are applying,
                did you receive any earnings or other benefits from a non-GM source such as a full or part-time job,
                or self-employment?</p>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row leftCellRow">
              If you answered yes above
            </div>
          </td>
          <td>
            <div class="row">
              Please enter the dollar amount you received from the non-GM source that you received for the week for which you are applying for a SUBenefit.
            </div>
            <br>
            <div class="row">
              $<app-textbox-control class="numberBoxWidth" [isButtonDisplayed]="false" [(value)]="otherEarningsDollarsAmount"
                                    [validator]="submitValidator.addProperty('otherEarningsDollars')"
              ></app-textbox-control>
              <p>.</p>
              <app-textbox-control class="numberBoxWidth1" [isButtonDisplayed]="false" [(value)]="otherEarningsCentsAmount"
                                   [validator]="submitValidator.addProperty('otherEarningsCents')"
              ></app-textbox-control>
            </div>
          </td>
        </tr>
      </table>
      <div>
        <hr>
      </div>

      <app-button value="Submit SUBenefit" type="primary" (click)="submitSubenefit()"
      ></app-button>

    </div>
  </app-expandable>

  <!-- Inquire failure -->
  <app-expandable *ngIf="processBenefitErrorPage" [expandable]="false"
                  [title]="globals?.i18n.title.mylayoffbenefits">
    <div class="container">
      <br>
      <div class="row centeredFlex">
        <h2>SUBenefit Error</h2>
      </div>
      <div class="row">
        <p>
          Our records indicate that you are not eligible for weekending {{processResult.weekEndDate}}.
        </p>
      </div>
      <div class="row">
        <p>
          Due to: {{errorMessage}}
        </p>
      </div>
      <div class="row centeredFlex" *ngIf="processResult?.returnCode === '911'">
        <app-button routerLink="/mylayoffbenef/cisaMondetRequest" type="primary"
                    value="Setup Your Mondet"
        ></app-button>
      </div>
      <div class="row">
        <p>If you have any questions, please call the GM Layoffs and Unemployment Center at 1-800-584-2000.</p>
      </div>
      <div  class="tableContainer1">
        Click to return to My Layoff Benefits Menu
      </div>
      <div class="tableContainer1">
        <app-button class="margin" type="primary" value="{{globals?.i18n.title.mylayoffbenefits}}"
                    routerLink="/mylayoffbenef"
        ></app-button>
      </div>
      <br>
      <div class="row centeredFlex">
        <a (click)="getprivacyinfo()">Privacy Information</a>
      </div>
    </div>
  </app-expandable>

  <!-- Create success -->
  <app-expandable *ngIf="createSuccess" [expandable]="false"
                  [title]="globals?.i18n.title.mylayoffbenefits">
    <div class="container">
      <br>
      <div class="row centeredFlex">
        <h2>SUBenefit Application Result</h2>
      </div>
      <div class="row">
        <p>Your application for weekending <b>{{processResult.weekEndDate}}</b> has been accepted for processing. The system will continue to check for any changes in your eligibility and for any other calculation items</p>
      </div>
      <div class="row">
        <p>If the application has no discrepancies, the application will be transmitted to Payroll on {{dayOfWeek}}, {{draftDate}} for Gross-to-Net processing. The funds should then be made available the following Friday, {{fundsAvailableDate}}</p>
      </div>
      <div class="row">
        <p>You may continue to check on the status of your pending application daily and you may check on the actual processing of the benefit on Fridays using the Interactive Voice Response System or this Web site.</p>
      </div>
      <div class="row">
        <b>
          Please print this page for your reference.
        </b>
      </div>
      <div class="row centeredFlex">
        <app-button class="margin" type="primary" value="Add another Application"
                    (click)="resetPage()"
        ></app-button>
      </div>
    </div>
  </app-expandable>

  <!-- Create failure -->
  <app-expandable *ngIf="createFailure" [expandable]="false"
                  [title]="globals?.i18n.title.mylayoffbenefits">
    <div class="container">
      <br>
      <div class="row centeredFlex">
        <h2>SUBenefit Error</h2>
      </div>
      <div class="row">
        <p>
          The system has found the following problem with your application.
        </p>
      </div>
      <div class="row">
        <p>
          Your application for weekending {{createResult.weekEndDate}} has been rejected.
        </p>
      </div>
      <div class="row">
        <p>
          Due to: {{errorMessage}}
        </p>
      </div>
      <div class="row centeredFlex" *ngIf="createResult?.returnCode === '911'">
        <app-button routerLink="/mylayoffbenef/cisaMondetRequest" type="primary"
                    value="Setup Your Mondet"
        ></app-button>
      </div>
      <div class="row">
        <p>If you have any questions, please call the GM Layoffs and Unemployment Center at 1-800-584-2000.</p>
      </div>
      <div  class="tableContainer1">
        Click to return to My Layoff Benefits Menu
      </div>
      <div class="tableContainer1">
        <app-button class="margin" type="primary" value="{{globals?.i18n.title.mylayoffbenefits}}"
                    routerLink="/mylayoffbenef"
        ></app-button>
      </div>
      <br>
      <div class="row centeredFlex">
        <a (click)="getprivacyinfo()">Privacy Information</a>
      </div>
    </div>
  </app-expandable>

  <div style="padding: 1.3em;"></div>
</div>
