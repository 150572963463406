// *** Angular Modules ***
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Globals } from './sharedComponents/global-variables/globals';
import { FormChecking } from './sharedComponents/utilities/formChecking';
import { EmailGenerator } from './sharedComponents/utilities/emailGenerator';
//import {NgbModule} from '@ng-bootstrap/ng-bootstrap';


// *** Modals ***
import { ModalComponent } from './sharedComponents/modal/modal.component';
import { ModalService } from './services/modal/modal.service';
import { ErrorModalComponent } from './sharedComponents/modal/modalComponents/error/error-modal.component';
import { ConfirmationModalComponent } from './sharedComponents/modal/modalComponents/confirmation/confirmation-modal.component';


// *** Routing ***
import { AppRoutingModule } from './app-routing.module';

// *** View Components ***
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './pages/home/home.component';
import {FormsComponent} from './pages/forms/forms.component';
import {SalaryFormsComponent} from './pages/salary-forms/salary-forms.component';
import { HomeViewComponent } from './pages/home/home-view/home-view.component';
import { NoaccessComponent } from './pages/noaccess/noaccess.component';
import { PlaceholderComponent } from './pages/placeholder/placeholder.component';
import {LandingComponent} from  './pages/landing/landing.component';
import {FaqComponent} from  './pages/faq/faq.component';
import {MyLayoffBenefitsComponent} from  './pages/my-layoff-benefits/my-layoff-benefits.component';

import {PaymentHistoryComponent} from './pages/processandpaymenthistory/paymenthistory.component';

import {TspElectionComponent} from "./pages/my-layoff-benefits/tsp-election/tsp-election.component";
import {SetupMondetComponent} from './pages/my-layoff-benefits/setup-mondet/setup-mondet.component';
import {BenefitInitComponent} from "./pages/my-layoff-benefits/benefit-init/benefit-init.component";
import {FileUploadComponent} from './pages/my-layoff-benefits/file-upload/file-upload.component';
import {UploadPopupComponent} from './pages/my-layoff-benefits/file-upload/upload-popup/upload-popup.component';


import {SubPlanCountersComponent} from './pages/my-layoff-benefits/SubPlanCounters/SubPlanCounters.component';


// *** Common components ***
import { LoadingElemComponent } from './sharedComponents/loadingElement/loading-elem.component';
import { MaterialModule } from './material.module';
import { ExpandableContainerComponent } from './sharedComponents/expandableContainer/expandable-container.component';
import { ExpandableTab } from './sharedComponents/expandableTabs/tab/tab.component';
import { ExpandableTabsComponent } from './sharedComponents/expandableTabs/expandable-tabs.component';
import { TextBoxControlComponent } from './sharedComponents/textBoxControl/textBox-control.component';
import { InputSelectComponent } from './sharedComponents/inputSelect/input-select.component';
import { InputDatepickerComponent } from './sharedComponents/inputDatePicker/input-datepicker.component';
import { AppButtonComponent } from './sharedComponents/button/button.component';
import { ScreenMessageComponent } from './sharedComponents/screenMessage/screen-message.component';
import { ErrorInterceptor } from './services/interceptor/error.service';
import { DataSharingService } from './services/data-sharing/data-sharing.service';
import { MatTablinkComponent } from './sharedComponents/mat-tablink/mat-tablink.component';
import {InputCheckboxComponent} from './sharedComponents/inputCheckbox/input-checkbox.component';
import {InputTextareaComponent} from './sharedComponents/inputTextarea/input-textarea.component';
import {ToNumberPipe} from './sharedComponents/pipes/number.pipe';
import {ToNegativeCurrencyPipe} from './sharedComponents/pipes/negativeCurrency.pipe';
import {Status} from "tslint/lib/runner";
import { ScrollToTop } from "./sharedComponents/scrollToTop/scroll-top";
import { SessionTimer } from "./sharedComponents/sessionTimer/sessionTimer";
import { LabelLabelComponent} from "./sharedComponents/labelLabel/label-label.component";
import {SimpleTextboxComponent} from "./sharedComponents/simpleTextBox/simple-textbox.component";
import {InformativeModalComponent} from "./sharedComponents/modal/modalComponents/informative/informative-modal.component";
import { OptionsSelectComponent } from "./sharedComponents/optionsSelect/optionsSelect.component";
import { NoAccessComponent } from "./sharedComponents/noAccess/noAccess.component";
import {PrivacyModalComponent} from './sharedComponents/modal/modalComponents/privacy-modal/privacy-modal.component';

// *** Validation ***
import { FormValidator } from "./sharedComponents/formValidation/formValidator";
import { InputMultiSelectComponent } from './sharedComponents/input-multi-select/input-multi-select.component';
import { SinkComponent } from './sharedComponents/sink/sink.component';
import {DecimalPipe} from "@angular/common";
import {InputRadioButtonGroupComponent} from "./sharedComponents/inputRadioButtonGroup/input-radio-button-group.component";
import {NavbarItemComponent} from "./navbar/navbar-item.component/navbar-item.component";


//3rd party libs
import * as $ from 'jquery';
import {NavService} from "./navbar/nav.service";
import { HoursComponent } from './pages/hours/hours.component';
import {RouterModule, Routes} from "@angular/router";
import { IvrInstructionsComponent } from './pages/ivr-instructions/ivr-instructions.component';
// import {FlexModule} from "@angular/flex-layout";
import {MyLayoffBenefitsHomeComponent} from  './pages/my-layoff-benefits/my-layoff-benefits-home/my-layoff-benefits-home.component';
import {GminInvalidComponent} from  './pages/my-layoff-benefits/gmin-invalid/gmin-invalid.component';
import {SystemUnavailableComponent} from  './pages/my-layoff-benefits/system-unavailable/system-unavailable.component';
import {SystemErrorComponent} from  './pages/my-layoff-benefits/system-error/system-error.component';
import {ContactUsComponent} from "./pages/my-layoff-benefits/contact-us/contact-us.component";
import {MatDialogModule} from '@angular/material/dialog';



window['$'] = window['jQuery'] = $;
const appRoutes: Routes=[
  {path: 'hours', component: HoursComponent},
  {path: 'vru', component: IvrInstructionsComponent},
];
@NgModule({ declarations: [
        AppComponent,
        // Modals
        ModalComponent,
        ErrorModalComponent,
        ConfirmationModalComponent,
        InformativeModalComponent,
        PrivacyModalComponent,
        // View Components
        FormsComponent,
        SubPlanCountersComponent,
        NavbarComponent,
        NavbarItemComponent,
        HomeComponent,
        HomeViewComponent,
        PlaceholderComponent,
        LandingComponent,
        FaqComponent,
        MyLayoffBenefitsComponent,
        MyLayoffBenefitsHomeComponent,
        GminInvalidComponent,
        SystemUnavailableComponent,
        SystemErrorComponent,
        PaymentHistoryComponent,
        TspElectionComponent,
        SetupMondetComponent,
        BenefitInitComponent,
        ContactUsComponent,
        FileUploadComponent,
        UploadPopupComponent,
        SalaryFormsComponent,
        // Common
        LoadingElemComponent,
        ExpandableContainerComponent,
        ExpandableTab,
        ExpandableTabsComponent,
        AppButtonComponent,
        TextBoxControlComponent,
        ScreenMessageComponent,
        InputSelectComponent,
        InputDatepickerComponent,
        InputCheckboxComponent,
        InputTextareaComponent,
        InputRadioButtonGroupComponent,
        MatTablinkComponent,
        ToNumberPipe,
        ToNegativeCurrencyPipe,
        InputMultiSelectComponent,
        SinkComponent,
        ScrollToTop,
        LabelLabelComponent,
        SimpleTextboxComponent,
        NoaccessComponent,
        OptionsSelectComponent,
        NoAccessComponent,
        HoursComponent,
        IvrInstructionsComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        MaterialModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(appRoutes),
        //NgbModule,
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
        // FlexModule,
        MatDialogModule], providers: [Globals, ModalService, FormChecking, DataSharingService, EmailGenerator, FormValidator, ScrollToTop, SessionTimer, NavService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        }, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
