<app-loading *ngIf="loadingPage"></app-loading>
<div class="searchContainer">
  <app-expandable *ngIf="systemErrorPage" [expandable]="false" [title]="globals?.i18n.title.mylayoffbenefits">
    <br>
    <app-system-error></app-system-error>
  </app-expandable>

  <app-expandable *ngIf="siteClosedPage" [expandable]="false" [title]="globals?.i18n.title.mylayoffbenefits">
    <br>
    <app-system-unavailable></app-system-unavailable>
  </app-expandable>

  <app-expandable *ngIf="incorrectUserPage" [expandable]="false" [title]="globals?.i18n.title.mylayoffbenefits">
    <br>
    <app-gmin-invalid></app-gmin-invalid>
  </app-expandable>

  <!-- Auto Sub Page -->
  <app-expandable *ngIf="autoSubPage" [expandable]="false" [title]="globals?.i18n.title.mylayoffbenefits">
    <div class="container">
      <br>
      <p>
        We are sorry, you are not eligible to use this web application or the Interactive Voice Response System.
      </p>
      <br>
      <p>
        If you continue to experience this problem, please call the GM Layoffs and Unemployment Center at
        1-800-584-2000.
      </p>
      <br>
      <div  class="tableContainer1">
        Click to return to My Layoff Benefits Menu
      </div>
      <div class="tableContainer1">
        <app-button class="margin" type="primary" value="{{globals?.i18n.title.mylayoffbenefits}}"
                    routerLink="/mylayoffbenef"
        ></app-button>
      </div>
      <br>
      <div class="row centeredFlex">
        <a (click)="getprivacyinfo()">Privacy Information</a>
      </div>
    </div>
  </app-expandable>

  <!-- Show Mondet Page -->
  <app-expandable *ngIf="mondetCurrentlyExistsPage" [expandable]="false" [title]="globals?.i18n.title.yourMondetInfo">
    <div class="container">
      <br>
      <p>
        Our records show you are laid off from a GM facility in the State of {{currentState}}.
      </p>
      <br>
      <p>
        Your {{benefitYearMessage}} is {{mondetYearConverted}}
      </p>
      <p *ngIf="mondetData.mondetWbaCode !== '000'">
        Your {{wbaMessage}} is $ {{mondetData.mondetWbaAmt}}
      </p>
      <p *ngIf="mondetData.mondetMbaCode !== '000'">
        Your {{mbaMessage}} is $ {{mondetData.mondetMbaAmt}}
      </p>
      <p *ngIf="mondetData.mondetWeeksCode !== '000'">
        Your {{weeksMessage}} is {{mondetData.mondetWeeks}}
      </p>
    </div>
    <div class="row centeredFlex">
      <app-button routerLink="/mylayoffbenef/cisaBenefitInit" type="primary"
                  value="{{globals?.i18n.application.button.subenefitLink}}"></app-button>
    </div>
    <br>
    <div class="row centeredFlex">
      <a (click)="getprivacyinfo()">Privacy Information</a>
    </div>
  </app-expandable>

  <!-- Questions Page -->
  <app-expandable *ngIf="mondetQuestionsPage" [expandable]="false" [title]="globals?.i18n.title.enterMondetInfo">
    <div class="container">
      <br>
      <p>
        You have requested to report your State Monetary Determination information. Please remember you must have
        applied for and received your copy of a current state Monetary Determination for your layoff period.
      </p>
      <p>
        You must have the state Monetary Determination in front of you to answer the following questions. The
        information you enter into this system must be correct. All your answers will be kept by the GM Layoffs and
        Unemployment Center
        as a part of your benefit history.
      </p>
      <p>
        Our records show you are recently laid off from a GM facility in the state of {{currentState}}.
        The State Monetary Determination information you will be asked to enter must be from the State
        of {{currentState}}.
        If you have information from any other state, please call the GM Layoffs and Unemployment Center at
        1-800-584-2000.
      </p>
      <p>
        Looking at your State of {{currentState}} Monetary Determination, find your {{benefitYearMessage}}.
      </p>
      <p>
        Please enter your {{benefitYearMessage}}.
      </p>
<!--      <div class="row centeredFlex">-->
<!--        <label>-->
<!--          Select:-->
<!--        </label>-->
<!--      </div>-->
      <div class="row centeredFlex">
        <app-select class="boxWidth" [label]="globals?.i18n.field.label.selectYear" [options]="years"
                    [(selected)]="currentYear"
                    (selectedChange)="populateDaysDropdown()"></app-select>

        <app-select class="boxWidth" [label]="globals?.i18n.field.label.selectMonth" [options]="months"
                    [(selected)]="currentMonth"
                    (selectedChange)="populateDaysDropdown()"></app-select>

        <app-select class="boxWidth" [label]="globals?.i18n.field.label.selectDay" [options]="days"
                    [(selected)]="currentDay"></app-select>
      </div>
      <br>
    </div>

    <div class="container" *ngIf="mondetData.mondetWbaCode !== '000'">
      <hr>
      <p>Find your {{wbaMessage}}</p>
      <p>Please enter your {{wbaMessage}}.</p>
      <div class="row">
        <p>$</p>
        <app-textbox-control class="numberBoxWidth" [(value)]="wbaAmountDollars" [isButtonDisplayed]="false"
                             [validator]="submitValidator.addProperty('wbaDollars')"
        ></app-textbox-control>
        <p>.</p>
        <app-textbox-control class="numberBoxWidth1" [(value)]="wbaAmountCents" [isButtonDisplayed]="false"
                             [validator]="submitValidator.addProperty('wbaCents')"
        ></app-textbox-control>
      </div>
    </div>

    <div class="container" *ngIf="mondetData.mondetMbaCode !== '000'">
      <hr>
      <p>Find your {{mbaMessage}}</p>
      <p>Please enter your {{mbaMessage}}.</p>
      <div class="row">
        <p>$</p>
        <app-textbox-control class="numberBoxWidth" [(value)]="mbaAmountDollars" [isButtonDisplayed]="false"
                             [validator]="submitValidator.addProperty('mbaDollars')"
        ></app-textbox-control>
        <p>.</p>
        <app-textbox-control class="numberBoxWidth1" [(value)]="mbaAmountCents" [isButtonDisplayed]="false"
                             [validator]="submitValidator.addProperty('mbaCents')"
        ></app-textbox-control>
      </div>
    </div>

    <div class="container" *ngIf="mondetData.mondetWeeksCode !== '000'">
      <hr>
      <p>Find your {{weeksMessage}}</p>
      <p>Please enter your {{weeksMessage}}.</p>
      <div class="row">
        <app-textbox-control class="numberBoxWidth" [(value)]="weeksAmountDollars" [isButtonDisplayed]="false"
                             [validator]="submitValidator.addProperty('weeksDollars')"
        ></app-textbox-control>
        <p>.</p>
        <app-textbox-control class="numberBoxWidth1" [(value)]="weeksAmountCents" [isButtonDisplayed]="false"
                             [validator]="submitValidator.addProperty('weeksCents')"
        ></app-textbox-control>
      </div>
    </div>
    <div class="container">
      <hr>
      <app-button value="{{globals?.i18n.application.button.addStateMondet}}" (click)="createMondet()"
                  type="primary" [disabled]="!addButtonEnabled"
      ></app-button>
      <app-loading *ngIf="submitLoading"></app-loading>
    </div>

    <div style="padding: 0.5em;"></div>

    <div class="row centeredFlex">
      <a (click)="getprivacyinfo()">Privacy Information</a>
    </div>

  </app-expandable>

  <!-- Mondet Added Success Page -->
  <app-expandable *ngIf="addSuccess" [expandable]="false" [title]="globals?.i18n.title.mylayoffbenefits + ' - Add Mondet'">
    <div class="container">
      <div class="row centeredFlex">
        <h2>
          Your Monetary Determination Information has been added.
        </h2>
      </div>
      <p>You are now ready to apply for a layoff benefit.</p>
      <div class="row centeredFlex">
        <app-button routerLink="/mylayoffbenef/cisaBenefitInit" type="primary"
                    value="{{globals?.i18n.application.button.subenefitLink}}"></app-button>
      </div>
      <br>
      <div class="row centeredFlex">
        <a (click)="getprivacyinfo()">Privacy Information</a>
      </div>
    </div>
  </app-expandable>

  <!-- Mondet Added Failure Page -->
  <app-expandable *ngIf="addFailure" [expandable]="false" [title]="globals?.i18n.title.mylayoffbenefits + ' - Monetary Determination Information'">
    <div class="container">
      <div class="row centeredFlex">
        <h2>
          Monetary Determination Information
        </h2>
      </div>
      <p>Problems with the State Monetary Determination have been discovered.  You will not be able to apply for a layoff benefit until these problems have been resolved.</p>
      <p>If you continue to experience the problem, please call the GM Layoffs and Unemployment Center at 1-800-584-2000.</p>
       <div  class="tableContainer1">
        Click to return to My Layoff Benefits Menu
      </div>
      <div class="tableContainer1">
        <app-button class="margin" type="primary" value="{{globals?.i18n.title.mylayoffbenefits}}"
                    routerLink="/mylayoffbenef"
        ></app-button>
      </div>
      <br>
      <div class="row centeredFlex">
        <a (click)="getprivacyinfo()">Privacy Information</a>
      </div>
    </div>
  </app-expandable>


  <div style="padding: 1.3em;"></div>
</div>
