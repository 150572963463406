import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Globals} from '../../sharedComponents/global-variables/globals';

@Injectable({
  providedIn: 'root'
})
export class SubPlanCounters{
  private url = this.globals.url;

  constructor(private http: HttpClient, private globals: Globals) {}

  getCounters(searchModel): any{
    return this.http.post(this.url + '/detailCounter/getCounter', searchModel);
  }

 }
