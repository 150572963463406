import { Component, Input, OnInit } from '@angular/core';
import { Globals } from '../global-variables/globals';

@Component({
  selector: 'app-screen-message',
  templateUrl: './screen-message.component.html',
  styleUrls: ['./screen-message.component.css']
})
export class ScreenMessageComponent implements OnInit {

  @Input() messages: Array<String> = [];
  @Input() errors: Array<String> = [];
  constructor(public globals: Globals) { }


  ngOnInit() {
  }
}
