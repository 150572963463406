import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NavService} from "../nav.service";
import {NavItem} from '../nav-item';
import {Globals} from  '../../sharedComponents/global-variables/globals';

@Component({
  selector: 'navbar-item',
  templateUrl: './navbar-item.component.html',
  styleUrls: ['./navbar-item.component.css'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class NavbarItemComponent implements OnInit {
  expanded: boolean;
  activeHeader = false;
  @HostBinding('attr.aria-expanded') ariaExpanded;
  @Input() item: NavItem;
  @Input() depth: number;
  constructor(private navService: NavService, private router: Router, private globals: Globals) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
    // ariaExpanded = this.expanded;
  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
      }
    });
  }

  isActive() {
    return this.router.isActive(this.item.route, true);
  }
  isActiveHeader() {
    const url = this.router.url;
    const itemRoute = this.item.route;
    if (this.item.children){
      if (url.indexOf('/' + itemRoute + '/') >= 0){
        this.activeHeader = true;
      }else{
        this.activeHeader = false;
      }
    }else{
      this.activeHeader = false;
    }
    return this.activeHeader;
  }

  onItemSelected(item: NavItem) {
    if (item.displayName === 'Logout'){
      this.globals.logout();
    }
    if (!item.children || !item.children.length) {
      // need to make this build it's route based off of it's parent.
      if (item.externalSameBrowser) {
        window.location.href = item.route;
      }else if (item.external){
        window.open(item.route);
      } else {
        this.router.navigate([item.route]);
      }
      // may want to see how we want this to behave...
      // close only by default on mobile
      this.navService.closeNav();
    }
    if (!item.external && this.expanded === false && this.globals.captchaverify !== false && this.globals.gminverify !== false) {
      this.navService.currentUrl.next(item.route);
    }else{
          this.router.navigate([item.route]);
    }

    if (item.children && item.children.length) {
      if (item.route !== undefined || item.route !== null){
        this.router.navigate([item.route]);
      }
      this.expanded = !this.expanded;
      this.navService.closeNav();
    }
  }
}
