import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/sharedComponents/global-variables/globals';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private url = this.globals.url;
  loadToDo(): any{
    return this.http.get(this.url + '/security/user');
  }
  retrieveSkeleton() {
    return this.globals.retrieveSkeleton('JPAController/retrieveVio');
  }

  searchVio(searchModel): any {
    return this.http.post(this.url + '/security/searchVio', searchModel.gmIn);
  }
  updateVio(vioModel): any {
    return this.http.post(this.url + '/security/updateVio', vioModel);
  }
  addVio(vioModel): any {
    return this.http.post(this.url + '/security/addVio', vioModel);
  }
  badRequest(): any {
    return this.http.post(this.url + '/CISAw', '');
  }
  constructor(private http: HttpClient, private globals: Globals) {

  }
}
