import {Component, EventEmitter, HostBinding, Input, OnInit} from '@angular/core';
import {Output} from '@angular/core';
import {Globals} from "../global-variables/globals";

@Component({
  selector: 'app-radio-button-group',
  templateUrl: './input-radio-button-group.component.html',
  styleUrls: ['./input-radio-button-group.component.css','../sharedStyling/shared-styling.component.css']
})

export class InputRadioButtonGroupComponent implements OnInit {

  @HostBinding('class.col-md-6') smallInputClass: Boolean = true;
  @HostBinding('class.col-md-12') bigInputClass: Boolean = false;

  @Input() label: String = '';
  @Input() fieldLevelSecurity: any = undefined;
  @Input() editable: Boolean = true;
  @Input() buttonValuesString: string[][] = undefined;
  @Input() buttonValuesBoolean: (boolean|string)[][] = undefined;
  @Input() bigInput = false;
  @Input() labelPosition = 'after';
  @Input() mandatory: Boolean = false;
  @Input() validator: any = undefined;
  @Input() tableInput: Boolean = false;
  @Input() isButtonDisplayed: Boolean = false;
  @Input() removeColon: Boolean = false;

  //pipe = true;

  validatorMessages = [];
  toolTips = '';
  changedValue: string;
  requiresVal = false;
  private clearCall = false;

  @Output()
  valueChange = new EventEmitter<string>();

  @Input()
  get value(){
    return this.changedValue;
  }

  set value(val) {
    this.changedValue = val;
    this.valueChange.emit(this.changedValue);
    if (this.validator !== undefined) {
      this.validator.bindValue(val);
      //fixes race condition when the modals set the value
      if (this.requiresVal === true) {
        this.validate();
      }
    }
  }

  onSelct() {

      this.requiresVal = true;
    }

  endingCharacter(val) {
    if (val[val.length - 1] === ':'){
      return val;
    }
    else if (this.removeColon === true) {
      return val;
    }
    else {
      return val + ':';
    }
  }
  public validate() {
    if (this.validator !== undefined) {
      this.validatorMessages = [];
      if (this.clearCall !== true) {
        this.validatorMessages = this.validator.validate(this.value);
      }
      this.clearCall = false;
    }
  }

  public updateValidatorMessages() {
    if (this.validatorMessages !== undefined && this.validatorMessages.length !== 0) {
      this.validatorMessages = this.validator.validate(this.value);
    }
  }

  updateValidatorParams(validate, context) {
    if (context.validator !== undefined) {
      context.validator.bindValue(context.value);
      context.validator.validateObsr.subscribe(data => {
        if (data.type === 'clear') {
          context.clearCall = true;
        }
        validate.bind(context)();
      });
      if (context.validator.rules.required === true) {
        context.mandatory = context;
      }
      if (context.validator.rules.inputMaxLen !== null) {
        context.maxLength = context.validator.rules.inputMaxLen;
      }
      if (context.validator.toolTips.length > 0) {
        context.toolTips = context.validator.toolTips.join('\n');
      }
    }
  }

  constructor(public globals: Globals) {
    let that = this;
    this.globals.getLocaleUpdate().subscribe(next => {
      setTimeout(() => {
        console.log(next);
        that.updateValidatorParams(that.validate, that);
        that.updateValidatorMessages();
      }, 0)

    });
  }
  ngOnInit() {
    let validate = this.validate;
    let that = this;
    if(this.bigInput) {
      this.bigInputClass = true;
      this.smallInputClass = false;
    }
    if(this.buttonValuesBoolean !== undefined && this.buttonValuesString !== undefined) {
      console.warn("Radio Button Group: Please define just one set of values.");
    } else if(this.buttonValuesBoolean === undefined && this.buttonValuesString === undefined) {
      console.warn("Radio Button Group: Please fine a set of button values.");
    }
    this.updateValidatorParams(this.validate, that);
  }

}
