<app-loading *ngIf="loadingPage"></app-loading>
<app-expandable [hidden]="paymentHistoryPage === false" [expandable]="false" [title]="globals?.i18n.title.paymenthis">
  <app-screen-message [errors]="" [messages]=""></app-screen-message>

<!--  <app-loading *ngIf="loadingPage"></app-loading>-->

  <div  *ngIf="systemUnavailable === true ">
    <app-system-unavailable></app-system-unavailable>
  </div>
  <div  *ngIf="systemError === true ">
    <app-system-error></app-system-error>
  </div>

  <div *ngIf="IdNotFound === true ">
    <div class="searchContainer">
      <div class="container">
        <div class="row"></div>
        <h4>
          You have entered a GM Identification Number (GMIN) that is not found to be a GM employee
        </h4>

        <div class="row">
          <span>Please verify your GMIN and <a (click)="showcaptchapage()">login </a>again.</span>
        </div>
      </div>
      <br>
      <div  class="row centeredFlex">
        <a (click)="getprivacyinfo()">Privacy Information</a>
      </div>
    </div>
  </div>

<div class = 'searchContainer' [hidden]="goodReturn !== true " >

  <div class="resultsContainer" [hidden]="showRejectTable === true" >
    <app-expandable [expandable]="false" [title]="'Pending Application Information'">
      <div *ngIf="hasPendingTrns; else noPending">
        <p style="white-space: pre;">{{pendingDraftDateStmt}}</p>
      </div>
      <ng-template #noPending>
        <p>{{noPendingDraftDateStmt}}</p>
      </ng-template>

      <div class="tableContainer" *ngIf="hasPendingTrns">

        <table mat-table  matSort #pendingTableSort="matSort"  matSortActive="weekEndDate" matSortDirection="desc" multiTemplateDataRows [dataSource]="searchResults"
               class="mat-elevation-z2 searchResultsTable">
          <ng-container matColumnDef="benefit">
            <th mat-header-cell *matHeaderCellDef >Benefit</th>
            <td mat-cell *matCellDef="let data" >{{getBenefit(data.payType)}}</td>
          </ng-container>
          <ng-container matColumnDef="weekEndDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Week Ending Date</th>
            <td mat-cell *matCellDef="let data" >{{ data.weekEndDate }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="getPendingDisplayedColumns(); sticky:true" class="tableHeader"></tr>
          <tr mat-row *matRowDef="let row; columns: getPendingDisplayedColumns();"
              class="tableRow"></tr>
        </table>
      </div>
      <div class="paginatorContainer">
        <mat-paginator #MatPaginator2 (page)="paginationChange($event,'pending')"
                       [pageSizeOptions]="pendingPageSizeOptions" showFirstLastButtons
                       [pageSize]="pendingPageSize">
        </mat-paginator>
      </div>
    </app-expandable>
  </div>
  <div class="resultsContainer"  [hidden]="showRejectTable === true" >
    <app-expandable [expandable]="false" [title]="'Processed Application History Information'">
      <div *ngIf="hasProcessedTrns == false">
        <p>{{noPaidHistoryStmt}}</p>
      </div>
      <div *ngIf="hasProcessedTrns == true" style="font-style: italic">
        <p> <b>The following Application history reflects payments transmitted to
          Payroll over the past two years from the current date</b> </p>
      </div>

      <div class="tableContainer" *ngIf="hasProcessedTrns">

        <table mat-table  matSort #paidTableSort="matSort" matSortActive="weekEndDate" matSortDirection="desc" multiTemplateDataRows [dataSource]="paidsearchResults"
               class="mat-elevation-z2 searchResultsTable">
          <ng-container matColumnDef="benefit">
            <th mat-header-cell *matHeaderCellDef >Benefit</th>
            <td mat-cell *matCellDef="let data" >{{getBenefit(data.payType)}}</td>
          </ng-container>
          <ng-container matColumnDef="weekEndDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Week Ending Date</th>
            <td mat-cell *matCellDef="let data" >{{ data.weekEndDate }}</td>
          </ng-container>
          <ng-container matColumnDef="draftDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Transmitted to Payroll</th>
            <td mat-cell *matCellDef="let data" >{{ data.draftDate }}</td>
          </ng-container>
          <ng-container matColumnDef="fundsAvailableDate">
            <th mat-header-cell *matHeaderCellDef >Funds Available</th>
            <td mat-cell *matCellDef="let data" >{{ data.fundsAvailableDate }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="getPaidDisplayedColumns(); sticky:true" class="tableHeader"></tr>
          <tr mat-row *matRowDef="let row; columns: getPaidDisplayedColumns();"
              class="tableRow"></tr>
        </table>
      </div>

      <div class="paginatorContainer">
        <mat-paginator #MatPaginator1
                       (page)="paginationChange($event, 'paid')"
                       [pageSizeOptions]="paidPageSizeOptions" showFirstLastButtons
                       [pageSize]="paidPageSize">
        </mat-paginator>
      </div>

    </app-expandable>
  </div>

  <div *ngIf="showRejectTable; else HideRejectionTable">
    <div class="rowContainer centeredFlex" style="padding-bottom: 20px" *ngIf="toggle !== true">
      <app-button type="generic" class="margin" value="Close Application Errors"
                  (click)="showRejectHistory()"></app-button>
    </div>
  </div>

  <ng-template #HideRejectionTable>
    <div class="rowContainer centeredFlex" style="padding-bottom: 5px">
      <app-button type="generic" class="margin" value="Check on Application Errors"
                  (click)="showRejectHistory()"></app-button>
    </div>
  </ng-template>

  <div class="resultsContainer" [hidden]="showRejectTable === false" >
    <app-expandable [expandable]="false" [title]="'Rejected Application Information'">
      <div *ngIf="hasRejectHistory == false">
        <p>{{noRejectedStmnt}}</p>
      </div>

      <div class="tableContainer">

        <table mat-table  multiTemplateDataRows [dataSource]="rejectSearchResults"
               class="mat-elevation-z2 searchResultsTable">
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef >Date</th>
            <td mat-cell *matCellDef="let data" >{{data.weekEndDate}}</td>
          </ng-container>

          <ng-container matColumnDef="payType">
            <th mat-header-cell *matHeaderCellDef >Benefit</th>
            <td mat-cell *matCellDef="let data" >{{getBenefit(data.payType)}}</td>
          </ng-container>

          <ng-container matColumnDef="reason">
            <th mat-header-cell *matHeaderCellDef >Reasons</th>
            <td mat-cell *matCellDef="let data" >
              <!--              <li>-->
              <p>{{getStatement(data.reasonCode1)}}</p>
              <p>{{getStatement(data.reasonCode2)}}</p>
              <p>{{getStatement(data.reasonCode3)}}</p>
              <!--              </li>-->
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['date','payType', 'reason']; sticky:true" class="tableHeader"></tr>
          <tr mat-row *matRowDef="let row; columns: ['date','payType' ,'reason'];"
              class="tableRow"></tr>
        </table>
      </div>

      <div class="paginatorContainer">
        <mat-paginator #MatPaginator3 (page)="paginationChange($event,'rejected')"
                       [pageSizeOptions]="rejectedPageSizeOptions" showFirstLastButtons
                       [pageSize]="rejectedPageSize">
        </mat-paginator>
      </div>
    </app-expandable>

    <div class="rowContainer centeredFlex" style="padding-bottom: 20px" >
      <app-button type="generic" class="margin" value="Close Application Errors"
                  (click)="showRejectHistory()"></app-button>
    </div>
  </div>

  <div  class="row centeredFlex">
    <a (click)="getprivacyinfo()">Privacy Information</a>
  </div>
  <br>
  <br>
  <br>
</div>
<!--  <div class="rowContainer centeredFlex" style="padding-bottom: 20px">-->
<!--    <app-button type="generic" class="margin" value="Check on Application Errors"-->
<!--    (click)="showRejectHistory()"></app-button>-->
<!--  </div>-->

<!--  <div class="resultsContainer" *ngIf="showRejectTable !== false">-->
<!--    <app-expandable [expandable]="false" title='Rejected Application History'>-->

<!--      <div *ngIf="hasRejectHistory; else noRejectHistory">-->
<!--        <div *ngFor="let item of rejectHistory; let i = index;">-->
<!--          <p>Your application for a {{getBenefit(item.payType)}} for weekending {{item.weekEndDate}} rejected because of:</p>-->
<!--          <ul>-->
<!--            <p>{{getStatement(item.reasonCode1)}}</p>-->
<!--            <p>{{getStatement(item.reasonCode2)}}</p>-->
<!--            <p>{{getStatement(item.reasonCode3)}}</p>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--      <ng-template #noRejectHistory>-->
<!--        <p>You have no rejected application history in the system.</p>-->
<!--      </ng-template>-->
<!--    </app-expandable>-->
<!--  </div>-->


</app-expandable>


