<!--<app-loading *ngIf="loadingPage"></app-loading>-->
<app-loading *ngIf="loadingPage"></app-loading>
<div class="searchContainer" *ngIf="globals.gmlogoclick === false">
  <app-expandable [expandable]="false" title= 'Welcome to GM Layoffs and Unemployment Center Web Site'>
    <div class="container">
      <div class="row">
        <h3>
          The Center is the Administrator for your SUB Layoff Benefits
        </h3>
      </div>
      <div  class="row">
        <h3>
          Continue to the GM Site:
        </h3>
        <span  class="link"  (click)="globals.retrieveNavitem()" ><img src="../assets/images/gmlogo_cisa.png" width="70px" style="padding-left: 10px"></span>
      </div>
      <div  *ngIf="this.globals?.landingmessage !==undefined"  class="resultsContainer" >
        <div class="tableContainer">
          <table class="searchResultsTable">
            <tr class="rowName" *ngFor="let message of this.globals?.landingmessage">
              <h4 class="texred">
                {{message.msgTxt}}
              </h4>
            </tr>
          </table>
        </div>
      </div>
      <div class="resultsContainer">
        <div class="tableContainer">
<!--          <table class="searchResultsTable">-->
<!--            <tr class="rowName">-->
<!--            <h4 class="texred">-->
<!--              {{globals.i18n.title.message.landingdefault}}-->
<!--            </h4>-->
<!--         </tr>-->
<!--          </table>-->
        </div>
      </div>
    </div>
  </app-expandable>
</div>
<div class="searchContainer"  *ngIf="globals.gmlogoclick !== false">
  <app-expandable [expandable]="false" [title]="globals.i18n.title.home">
    <div class="container">
      <div class="row centeredFlex">
        <span class="link textalign" routerLink="/faq">
        <h3>
          {{globals.i18n.title.message.text1}}
        </h3>
          </span>
        <!--         <span  class="link" routerLink="/emulate" >{{globals.i18n.main.menu.emulatePlant}}</span>-->
      </div>
      <div class="row centeredFlex">
        <span class="link textalign" routerLink="/mylayoffbenef">
        <h3>
          {{globals.i18n.title.message.text2}}
        </h3>
          </span>
      </div>
      <div class="row centeredFlex">
            <span class = "link textalign"  routerLink="/gmforms">
        <h3>
         {{globals.i18n.title.message.text3}}
        </h3>
            </span>
      </div>
      <div class="row centeredFlex">
        <span class="link textalign" routerLink="/vru">
        <h3>
          {{globals.i18n.title.message.text4}}
        </h3>
          </span>
      </div>
      <div class="row centeredFlex">
        <span class="link textalign" routerLink="/hours">
        <h3>
          {{globals.i18n.title.message.text5}}
        </h3>
          </span>
      </div>
      <!--<div><a routerLink="/hours">Hours</a></div>-->
      <div class="rowContainer centeredFlex">

        <app-button type="primary" class="margin" value="{{globals?.i18n.application.button.stateucinfo}}"
                    (click)="openState()"
        >

        </app-button>
        <!--                    (click)="exportResultSet('ActiveDepartments')"-->
        <!--                    [disabled]="loadingForm"></app-button>-->

        <!--    <app-button type="primary" class="margin" value="{{globals.i18n.application.button.delete}}"-->
        <!--                [disabled]="loadingForm"></app-button>-->
      </div>
    </div>
  </app-expandable>
  <br>
  <br>
  <br>
  <br>
</div>

<!--<span id="navbarContainer" *ngIf="base.userInfo !== undefined">-->
<!--  <app-navbar></app-navbar>-->
<!--</span>-->


