import { masks } from '../../masks';

let submit = {

  comment: {
    minLen: 0,
    inputMaxLen: 50,
    maxLen: 50,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  }
};


export {submit};
