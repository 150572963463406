import { masks } from '../../masks';

let search = {

  gmin: {
    minLen: null,
    inputMaxLen: 9,
    maxLen: 9,
    mask: masks.maskGmin.mask,
    maskMessage: masks.maskGmin.maskMessage,
    maskToolTip: masks.maskGmin.maskToolTip,
    required: true
  },
  name: {
    minLen: null,
    inputMaxLen: 100,
    maxLen: 100,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  },
  streetAddress: {
    minLen: null,
    inputMaxLen: 100,
    maxLen: 100,
    mask: masks.maskStreetAddress.mask,
    maskMessage: masks.maskStreetAddress.maskMessage,
    maskToolTip: masks.maskStreetAddress.maskToolTip,
    required: false
  },
  city: {
    minLen: null,
    inputMaxLen: 30,
    maxLen: 30,
    mask: masks.maskCity.mask,
    maskMessage: masks.maskCity.maskMessage,
    maskToolTip: masks.maskCity.maskToolTip,
    required: false
  },
  state: {
    minLen: null,
    inputMaxLen: 25,
    maxLen: 25,
    mask: masks.maskState.mask,
    maskMessage: masks.maskState.maskMessage,
    maskToolTip: masks.maskState.maskToolTip,
    required: false
  },
  phone: {
    minLen: null,
    inputMaxLen: 30,
    maxLen: 30,
    mask: masks.maskPhone.mask,
    maskMessage: masks.maskPhone.maskMessage,
    maskToolTip: masks.maskPhone.maskToolTip,
    required: false
  },
  zipcode: {
    minLen: null,
    inputMaxLen: 5,
    maxLen: 5,
    mask: masks.maskZipcode.mask,
    maskMessage: masks.maskZipcode.maskMessage,
    maskToolTip: masks.maskZipcode.maskToolTip,
    required: false
  },
  email: {
    minLen: null,
    inputMaxLen: 100,
    maxLen: 100,
    mask: masks.maskEmail.mask,
    maskMessage: masks.maskEmail.maskMessage,
    maskToolTip: masks.maskEmail.maskToolTip,
    required: true
  }
};


export {search};
