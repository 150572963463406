<div class="modalContainer">
  <div class="headerContainer">
    <img src="../assets/images/gmlogo_cisa.png" width="70px" style="padding-left: 10px">

    <h2 class="headertext">Privacy Information</h2>

    <div class="modalClose">
      <i (click)="close();" class="closeBtn" class="fa fa-2x fa-times" aria-hidden="true"></i>
    </div>
  </div>
  <div  *ngFor="let message of data.message">
    {{message}}
  </div>

<!--  <div class="controlsContainer">-->
<!--    <a (click)="getchrome()"><img src="" align="center"/>-->
<!--    </a>-->
<!--  </div>-->
  <div class="controlsContainer">
    <app-button type="primary" class="margin" value="Close" (click)="close();"></app-button>
  </div>
</div>

