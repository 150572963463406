<div  *ngIf="globals.gmlogoclick !== false">
  <app-expandable [expandable]="false" [title]="globals.i18n.title.form">

      <div class="container">

<!--      <div class="message">-->
        <br>
        You can download forms electronically that you previously had to request by phone or mail.&nbsp; Select a form to download below.

<!--      </div>-->
      <div class="files">
        <dl>
        <dt><b>Hourly Layoff Benefit Information</b></dt><br>
        <dd><a href="assets/Forms/Plant Layoff Check list.pdf" target="_blank"> Plant Layoff Check List </a></dd>
        <dd><a href="assets/Forms/Employee Layoff Check list.pdf" target="_blank">Employee Layoff Check List</a></dd>
        <dd><a href="assets/Forms/Severe Weather Report Form.pdf" target="_blank">Severe Weather Report Form</a></dd>

        <br>

        <dt><b>Hourly Layoff Benefit Application Forms</b></dt><br>
        <dd><a href="assets/Forms/Multiple-Week Application for SUBenefits.pdf" target="_blank">Multiple-Week Application for SUBenefits</a></dd>
        <dd><a href="assets/Forms/SUB2-TSP.pdf" target="_blank">Application for Transition Support Program (TSP) benefits</a></dd>
        <dd><a href="assets/Forms/TSP Form.pdf" target="_blank">Election for Transition Support Program (TSP) benefits</a></dd>

        <br>

        <dt><b>UAW SUB Appeal Forms</b></dt><br>
        <dd><a href="assets/Forms/GM-SUB-6 Form.pdf" target="_blank">First Stage Appeal (SUB 6)</a></dd>
        <dd><a href="assets/Forms/GM-SUB-7 Form.pdf" target="_blank">Notice of Local Committee Decision (SUB 7)</a></dd>
        <dd><a href="assets/Forms/GM-SUB-7A Form.pdf" target="_blank">Notice of Local Committee Decision (SUB 7A)</a></dd>
        <dd><a href="assets/Forms/GM-SUB-7B Form.pdf" target="_blank">Notice of Local Committee Decision and Appeal to the Board (SUB 7B)</a></dd>
        <dd><a href="assets/Forms/Local Committee Appeal to GM-UAW Board of Administration (SUB 8).pdf" target="_blank">Local Committee Appeal to GM-UAW Board of Administration
          (SUB 8)</a></dd>
        <dd><a href="assets/Forms/Employee Appeal to the GM-UAW Board of Administration (SUB 8A).pdf" target="_blank">Employee Appeal to the GM-UAW Board of Administration (SUB 8A)</a></dd>
        <dd><a href="assets/Forms/Notice of Board of Administration of Withdrawal of Appeal (SUB 8B).pdf" target="_blank">Notice to Board of Administration of Withdrawal of Appeal (SUB 8B)</a></dd>
        <dd><a href="assets/Forms/GM-SUB-9 Form.pdf" target="_blank">Employee Appeal to GM-UAW Board of Administration Decision (SUB 9)</a></dd>

<!--          <br>-->
<!--          <dt><b>Separation State Unemployment Documents</b></dt><br>-->
<!--          <dd><a href="assets/Forms/stateUC/Alabama Notice of Availability of Unemployment Compensation.pdf" target="_blank">Alabama</a></dd>-->
<!--          <dd><a href="assets/Forms/stateUC/ARIZONA Notice to Employee of Potential Unemployment Compensation UIB-1241A.pdf" target="_blank">Arizona</a></dd>-->
<!--          <dd><a href="assets/Forms/stateUC/California Notice to Employee of Potential Unemployment Compensation.pdf" target="_blank">California</a></dd>-->
<!--          <dd><a href="assets/Forms/stateUC/Colorado Employer Separation Form 22-234.pdf" target="_blank">Colorado</a></dd>-->
<!--          <dd><a href="assets/Forms/stateUC/Illinois Pamphlet for Separated Employees.pdf" target="_blank">Illinois</a></dd>-->
<!--          <dd><a href="assets/Forms/stateUC/Louisiana UI_AvailabilityNoticeToSeparatingEmployees.pdf" target="_blank">Louisiana</a></dd>-->
<!--          <dd><a href="assets/Forms/stateUC/Maryland uiavailnotice.pdf" target="_blank">Maryland</a></dd>-->
<!--          <dd><a href="assets/Forms/stateUC/Massachussets Pamphlet for Separated Employees.pdf" target="_blank">Massachussets</a></dd>-->
<!--          <dd><a href="assets/Forms/stateUC/Michigan UIA 1711.pdf" target="_blank">Michigan</a></dd>-->
<!--          <dd><a href="assets/Forms/stateUC/New Jersey Separation Form for Employees.pdf" target="_blank">New Jersey</a></dd>-->
<!--          <dd><a href="assets/Forms/stateUC/Pennsylvania UC-1609.pdf" target="_blank">Pennsylvania</a></dd>-->
<!--          <dd><a href="assets/Forms/stateUC/South Carolina families-first-required-notice_uie646d9b195084b58bcfaf2b06f0ca5ff.pdf" target="_blank">South Carolina</a></dd>-->
<!--          <dd><a href="assets/Forms/stateUC/Tennessee LB-0489.pdf" target="_blank">Tennessee</a></dd>-->
<!--          <dd><a href="assets/Forms/stateUC/Virgina B-29-Notice-to-Workers-English-20221114105900.pdf" target="_blank">Virginia</a></dd>-->
        </dl>
      </div>

    <br>
    <br>
    <p alight="left">
    <a href="https://get.adobe.com/reader/" target="_blank">
        <img src="assets/images/adobe.gif" width="88" height="31" border="0"></a>
    <small>The above forms are viewable and printable from Adobe Acrobat Reader. Follow this link to
      download a free version of Acrobat Reader if you do not already have a version on your computer. </small>
    </p>

   </div>
  </app-expandable>
</div>
