import { Component, OnInit } from '@angular/core';
import {Router} from  '@angular/router';
import {ModalService} from  '../../../services/modal/modal.service';
import {Globals} from  '../../../sharedComponents/global-variables/globals';

@Component({
  selector: 'app-system-unavailable',
  templateUrl: './system-unavailable.component.html',
  styleUrls: ['./system-unavailable.component.css']
})
export class SystemUnavailableComponent implements OnInit {

  constructor(public globals: Globals, public router: Router, private modal: ModalService) { }

  ngOnInit(): void {
  }
  // logout(){
  //   window.sessionStorage.setItem('gmlogoclick', 'false');
  //   this.globals.changegmlogoclick(false);
  //   this.globals.captchaverify = false;
  //   this.globals.gminattempt = false;
  //   this.globals.systemunvailable = false;
  //   this.globals.gminverify = false;
  //   this.globals.systemerror = false;
  //   this.globals.gminval = '';
  //   this.router.navigate(['']);
  // }
  //
  // getprivacyinfo(){
  //   this.modal.createModal('Privacy',  {
  //     message: [this.globals.i18n.messages.privacy.text1, this.globals.i18n.messages.privacy.text2, this.globals.i18n.messages.privacy.text3],
  //     callback: data =>{
  //       if (data.informative){
  //         return;
  //       }else{
  //
  //       }
  //     }
  //
  //   });
  // }

  goback() {

    if(this.globals.gminval !==''){
      this.router.navigate(['mylayoffbenef']);
    }else{
     this.globals.captchaverify = false;
      this.globals.systemunvailable === false
    }

  }

}
