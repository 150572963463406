import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Globals} from '../../sharedComponents/global-variables/globals';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private url = this.globals.url;
   constructor(private http: HttpClient, private globals: Globals) {
  }

  fileUpload(files, gminval): any {
    return this.http.post(this.url + '/upload/fileUpload/' + gminval, files);
  }

  fileDownload(path, gminval): any {
    return this.http.post(this.url + '/upload/fileDownload/' + gminval, path, {responseType: 'blob'});
  }

  getSessionCount(): any {
    return this.http.get(this.url + '/upload/sessionCount');
  }

  getCurrentFiles(gminval): any {
    return this.http.post(this.url + '/upload/getCurrentFiles', gminval);
  }

  startupPage(gminval): any {
    return this.http.post(this.url + '/upload/startup', gminval);
  }
}
