import { Component, OnInit } from '@angular/core';
import { Globals } from '../../sharedComponents/global-variables/globals';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})

export class FaqComponent implements OnInit {

  constructor(
    public globals: Globals,
  ) {

  }
  prevExpanded: any = undefined;

  data: any =
    [
      {
        "sections": "General Information",
        "responses":
          [
            { "question": "What is the GM Layoffs and Unemployment Center?",
              "response" :  "The GM Layoffs and Unemployment Center is the administrator for the GM " +
                "layoff benefit plan (SUB) and unemployment compensation activities."
            },
            { "question": "What is the GM Layoffs and Unemployment Center's address?",
              "response" :  "The mailing address for the GM Layoffs and Unemployment Center is:" +
                "<p align=\"center\">\n" +
                "GM Layoffs and Unemployment Center\n" +
                "<br> P.O. Box 5078<br> Southfield, MI\n" +
                "48086-5078\n" +
                "</p> "
            },
            { "question": "How do I reach the GM Layoffs and Unemployment Center by phone or fax?",
              "response" :  "The phone and fax numbers for the GM Layoffs and Unemployment Center are: " +
                "<p align=\"center\">\n" +
                "Phone: 1-800-584-2000<br>\n" +
                "Fax: 1-248-365-9809\n" +
                "</p>"
            },
            { "question": "How can I get information about my other GM benefits?",
              "response" :  "Visit gmbenefits.com or call 1-800-584-2000."
            },
          ]
      },
      {
        "sections": " Applying for Layoff Benefits:",
        "responses":
          [
            { "question": "How do I apply for a SUBenefit?",
              "response" : "To apply for benefits, eligible employees may call our " +
               " <a routerLink=\"/mylayoffbenef\">Interactive Voice Response (IVR) </a>" +
                "at 1-800-584-2000 or click on " +
                "<a (click)=\"processLinks($event)\">My Layoff Benefits</a>. " +
                "If you are from a plant location covered by " +
                "the AutoSUB Program, generally you do not need to apply for a " +
                "benefit using the IVR or the web site. A check will be sent to " +
                "you automatically. In certain situations, you may need to " +
                "complete a paper application."
            },
            { "question": "How do I apply for TSP?",
              "response" : "To apply for benefits, eligible employees may call our <a href=\"/CisaWeb/gm/vru/vru.htm\">" +
                "Interactive Voice Response (IVR)</a> at 1-800-584-2000 or click on " +
                "<a href=\"/CisaWeb/selfserv/CisaRedirect?page=/selfserv/CisaBuildMain\"> My Layoff Benefits</a>. " +
                "If you are from a plant location covered by " +
                "the AutoSUB Program, generally you do not need to apply for a " +
                "benefit using the IVR or the web site. A check will be sent to " +
                "you automatically. In certain situations, you may need to " +
                "tcomplete a paper application."
            },
            { "question": " What if I need assistance with the IVR or web site application process?",
              "response" : "GM Layoffs and Unemployment Center Analysts are <a href=\"/CisaWeb/gm/hours/hours.htm\">available" +
                "during normal business hours</a> to handle any problems and to explain" +
                "the process to you. However, you are required to do the" +
                "actual input of information into the IVR or this web site. The" +
                "GM Layoffs and Unemployment Center Analyst provides verbal assistance"
            },
            { "question": "How soon after my layoff begins can I apply for a layoff benefit?",
              "response" :  "Your home plant must enter the proper layoff status into the personnel status system" +
                "(PeopleSoft) and you must have met all applicable UC reporting and" +
                "application requirements before you may apply for a layoff benefit." +
                "<p>You must wait to file an application until you have received your monetary determination" +
                "from the state UC agency.&nbsp; You must also wait until you" +
                "receive your weekly UC check before you apply for your layoff" +
                "benefit for the same week. &nbsp; If you have exhausted UC" +
                "eligibility, you must wait until the Monday following any full" +
                "week of layoff to apply for a layoff benefit for such week.</p>"
            },
          ]
      },
      {
        "sections": "The AutoSUB Program",
        "responses":
          [
            { "question": "What is AutoSUB and how do I know if my plant is covered under the Program?",
              "response" :  "AutoSUB is an automated procedure, in which the GM Layoffs and Unemployment Center " +
                "receives state UC benefit information electronically from the " +
                "State. The UC information is used to calculate the payment of " +
                "a regular SUBenefit for each full week of layoff. When the " +
                "AutoSUB procedures apply, your application for a state UC benefit " +
                "will constitute submitting an application (and supporting " +
                "information) for regular SUBenefits <em> and generally you will " +
                "not need to use the IVR or web site to apply.</em> " +
                "<p>AutoSUB is used for GM employees in Michigan and New York, as well as those " +
                "working at GM assembly plants in Arlington and Fairfax.</p>"
            },
          ]
      },
      {
        "sections": "Layoff Benefits Processing",
        "responses":
          [
            { "question": "How often does the GM Layoffs and Unemployment Center process layoff benefits?",
              "response" :  "Layoff benefits are processed weekly, generally on Thursday nights. Gross " +
                "benefit amounts are transmitted to Payroll weekly, generally on " +
                "Fridays. Payroll generates SUB payments in the following week."
            },
            { "question": "I have not received my layoff benefits.",
              "response" : "GM Payroll issues SUB payments.&nbsp;Contact the Hourly Payroll Team at " +
                "1-866-245-5957 regarding missing payments."
            },
            { "question": "How is my SUBenefit calculated?",
              "response" : "The purpose of the SUB Plan is to supplement state UC benefits while you are on a " +
                "layoff. If you have at least one year of seniority (as defined " +
                "in the SUB Plan) as of your last day worked, your SUBenefit is equal " +
                "to 74% of your base hourly rate as of your last day worked. " +
                "Your SUBenefit is also offset by state Unemployment Compensation " +
                "(UC) benefits and other compensation received for the specified " +
                "week. To view a sample calculation, see the attached example: " +
                "<a href=\"Example SUB Calcs.pdf\">Example SUB Calculations</a>"
            },
            { "question": "If I have wages from another job for the same week that I am applying for a SUBenefit, do " +
                "I have to declare these earnings?",
              "response" : "Yes. You must report any outside earnings. However, only 80% of these " +
                "earnings will be deducted from your SUBenefit for the week."
            },
            { "question": "My state requires that I serve a waiting week. How does that impact my SUB eligibility?",
              "response" : "As of the effective date of the 2003 SUB Plan, if you are on a qualifying " +
              "layoff, you will receive full SUB while serving a state waiting " +
              "week."
            },
            { "question": "Who can I contact for questions regarding my payment?",
              "response" : "Questions regarding eligibility and gross amounts should be directed to the " +
                "GM Layoffs and Unemployment Center at 1-800-584-2000.&nbsp;Questions " +
                "regarding taxes, deductions, wage attachments, etc., should be " +
                "directed to the Hourly Payroll Team at 1-866-245-5957."
            },

          ]
      },
      {
        "sections": "Transition Support Program (TSP) for UAW and IAM(PM)",
        "responses":
          [
            { "question": "How is TSP calculated?",
              "response" :  "TSP is another form of payment provided by the SUB Plan. You cannot receive TSP " +
                "until you have exhausted weekly SUB. TSP may be paid as a weekly " +
                "benefit or a lump sum. <a href=\"SampleTSPCalcs.pdf\" target=\"blank\">Sample " +
                "calculations</a>"
            },
            { "question": "Do I need to apply for TSP if I've submitted a lump sum election?",
              "response" :  "Yes, you must apply to receive your lump sum."
            },
            { "question": "How can I change my TSP election?",
              "response" :  " You may send a new TSP election by completing a new <a " +
                "href=\"/CisaWeb/gm/forms/TSP Form.pdf\" target=\"blank\">election form</a> " +
                "and faxing it to (248) 365-9809. You may not change your TSP " +
                "election if TSP payments have started."
            },
            { "question": "Why doesn't my TSP payment match the amount communicated in the TSP election package?",
              "response" :  "The amounts contained in the TSP election package are projections only and are " +
                "based upon data in effect at the time the communication was " +
                "produced. Your actual payment will be determined based upon data in " +
                "effect at the time of payment."
            },
          ]
      },
      {
        "sections": "Unemployment Compensation",
        "responses":
          [
            { "question": "Who do I contact with questions about eligibility for state unemployment compensation " +
                "benefits?",
              "response" :  "You should contact your local unemployment office to determine your " +
                "eligibility."
            },
            { "question": "What is a Monetary Determination?",
              "response" :  "A monetary determination is a written notice issued by the state establishing " +
                "your UC eligibility and your UC benefit entitlement.&nbsp; This " +
                "information is given to you when you initially apply for state UC " +
                "benefits. It includes your benefits year beginning (BYB) date, " +
                "weekly benefit amount (WBA), Maximum Benefit Amount (MBA) payable " +
                "and the number of benefit weeks allowed. You will need to " +
                "provide this information when applying for a layoff benefit."
            },
            { "question": "I have not received my Monetary Determination from the State.&nbsp; Who can I call?",
              "response" :  "You should contact the state UC office where you applied for state UC benefits."
            },
            { "question": "What is my UC benefit year?",
              "response" :  "The filing of a valid UC claim established not only your base period, but also your " +
                "&quot;benefit year&quot;. A benefit year is a one year period usually beginning with the start of " +
                "the first full week for which you file your UC claim."
            },
          ]
      },
    ]

  ngOnInit() {

  }

  toggleAccordion(event) {
    const element = event.target;
    let panel = undefined;

    element.classList.toggle("active");
    panel = element.nextElementSibling;
    this.setPanel(panel);

    if(this.prevExpanded !== undefined){
      if( this.prevExpanded.innerHTML !== element.innerHTML){
        this.prevExpanded.classList.toggle("active");
        panel = this.prevExpanded.nextElementSibling;
        this.setPanel(panel);
        this.prevExpanded = element;
      }else{
        this.prevExpanded = undefined;
      }
    }else{
      this.prevExpanded = element;
    }

  }

  setPanel(panel){
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  }

}
