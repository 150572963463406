import {Component, EventEmitter, HostBinding, Input, OnInit} from '@angular/core';
import {Output} from '@angular/core';

@Component({
  selector: 'app-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.css']
})

export class InputTextareaComponent implements OnInit {

  @HostBinding('class.col-md-6') smallInputClass: Boolean = false;
  @HostBinding('class.col-md-12') bigInputClass: Boolean = true;

  changedValue: string;

  @Output()
  valueChange = new EventEmitter<string>();

  @Input()
  get value(){
    return this.changedValue;
  }

  set value(val) {
    this.changedValue = val;
    this.valueChange.emit(this.changedValue);
    if (this.validator !== undefined) {
      this.validator.bindValue(val);
      this.validate();
    }
  }

  public validate() {
    if (this.validator !== undefined) {
      this.validatorMessages = undefined;
      this.validatorMessages = this.validator.validate(this.value);
    }
  }

  @Input() mandatory: Boolean = false;
  @Input() label: String = '';
  @Input() fieldLevelSecurity: any = undefined;
  @Input() editable: Boolean = true;
  @Input() name: String = '';
  @Input() rows = 5;
  @Input() maxLength: Number = 5000;
  @Input() specialRowSpanningInputs = false;
  @Input() bigInput: Boolean = true;
  @Input() validator: any = undefined;
  @Input() tableInput: Boolean = false;

  validatorMessages = [];
  toolTips = '';

  endingCharacter() {
    if (this.label[this.label.length - 1] === ':'){
      return '';
    }
    else {
      return ':';
    }
  }

  //calls validate function when the user focuses off the input element
  offFocus() {
    this.validate();
  }

  constructor() {}

  ngOnInit() {
    const validate = this.validate;
    const that = this;
    if (!this.bigInput) {
      this.smallInputClass = true;
      this.bigInputClass = false;
    }
    if (this.validator !== undefined) {

      this.validator.bindValue(this.value);
      this.validator.validateObsr.subscribe(data => {
        validate.bind(that)();
      });
      if (this.validator.rules.required === true) {
        this.mandatory = true;
      }
      if (this.validator.rules.inputMaxLen !== null) {
        this.maxLength = this.validator.rules.inputMaxLen;
      }
      if (this.validator.toolTips.length > 0) {
        this.toolTips = this.validator.toolTips.join('\n');
      }
    }
  }
}

