import { Component, OnInit } from '@angular/core';
import {Globals} from '../../../global-variables/globals';

@Component({
  selector: 'app-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.css']
})
export class PrivacyModalComponent implements OnInit {

  message: any;
  data: any;
  selfComponent: any;
  index: number;
  removalObservable: any;

  constructor(public globals: Globals) {}

  close() {
    this.removalObservable.next({index: this.index, data: {}, callback: this.data.callback}); // update the base modal component about the removal
    this.selfComponent.destroy();
  }

  ngOnInit() {
    console.log(this.data);
  }
  getchrome(){
    window.open('https://www.google.com/chrome/index.html', "_blank");
  }
}
