import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef, OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import {Globals} from '../sharedComponents/global-variables/globals';

import {Router, ActivatedRoute} from "@angular/router";
import {NavItem} from "./nav-item";
import {NavService} from "./nav.service";

import * as $ from 'jquery';
window['$'] = window['jQuery'] = $;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements AfterViewInit, AfterContentChecked {
  security = 'A';
  public userName: String = '';
  // screenSecurity = undefined;
  contentId = '#appContentWrapper';
  contentContainerId="#appContentContainer";
  contentElem = undefined;
  navbarContainer = undefined;
  appContentContainer = undefined;
  screenSecurity = undefined;

  sociedadList = [7180, 7830];
  activeSociedad = 7180;


  @ViewChildren('sidenav') public nav !: QueryList<ElementRef>;

  gmlogoclick: boolean = false;

  navItems: NavItem[] = [];

  setNavItems(){
    this.navItems = [
      {
        displayName: 'Home',
        route: 'home',
        screenLevelSecurity: 'A',
        mainLink: true,
       // screenLevelSecurityShow: this.globals.screenData.home,
       },
      //faq
      {
        displayName: 'FAQ',
        route: 'faq',
        screenLevelSecurity: 'A',
        mainLink: true,
        //screenLevelSecurityShow: this.globals.screenData.emulate,
      },
      {
        displayName: 'My Layoff Benefits',
        route: 'mylayoffbenef',
        screenLevelSecurity: 'A',
        mainLink: true,
        //screenLevelSecurityShow: this.globals.screenData.administration,
        children: [
          {
            displayName:  'Apply Weekly Benefit',
            route: '/mylayoffbenef/cisaBenefitInit',
            screenLevelSecurity: 'A',
           // screenLevelSecurityShow: this.globals.screenData.administration,
          },
          {
            displayName:  'Setup/View Monetary Determination',
            route: '/mylayoffbenef/cisaMondetRequest',
            screenLevelSecurity: 'A',
            // screenLevelSecurityShow: this.globals.screenData.administration,
          },
          {
            displayName:  'Processed and Pending History',
            route: '/mylayoffbenef/cisaGetHistory',
            screenLevelSecurity: 'A',
            // screenLevelSecurityShow: this.globals.screenData.administration,
          },
          {
            displayName:  'SUB Plan Counters',
            route: '/mylayoffbenef/cisaGetCounters',
            screenLevelSecurity: 'A',
            // screenLevelSecurityShow: this.globals.screenData.administration,
          },
          {
            displayName:  'TSP Election',
            route: '/mylayoffbenef/cisaTspElection',
            screenLevelSecurity: 'A',
            // screenLevelSecurityShow: this.globals.screenData.administration,
          },
          {
            displayName:  'Submit Documents to Layoff Center',
            route: '/mylayoffbenef/fileUpload',
            screenLevelSecurity: 'A',
            // screenLevelSecurityShow: this.globals.screenData.administration,
          },
          {
            displayName:  'Contact Us',
            route: '/mylayoffbenef/contactUs',
            screenLevelSecurity: 'A',
            // screenLevelSecurityShow: this.globals.screenData.administration,
          },
        ]
      },
      {
        displayName: 'Forms',
        route: 'gmforms',
        screenLevelSecurity: 'A',
        mainLink: true,
        //screenLevelSecurityShow: this.globals.screenData.emulate,
      },
      {
        displayName: 'Voice Options',
        route: 'vru',
        screenLevelSecurity: 'A',
        mainLink: true,
        //screenLevelSecurityShow: this.globals.screenData.emulate,
      },
      {
        displayName: 'Service Hours',
        route: 'hours',
        screenLevelSecurity: 'A',
        mainLink: true,
        //screenLevelSecurityShow: this.globals.screenData.emulate,
      },
      {
        displayName: 'Logout',
        route: '',
        screenLevelSecurity: 'A',
        mainLink: true,
        //screenLevelSecurityShow: this.globals.screenData.emulate,
      },

    ];
  }
  delaySet(ms){
    setTimeout(() => {
      this.setNavItems();
    }, ms);
  }

  constructor(public globals: Globals, public router: Router, private activatedRoute: ActivatedRoute, private navService: NavService, private cdr: ChangeDetectorRef) {
    this.userName = this.globals.userInfo.firstName + ' ' + this.globals.userInfo.lastName;
    // this.service.retrieveScreenAccess().subscribe(data => {
      // this.screenSecurity = data;

      // this.globals.changeLanguage('us');
      this.globals.gmlogoclickval.subscribe((newgmlogoval : boolean) =>{
        this.gmlogoclick = newgmlogoval;});

    this.globals.retrieveScreenAccess().subscribe(data => {
      this.screenSecurity = data;
      this.setNavItems()
    });

  }
  changeLang(newLang: String) {
    // this.globals.changeLanguage(newLang);
    this.delaySet(500);
  }


  changeSociedad(newId) {
    console.log(newId);
    this.activeSociedad = newId;
    this.globals.setActiveSociedad(newId);
  }

  isLargeScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width > 992) {
      return true;
    } else {
      return false;
    }
  }

  printContent() {
    const myWindow = window.open('', 'PRINT');

    myWindow.document.write('<html><head><title>' + document.title + '</title>');
    myWindow.document.write('</head><body >');
    myWindow.document.write(document.getElementById(this.contentId).innerHTML);
    myWindow.document.write('</body></html>');

    myWindow.document.close();
    myWindow.focus();

    setTimeout(() => {
      myWindow.print();
      myWindow.close();
    }, 1000);

    return true;
  }

  // Get the current language string from globals and return the appropriate flag
  currentFlag() {
    const langStr: String = this.globals.languageString;
    switch (langStr) {
      case 'us': {
        return 'flag_us';
      }
      case 'es': {
        return 'flag_es';
      }
      default:
        return 'flag_us';
    }
  }

  ngAfterViewInit() {
    // this.navService.sidenav = this.sidenav;
    this.nav.changes.subscribe((sidenav) => {
      this.navService.sidenav = sidenav.first;
    });

    window.onbeforeprint = () => {
      //move app content up the DOM tree
      $(this.contentId).detach().appendTo(this.navbarContainer);
    };

    window.onafterprint = () => {
      $(this.contentId).detach().appendTo($(this.contentContainerId));
    };

    //get a handle on the navbar's parent elem
    this.navbarContainer = $("#navbarContainer");


    // setTimeout(() => {
    //   this.gmlogoclick = this.globals.gmlogoclick;
    // }, 500)

  }
  // ngOnInit(): void {
  //  // this.gmlogoclick = this.globals.gmlogoclick;
  // }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

}
