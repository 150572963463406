// A module which has no ties to the UI, it only serves to hold variables that should be globally available to all other components
import {Injectable, Inject } from '@angular/core';
import {I18nService} from '../../services/i18n.service';
import {FormChecking} from '../utilities/formChecking';
import {EmailGenerator} from '../utilities/emailGenerator';
import {Observable, Subject} from 'rxjs';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import * as _timezone from 'moment-timezone';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import {DateAdapter} from '@angular/material/core';
import {Router} from '@angular/router';
import {ModalService} from "../../services/modal/modal.service";

const timezone = _timezone;
const moment = _moment;
const FORMAT = 'DD-MMM-YYYY';
const DB2FORMAT = 'MM-DD-YYYY';
const DB2FORMATSLASH = 'MM/DD/YYYY';
const actTimeStamp = 'DD-MMM-YYYY HH:mm:ss';

@Injectable({
  providedIn: 'root'
})
export class Globals {
  i18n: any = undefined;
  languageString: String = '';
  activeSociedad = undefined;

  private localeChange = new Subject<any>();
  private gmlogoclickChange = new Subject<boolean>();
   gmlogoclickval: Observable<boolean>;
  public url = environment.apiURL;

  // list of all code types - use the key as a string when asking for it
  public codeTypes: any = {
    CODE_TYPE_ADVANCE_SCHEDULE_DAYS: 'advanceScheduleDays'
  };

  private codeCache: any = {};
  private fieldSecurity: any = {};
  public userInfo: any = undefined;
  private groupCache: any = null;
  private processSourceCache : any = undefined;
  private skeletonCache : any = {};
  val = '';
   public gmlogoclick = false;
  public captchaverify = false;
  public gminverify = false;
  public systemunvailable = false;
  public systemerror = false;
  public gminattempt = false;
  public gminval = '';
  public logoutval = false;
  public landingmessage = undefined;
  //Temporary Screen Data - until the backend will return the structure

  public screenData = {
    R10: 'A',
    R111: 'A',
    R112: 'A',
    R113: 'A',
    R114: 'A',
    R115: 'A',
    R116: 'A',
    R20: 'A',
    R211: 'A',
    R212: 'A',
    R213: 'A',
    R214: 'A',
    R215: 'A',
    R216: 'A',
    R217: 'A',
    R218: 'A',
    R219: 'A',
    R220: 'A',
    R221: 'A',
    R222: 'A',
    R223: 'A',
    R224: 'A',
    R30: 'A',
    R311: 'A',
    R312: 'A',
    R313: 'A',
    R314: 'A',
    R315: 'A',
    R316: 'A',
    R317: 'A',
    R318: 'A',
    R319: 'A',
    R320: 'A',
    R40: 'A',
    R411: 'A',
    R412: 'A',
    R413: 'A',
    R414: 'A',
    R415: 'A',
  };

  retrieveNavitem(){
    window.sessionStorage.setItem('gmlogoclick', 'true');
    this.changegmlogoclick(true);

    // this.router.navigate(['/home']);
  }

  retrieveUserInfo(url) {
    this.userInfo = {};
    // this.http.get(url).subscribe(response => {
    //   this.userInfo.userId = response['authDetails']['user_name'];
    //   this.userInfo.firstName = response['authDetails']['given_name'];
    //   this.userInfo.lastName = response['authDetails']['family_name'];
    //   this.userInfo.user_name = response['authDetails']['user_name'];
    //   this.userInfo.phone_number = response['authDetails']['phone_number'];
    //   this.userInfo.given_name = response['authDetails']['given_name'];
    //   this.userInfo.family_name = response['authDetails']['family_name'];
    //   this.userInfo.email = response['authDetails']['email'];
    //   this.userInfo.userDetails = response['userDetails'];
    //   console.log('userInfo: ');
    //   console.log( this.userInfo);
    //   console.log(response);
    // });
  }


  public retrieveSkeleton(path) {
    const fetchPS = new Subject<any>();
    // in order to get the observable to work after it has been returned by retrieveLookup, we invoke its update after the current call stack has been cleared
    setTimeout(() => {
      // check if it has already been cached, if so, return the cached value
      if (this.skeletonCache.hasOwnProperty(path)) {
        fetchPS.next(this.cloneData(this.skeletonCache[path]));
      }
      // if not, ask the Service for it and then cache it
      else {
        this.http.get(this.url + '/utils/getControllerMethodReturnObject/' + path).subscribe(data => {
          this.skeletonCache[path] = data;
          fetchPS.next(this.cloneData(data));
        });
      }
    }, 0);
    return fetchPS;
  }

  public retrieveScreenAccess() {
    const tempSolution = new Subject<any>();
    let that = this;
    setTimeout(function() {tempSolution.next(that.screenData)}, 250);

    return tempSolution;
  }


  public retrieveTimeValuesHalfHourIncrements() {
    const timeDropDown = [];
    for (let i = 0; i < 24; i++) {
      const timeString = '' + i;
      let label1 = '';
      let label2 = '';
      if (timeString.length === 1) {
        label1 = '0' + i + ':00';
        label2 = '0' + i + ':30';
      } else if (timeString.length === 2) {
        label1 = i + ':00';
        label2 = i + ':30';
      }
      timeDropDown.push([label1, label1]);
      timeDropDown.push([label2, label2]);
    }
    return timeDropDown;
  }

  public retrieveTimeValuesQuarterHourIncrements() {
    const timeDropDown = [];
    for (let i = 0; i < 24; i++) {
      const timeString = '' + i;
      let label1 = '';
      let label2 = '';
      let label3 = '';
      let label4 = '';
      if (timeString.length === 1) {
        label1 = '0' + i + ':00';
        label2 = '0' + i + ':15';
        label3 = '0' + i + ':30';
        label4 = '0' + i + ':45';
      } else if (timeString.length === 2) {
        label1 = i + ':00';
        label2 = i + ':15';
        label3 = i + ':30';
        label4 = i + ':45';
      }
      timeDropDown.push([label1, label1]);
      timeDropDown.push([label2, label2]);
      timeDropDown.push([label3, label3]);
      timeDropDown.push([label4, label4]);
    }
    return timeDropDown;
  }

  setActiveSociedad(newId) {
    this.activeSociedad = newId;
  }

  getLanguageString = () => {
    // If the language was already saved, retrieve it
    if (localStorage.getItem('lang')) {
      this.languageString = localStorage.getItem('lang');
    } else {
      this.languageString = 'us';
    }
  };

  // DATE FUNCTIONS
  appDateTimeToMillis(appDate) {
    if (appDate !== null) {
      return moment(moment(appDate['miliseconds']).format('YYYY-MM-DD') + ' ' + appDate['24HourTimeStringWithoutSeconds']).valueOf();
    }
    return null;
  }
  appDateToMillis(appDate) {
    if (appDate !== null) {
      return moment(moment(appDate['miliseconds']).format('YYYY-MM-DD')).valueOf();
    }
    return null;
  }
  formatTime(unixTime) {
    return moment(new Date(unixTime)).format('HH:mm');
  }
  unformatUnixTime(unixTime, time) {
    return moment(moment(new Date(unixTime)).format('YYYY-MM-DD') + ' ' + time).valueOf();
  }
  unformatTime(date, time) {
    return moment(moment(new Date(date.year, date.month - 1, date.day)).format('YYYY-MM-DD') + ' ' + time).valueOf();
  }
  diffDays(endMomentDate, startMomentDate){
    const endMoment = moment(endMomentDate);
    const startMoment = moment(startMomentDate);
    return endMoment.diff(startMoment, 'days');
  }
  getNow(){
    return moment();
  }

  timeStampToString(timestamp) {
    let t = new Date(timestamp);

    return (t.getMonth() + 1) + '/' + (t.getDate()) + '/' + t.getFullYear();
  }
  // END DATE FUNCTIONS
  changegmlogoclick(newval: boolean){
    this.gmlogoclick = newval;
    this.gmlogoclickChange.next(this.gmlogoclick);
  }

  changeLanguage(newLang: String) {
    // console.log('change language to: ' + newLang);
    // this.languageString = newLang;
    // localStorage.setItem('lang', newLang.toString());
    // this.service.getProperties(newLang).subscribe(data => {
    //   if (data !== undefined) {
    //     this.i18n = data;
    //     this.localeChange.next({locale: this.getLocale()});
    //   }
    // });
    // this.service.changeLanguage(newLang).subscribe(data => {
    //   if (data !== undefined) {
    //     console.log('Language Change ' + data);
    //   }
    // });
  }

  public getLocale() {
    switch (this.languageString) {
      case 'us': {
        return 'en-US';
      }
      case 'es': {
        return 'es-ES';
      }
      default: {
        return 'en-US';
      }
    }
  }
  cloneData(obj){
    return JSON.parse(JSON.stringify(obj));
  }
  cloneDataAndClear(obj){
     const msg = JSON.parse(JSON.stringify(obj));
     return msg;
  }

  // takes a string with {0} in its value and replaces it with the value passed in
  public replaceToken(key, value) {
    let res = key.replace('{0}', value);
    return res;
  }

  constructor(private service: I18nService, public formChecking: FormChecking, public modal: ModalService,
              public emailGenerator: EmailGenerator, private adapter: DateAdapter<any>,
              public http: HttpClient, @Inject(DOCUMENT) private document: any, public router: Router )  {

    this.http.post(this.url + '/home/landing', '').subscribe(response => {
      this.landingmessage = response;
      console.log(this.landingmessage);
      if (this.landingmessage >= 1) {
        const todaydate = new Date();
        const  response1 =  this.landingmessage.filter(row => new Date(this.formatDateStr(row.endDate, false, false)) >= new Date(this.formatDateStr(todaydate, false, false)));
        this.landingmessage = response1;
      }
    }, error => {
      this.landingmessage = undefined;
    });

    this.gmlogoclickval = this.gmlogoclickChange.asObservable();
    this.getLanguageString();
    this.service.getProperties(this.languageString).subscribe(data => {
      if (data !== undefined) {
        this.i18n = data;
      }
    });

    this.adapter.setLocale('en-US');
    this.getLocaleUpdate().subscribe(data => {
      this.adapter.setLocale('en-US');
    });
    timezone.tz.setDefault('America/Detroit');

    this.val = this.document.location.href;
    const urlUser: String = this.url + '/security/user';
    if (environment.ssoEnabled) {
      console.log('SSO-Enabled Configuration');
      this.retrieveUserInfo(urlUser);
    }
    else {
      console.log('Default Configuration');
      this.userInfo = {
        userId: 'BZ9SP7',
        firstName: 'Sivakumar',
        lastName: 'Ramakrishnan',
        user_name: 'BZ9SP7',
        phone_number: '+1 404-422-5195',
        given_name: 'c',
        family_name: 'Ramakrishnan',
        email: 'Ramakrishnan.Ramakrishnan@gm.com'
      };
    }


  }
  logout(){
    window.sessionStorage.setItem('gmlogoclick', 'false');
    this.changegmlogoclick(false);
    this.captchaverify = false;
    this.gminattempt = false;
    this.systemunvailable = false;
    this.gminverify = false;
    this.systemerror = false;
    this.gminval = '';
    this.logoutval = true;
    this.router.navigate(['']);
  }

  getprivacyinfo(){
    this.modal.createModal('Privacy',  {
      message: [this.i18n.messages.privacy.text1, this.i18n.messages.privacy.text2, this.i18n.messages.privacy.text3],
      callback: data =>{
        if (data.informative){
          return;
        }else{

        }
      }

    });
  }
  getLocaleUpdate(): Observable<any> {
    return this.localeChange.asObservable();
  }

  /** pass in dateObject (GMDate for example)= false if the date is a unix time
   * pass in timeStamp = true if you want to include Hours, Mins, and Secs*/
  formatDateStr(dateMeta, dateObject = true, timeStamp = false) {
    let dateObj;
    const locale = this.getLocale();

    if (dateMeta !== null && dateMeta !== undefined && dateMeta !== "now") {
      if (dateMeta.null === true) {
        return ("");
      }
      if (dateObject) {
        dateObj = new Date(dateMeta.year, dateMeta.month - 1, dateMeta.day);
      }
      else {
        dateObj = new Date(dateMeta);
        /*
        if(dateMeta.toString().length === 13 || dateMeta.toString().length === 15) {
          dateObj = new Date(dateMeta);
        } else {
          dateObj = new Date(dateMeta * 1000);
        }
        */
      }
    }
    else if (dateMeta === "now") {
      dateObj = new Date();
    }
    else {
      // Commented out this line to return null instead,
      // if we want the current date, we might want to handle
      // differently.
      // dateObj = new Date();
      return null;
    }
    moment.locale(locale);
    let dateMoment = moment(dateObj).utc();
    //if it is a date object, take the local comp's system time
    if (dateObject) {
      dateMoment = timezone.tz(dateObj.valueOf(), Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
    let dateStr;
    if (timeStamp /*&& dateMoment.seconds() !== 0*/) {
      dateStr = dateMoment.format(actTimeStamp);
    } else {
      dateStr = dateMoment.format(DB2FORMAT);
    }
    return dateStr;
  }
  /** pass in dateObject (GMDate for example)= false if the date is a unix time
   * pass in timeStamp = true if you want to include Hours, Mins, and Secs*/
  formatDateStrslash(dateMeta, dateObject = true, timeStamp = false) {
    let dateObj;
    const locale = this.getLocale();

    if (dateMeta !== null && dateMeta !== undefined && dateMeta !== "now") {
      if (dateMeta.null === true) {
        return ("");
      }
      if (dateObject) {
        dateObj = new Date(dateMeta.year, dateMeta.month - 1, dateMeta.day);
      }
      else {
        dateObj = new Date(dateMeta);
        /*
        if(dateMeta.toString().length === 13 || dateMeta.toString().length === 15) {
          dateObj = new Date(dateMeta);
        } else {
          dateObj = new Date(dateMeta * 1000);
        }
        */
      }
    }
    else if (dateMeta === "now") {
      dateObj = new Date();
    }
    else {
      // Commented out this line to return null instead,
      // if we want the current date, we might want to handle
      // differently.
      // dateObj = new Date();
      return null;
    }
    moment.locale(locale);
    let dateMoment = moment(dateObj).utc();
    //if it is a date object, take the local comp's system time
    if (dateObject) {
      dateMoment = timezone.tz(dateObj.valueOf(), Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
    let dateStr;
    if (timeStamp /*&& dateMoment.seconds() !== 0*/) {
      dateStr = dateMoment.format(actTimeStamp);
    } else {
      dateStr = dateMoment.format(DB2FORMATSLASH);
    }
    return dateStr;
  }
  /**
   * Pads num by appending zeros to the front of the string to get it to the desired number of digits.
   * @param num
   * @param size
   */
  pad(num: number, size: number): string {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }

  /**
   * Formats auth code and pads the sequence number with 7 digits (leading zeros if necessary).
   * @param businessUnit
   * @param prefix
   * @param sequence
   */
  formatAuthCode(businessUnit, prefix, sequence) {
    let returnVal = null;
    if (businessUnit !== null) {
      returnVal = businessUnit;
      if (prefix !== null) {
        returnVal += prefix;
        if (sequence !== null) {
          returnVal += this.pad(sequence, 7);
        }
      }
    }
    return returnVal;
  }

  /**
   * Formats the status field as statusCode - statusDescription.
   * If there is no code, return null. If there is no status description, return just the code.
   * @param statusCode
   * @param statusDescription
   */
  formatStatus(statusCode, statusDescription) {
    if (statusCode === null) {
      return null;
    } else if (statusDescription === null) {
      return statusCode;
    }
    return statusCode + ' - ' + statusDescription;
  }
}
