<div class="row">
  <div [ngClass]="{'col-md-2': !specialRowSpanningInputs && bigInput, 'col-md-4': specialRowSpanningInputs || !bigInput}"  *ngIf="label">
    <label><span *ngIf="mandatory" style="color: red">*</span>{{label + endingCharacter()}}</label>
  </div>
  <div [ngClass]="{ 'col-md-12': !label, 
                    'col-md-10': label && (!specialRowSpanningInputs && bigInput), 
                    'col-md-8':  label && (specialRowSpanningInputs || !bigInput)}">
 
    <mat-form-field style="width: 100%"
                    *ngIf="((fieldLevelSecurity === 'U' || fieldLevelSecurity === 'A') && editable) || (fieldLevelSecurity === undefined && editable)" [ngClass]="{'tableInput': tableInput}">
      <textarea [rows]="rows" matInput [(ngModel)]="value" [name]="name" [maxLength]="maxLength"
                (blur)="offFocus(); tooltip.hide()" [matTooltip]="toolTips" (focus)="tooltip.show()" #tooltip="matTooltip"></textarea>
    </mat-form-field>
    <mat-form-field style="width: 100%"
                    *ngIf="(!editable && (fieldLevelSecurity === 'U' || fieldLevelSecurity === 'A')) || (fieldLevelSecurity === undefined && !editable) || fieldLevelSecurity === 'I'" [ngClass]="{'tableInput': tableInput}">
      <textarea [rows]="rows" matInput [ngModel]="value" [name]="name" [maxLength]="maxLength" [disabled]="true"
                (blur)="offFocus(); tooltip.hide()" [matTooltip]="toolTips" (focus)="tooltip.show()" #tooltip="matTooltip"></textarea>
    </mat-form-field>
    <mat-form-field style="width: 100%" *ngIf="fieldLevelSecurity === 'N'" [ngClass]="{'tableInput': tableInput}">
      <textarea [rows]="rows" matInput [name]="name" [maxLength]="maxLength" [disabled]="true"></textarea>
    </mat-form-field>
    <div *ngFor="let message of validatorMessages" class="inputValidationMessage" [ngClass]="{'inputValidationMessageTable' : tableInput}">
      {{message}}
    </div>
  </div>
</div>
