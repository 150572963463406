import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {Globals} from '../../sharedComponents/global-variables/globals';


@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})

export class FormsComponent implements OnInit {

  isActiveLink: boolean;

  constructor(public globals: Globals) {
      this.isActiveLink = false;
  }

  openState(){
    window.open(this.globals.i18n.link.stateucinfo);
  }

  ngOnInit() {

  }



}
