import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Globals} from '../../sharedComponents/global-variables/globals';

@Injectable({
  providedIn: 'root'
})
export class LandingService{
  private url = this.globals.url;
     // landingmessage = ['Employees from an AutoSUB State or plant, who are laid off due to COVID-19, MUST allow the AutoSUB process to apply for your SUB benefits. Do not apply for your weekly SUB benefits using the IVR or Web site as benefits applied for in this manner will not be processed timely.',
     //   'Please be aware this website no longer supports Microsoft Internet Explorer. Using Internet Explorer with this website will prevent the web pages from functioning properly.'];

  constructor(private http: HttpClient, private globals: Globals) {}

  getlandingmessage(): any{
     //return this.landingmessage.slice();
    return this.http.post(this.url + '/home/landing','');
  }

 }
