import { Component, OnInit } from '@angular/core';
import {Globals} from "../../sharedComponents/global-variables/globals";
import {Router} from "@angular/router";
import {CaptchaService} from  '../../services/my-layoff-benefits/captcha.service';
import {DomSanitizer} from  '@angular/platform-browser';
import {FormValidator} from "../../sharedComponents/formValidation/formValidator";
import {ModalService} from "../../services/modal/modal.service";

@Component({
  selector: 'app-my-layoff-benefits',
  templateUrl: './my-layoff-benefits.component.html',
  styleUrls: ['./my-layoff-benefits.component.css']
})
export class MyLayoffBenefitsComponent implements OnInit {
  constructor(public globals: Globals) {
  }

  ngOnInit(): void {
  }

}
