<!--<div class="searchContainer"  *ngIf="globals.gmlogoclick !== false">-->
<app-loading *ngIf="loadingPage"></app-loading>
<div class="searchContainer">
  <app-expandable *ngIf="subPlanCountersPage" [expandable]="false" [title]="globals.i18n.title.counters">
         <div class="row centeredFlex">
   <div class="resultsContainer" *ngIf = "returnCode==='000'" >
           <div class="tableContainer"  >
          <table mat-table matSort matSortDirection="asc" multiTemplateDataRows [dataSource]="searchResults"
                 class="mat-elevation-z2 searchResultsTable">
            <ng-container matColumnDef="descr">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Counter Description</th>
              <td mat-cell *matCellDef="let data" class="centre">{{data.descr}}</td>
            </ng-container>
            <ng-container matColumnDef="origWksQty">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Original Weeks on Counter</th>
              <td mat-cell *matCellDef="let data" class="centre">{{ data.origWksQty }}</td>
            </ng-container>
            <ng-container matColumnDef="wksRemngQty">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Remaining Weeks on Counter</th>
              <td mat-cell *matCellDef="let data" >{{ data.wksRemngQty }}</td>
            </ng-container>
            <ng-container matColumnDef="updtDt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Counter Last Updated</th>
              <td mat-cell *matCellDef="let data" >{{ data.updtDt }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="getDisplayColumns(); sticky:true" class="tableHeader"></tr>
            <tr mat-row *matRowDef="let row; columns: getDisplayColumns();"
                class="tableRow"></tr>

          </table>

        </div>
      <br>
      <div class = "row centeredFlex noDataMessage">
        {{noDataMessage}}
      </div>
      <br>
      <br>
      <div  class="tableContainer1">
        Click to return to My Layoff Benefits Menu
      </div>
      <div class="tableContainer1">
        <app-button class="margin" type="primary" value="My Layoff Benefits"
                    (click)="navigateToHome()" (keyup.enter)="navigateToHome()"
        ></app-button>
      </div>
      <br>
      <div  class="row centeredFlex">
        <a (click)="getprivacyinfo()">Privacy Information</a>
      </div>

         </div>

   </div>

    <div class="resultsContainer" *ngIf="returnCode==='999'">
      <app-system-error></app-system-error>
    </div>
    <div class="resultsContainer" *ngIf="returnCode==='888'">
      <app-system-unavailable></app-system-unavailable>
    </div>
    <div class="resultsContainer" *ngIf="returnCode==='912'">
      <app-gmin-invalid></app-gmin-invalid>
    </div>

  </app-expandable>
</div>
<!--</div>-->
