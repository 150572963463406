import {Injectable, Injector, Input, ViewChild} from '@angular/core';
import {ModalService} from "../../services/modal/modal.service";
import {LandingService} from '../../services/landing/landing.service';
import {HomeService} from "../../services/home/home.service";
import {Route, Router} from "@angular/router";
// import {viewClassName} from "@angular/compiler";
import {InformativeModalComponent} from "../modal/modalComponents/informative/informative-modal.component";


@Injectable({
  providedIn: 'root'
})

export class SessionTimer {

  private timeoutPeriod: number = 750000; //30 minutes, in milliseconds
  private expiredPeriod: number = 100000; //the absolute maximum session length - force a refresh after this point
  private timeout: any = undefined;
  private secondTimeout: any = undefined;
  private service;
  private injector;

  constructor(public modal: ModalService, injector: Injector, public router: Router) {
    this.injector = injector;
  }

  //called when the timer runs out
  timeoutHandler() {
    //import this here intead of the constructor to avoid race conditions with the properties.
    // this.service = this.injector.get(HomeService);
    // this.modal.createModal('Informative', {
    //   message: "Your session will expire in 5 minutes, press OK to extend your session",
    //   callback: data => {
    //    // if (data.confirmation === true) {
    //       //cancel the second timeout
    //       if (this.secondTimeout !== undefined) {
    //         clearTimeout(this.secondTimeout);
    //       }
    //      this.service.loadToDo().subscribe(() => {
    //         console.log("session extended");
    //       });
    //    }
    //
    // });
    this.startSecondTimer();

  }

  secondTimeoutHandler() {
     this.modal.createModal('Informative', {
      message: "Your session has expired, press OK to refresh",
      callback: data => {
        // if ()
        window.sessionStorage.setItem('gmlogoclick', 'true');
        window.sessionStorage.setItem('sesexpire', 'true');
        // this.globals.captchaverify = false;
        // this.globals.gminattempt = false;
        // this.globals.systemunvailable = false;
        // this.globals.gminverify = false;
        // this.globals.systemerror = false;
        // this.globals.gminval = '';
        this.router.navigate(['']);
       // location.reload();
      }
    });
    // this.startThirdTimer();
  }
  thirdTimeoutHandler() {
    window.sessionStorage.setItem('sesexpire', 'true');
    location.reload();
  }

  startSecondTimer() {
    if (this.secondTimeout !== undefined) {
      clearTimeout(this.secondTimeout);
    }
    this.secondTimeout = setTimeout(() => {this.secondTimeoutHandler()}, this.timeoutPeriod);
  }

  startThirdTimer() {
    if (this.secondTimeout !== undefined) {
      clearTimeout(this.secondTimeout);
    }
    this.secondTimeout = setTimeout(() => {this.thirdTimeoutHandler()}, this.timeoutPeriod);
  }

  restartTimer() {
    // console.log("restart timer");
    //first, clear out any existing timeout
    if (this.timeout !== undefined) {
      clearTimeout(this.timeout);
    }
    if (this.secondTimeout !== undefined) {
      clearTimeout(this.secondTimeout);
    }
    this.timeout = setTimeout(() => {this.timeoutHandler()}, this.timeoutPeriod);

        // check to see if the 403 fix was triggered only once
        let flag = sessionStorage.getItem("noAccessFlag");
        if (typeof(flag) === "string") {
          // check to see if we surpassed the expiration time
          let expString = sessionStorage.getItem("noAccessExpiration");
          let exp = new Date(Number(expString));
          let now = new Date();
          if (now > exp) {
            console.log("clearing Session");
            // get current gmlogo click after clear set it back
            sessionStorage.clear();
           }
        }

  }

}
