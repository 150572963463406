
<app-expandable [expandable]="false" title= "{{globals.i18n.title.message.text5}}">
  <div class ="searchContainer">
  <div class="row centeredFlex">
    <table>
      <p>
      <tr class="row centeredFlex trHeight">
        {{globals.i18n.title.message.text18}}
      </tr><br>
      <tr class="row centeredFlex trHeight">
          Dial {{globals.i18n.title.message.text6}} (IVR)
      </tr>
      <tr class="row centeredFlex trHeight">
        or
      </tr>
      <tr class="row centeredFlex trHeight">
        Click on &nbsp;<a routerLink="/mylayoffbenef" routerLinkActive="active" routerLinkActiveOptions="{exact:true}"> My Layoff Benefits</a>
      </tr><br>
      <tr class="row centeredFlex trHeight">
        {{globals.i18n.title.message.text7}}
      </tr>
      <tr class="row centeredFlex  trHeight">
        {{globals.i18n.title.message.text8}}
      </tr>
      <tr class="row centeredFlex trHeight">
        {{globals.i18n.title.message.text9}}
      </tr>
      <tr class="row centeredFlex trHeight">
        {{globals.i18n.title.message.text10}}
      </tr>
      <tr class="row centeredFlex  trHeight">
        {{globals.i18n.title.message.text11}}
      </tr>
      <tr class="row centeredFlex  trHeight">
        {{globals.i18n.title.message.text12}}
      </tr>
      <tr class="row centeredFlex  trHeight">
        {{globals.i18n.title.message.text13}}
      </tr>
      <tr class="row centeredFlex  trHeight">
        {{globals.i18n.title.message.text14}}
      </tr>
      </p>

      <p>
      <tr class="row centeredFlex  trHeight">
        {{globals.i18n.title.message.text15}}
      </tr>
      <!--<tr class="row centeredFlex  trHeight">
      Service Representative
    </tr>-->
      <tr class="row centeredFlex  trHeight">
        Dial {{globals.i18n.title.message.text6}}
      </tr>
      <tr class="row centeredFlex  trHeight">
        {{globals.i18n.title.message.text16}}
      </tr>
      <tr class="row centeredFlex  trHeight">
        {{globals.i18n.title.message.text17}}
      </tr>
      <tr class="row centeredFlex  trHeight">
        {{globals.i18n.title.message.text14}}
      </tr>
      </p>
    </table>
  </div>
  </div>
</app-expandable>
