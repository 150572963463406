import { Component, Input, OnInit } from '@angular/core';
import { Globals } from "../global-variables/globals";

@Component({
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.html',
  styleUrls: ['./scroll-top.css']
})
export class ScrollToTop implements OnInit {
  
  @Input() animationLength: Number = 500;
  constructor(public globals: Globals) { }

  ngOnInit() {
  }
  scrollToTop() {
    let animationDuration = 500; //time of animation in ms
    let startTime: any = new Date;
    let endTime: any = undefined;
    let appDiv = document.getElementById('appContentWrapper');
    let scrollPoint = appDiv.scrollTop;
    function animate() {
      endTime = new Date();
      appDiv.scrollTo(0, scrollPoint - (((endTime - startTime) / 500) * scrollPoint));
      if ((endTime - startTime) < animationDuration) {
        requestAnimationFrame(animate);
      }
    }
    requestAnimationFrame(animate);
  }

  scrollToTopElem(elem) {
    elem.scrollIntoView({behavior: 'smooth'});
  }

  scrollToBottom() {
    const appDiv = document.getElementById('appContentWrapper');
    const scrollPoint = appDiv.scrollHeight ;
    appDiv.scrollTo(0, scrollPoint);
  }

}
