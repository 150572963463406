import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Globals} from '../../sharedComponents/global-variables/globals';

@Injectable({
  providedIn: 'root'
})
export class PaymentHistory{
  private url = this.globals.url;

  constructor(private http: HttpClient, private globals: Globals) {}

  getHistory(searchModel): any{
    return this.http.post(this.url + '/cisaGetHistory/startHistoryH0', searchModel);
  }

  rejectHistoryH1(searchModel): any{
    return this.http.post(this.url + '/cisaGetHistory/rejectHistoryH1', searchModel);
  }

 }
