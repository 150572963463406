import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Globals} from '../../../sharedComponents/global-variables/globals';

@Injectable({
  providedIn: 'root'
})
export class UbetelctService{
  private url = this.globals.url;

  constructor(private http: HttpClient, private globals: Globals) {}

  getTspTypeCode(searchModel): any{
    return this.http.post(this.url + '/tspFrequencyT0/getTspFreq',searchModel);
   //return this.http.post(this.url + '/cisaTspElection/getTspFreq',searchModel);
    //cisaTspElection
  }

 }
