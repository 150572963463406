import {NgModule, Pipe, PipeTransform} from "@angular/core";
import {DecimalPipe} from "@angular/common";
import {ToNegativeCurrencyPipe} from "./negativeCurrency.pipe";

@Pipe({
  name: 'toNumber'
})

export class ToNumberPipe implements PipeTransform {

  transform(value):any {
    if(value === '') {
      return null;
    }
    let retNumber = Number(value);
    return isNaN(retNumber) ? value : new ToNegativeCurrencyPipe().transform(new DecimalPipe('en-US').transform(retNumber, '1.2-2'));
  }
}
