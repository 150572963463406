<div class="modalContainer">
  <div class="messageTitle">
    {{globals?.i18n.error.application.title}}
  </div>
  <div class="messageContainer">
    {{data.message}}
  </div>
  <div class="controlsContainer">
    <app-button type="generic" value="OK" (click)="close()"></app-button>
  </div>
</div>
