// Catches any form errors and updates the passed in screen messages
import { Injectable } from '@angular/core';
// import {formattedError} from "@angular/compiler";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class FormChecking {

  constructor(private snackBar: MatSnackBar) {}

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {panelClass: ['snackBar', 'snackBarError']});
  }

  checkResponse(response, screenMessages, screenErrors) {
    if (response === null) {
      return true;
    }
    else if ( ( (response.hasOwnProperty('trace')) &&
                (response['trace'].toLowerCase().indexOf('exception') >= 0)
              ) ||
              response.hasOwnProperty('exception')
              ||
              response.hasOwnProperty('error')
            ) {
      const splitted = response.message.split('@');
      for (const entry of splitted) {
        let formattedEntry = entry;
        if(formattedEntry.indexOf(' - null') !== -1) {
          formattedEntry = formattedEntry.replace(' - null', '');
        }
        if(formattedEntry.indexOf('[') !== -1 && formattedEntry.indexOf(']') !== -1) {
          const errors = formattedEntry.split(", ");
            for (const error of errors) {
              let formattedError = error;

              if(formattedError[0] === '[') {
                formattedError = formattedError.substr(1, formattedError.length - 1);
              }
              if(formattedError[formattedError.length - 1] === ']') {
                formattedError = formattedError.substr(0, formattedError.length - 1);
              }
              if(formattedError[formattedError.length - 1] !== '.') {
                formattedError = formattedError + '.';
              }
              screenErrors.push(formattedError);
            }
          //const errs = entry.message.split(", ");
          //screenErrors.push(entry.substr(1, entry.length - 2));
        } else {
          screenErrors.push(formattedEntry);
        }
      }
      /*
      if(screenErrors.length !== 0) {
        for(let i = 0; i < screenErrors.length; i++) {
          this.openSnackBar(screenErrors[i], 'Ok');
        }
      }
      */
      return false;
    }
    else {
      return true;
    }
  }
}
