<!--
<mat-divider *ngIf="item.children || item.mainLink"></mat-divider>
-->

<!-- [ngStyle]="{'padding-left': (depth * 12) + 'px'}" -->
<a mat-list-item
   (click)="item.screenLevelSecurity !== undefined && item.screenLevelSecurity !== 'N' && onItemSelected(item)"
   [class.disabled]="item.screenLevelSecurity === undefined || item.screenLevelSecurity === 'N'"
   [disableRipple]="item.screenLevelSecurity === undefined"
   [ngClass]="{ 'active': item.route ? isActive(): false,
                'activeHeader': isActiveHeader(),
                'expanded': expanded,
                'childMenu': !(item.children || item.mainLink),
                'mainLink': item.children || item.mainLink}"
   [title]="item.screenLevelSecurity === undefined ? 'No Access' : ''"
   class="menu-list-item"> {{item.displayName}}
<!--  <div *ngIf ="globals.captchaverify !== false && globals.gminverify !== false">-->
  <span fxFlex *ngIf="item.children && item.children.length">

      <span fxFlex></span>
      <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
        expand_more
      </mat-icon>

  </span>
<!--  </div>-->
</a>


<!--    <span fxFlex *ngIf="item.children && item.children.length">-->
<!--       <div *ngIf ="globals.captchaverify !== false && globals.gminverify !== false">-->
<!--      <span fxFlex></span>-->
<!--      <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">-->
<!--        expand_more-->
<!--      </mat-icon>-->
     <div *ngIf="expanded">
       <div *ngIf ="globals.captchaverify !== false && globals.gminverify !== false">
      <navbar-item *ngFor="let child of item.children" [item]="child" [depth]="depth+2"></navbar-item>
       </div>
     </div>
<!--       </div>-->
<!--    </span>-->



