import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Globals} from '../../sharedComponents/global-variables/globals';
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  private url = this.globals.url;
   constructor(private http: HttpClient, private globals: Globals) {
  }

  sendContactMail(searchModel): any {
    return this.http.post(this.url + '/mylayoffbenef/contactus', searchModel, {responseType: "text"});
  }
}
