import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Globals} from '../../../sharedComponents/global-variables/globals';
import {SubPlanCounters} from '../../../services/my-layoff-benefits/subPlanCounters';
import {MatTableDataSource} from '@angular/material/table';
import {DataSource} from '@angular/cdk/collections';
import {ModalService} from '../../../services/modal/modal.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-subPlanCounters',
  templateUrl: './SubPlanCounters.component.html',
  styleUrls: ['./SubPlanCounters.component.css'],
})

export class SubPlanCountersComponent implements OnInit {
  constructor(
    public globals: Globals,
    public service: SubPlanCounters,
    private modal: ModalService,
    public router: Router,
  ){
    this.initializePage();
  }

  displayColumns = ['descr', 'origWksQty', 'wksRemngQty', 'updtDt'];
  searchResults = undefined;
  noDataMessage = undefined;
  loadingPage = false;
  subPlanCountersPage = false;
  searchModel = {
    gmin: '',
  };
  returnCode: any = undefined;
  ngOnInit(): void {
}

  initializePage(){
    this.loadingPage = true;
    this.search();
  }

  getDisplayColumns(){
    return this.displayColumns;
  }

  setGridData(response){
    console.log('setGridData');
    console.log('Response ' + response);
    console.log( 'getCounters ' + response[0].descr);
  }

  test(data){
    console.log(data);
  }



  search() {
    this.searchModel = {
      gmin: this.globals.gminval,
    };
    this.service.getCounters(this.searchModel).subscribe(response => {
      this.loadingPage = false;
      this.subPlanCountersPage = true;
       this.returnCode = response[0].returnCode;
     if (response[0].returnCode === '000') {
        if (response[0].descr === 'NO COUNTER DATA') {
          this.searchResults = undefined;
          this.noDataMessage = 'Counter Data Not Available';
        } else {
          for (const result of response) {
            console.log('RESULT' + result);
            this.setFormatResult(result);
            if (result.updtDt === '01-01-0001'){
              result.updtDt = '00-00-0000';
            }
            this.paddingOfResult(result,3);
          }
          this.searchResults = new MatTableDataSource(response);
        }
      }

    }, error => {
      console.log(error);
      this.returnCode = '999';
      this.loadingPage = false;
    });

  }
  setFormatResult(dto){
    dto.updtDt = this.globals.formatDateStr(dto.updtDt, false, false);
  }
  getprivacyinfo(){
    this.modal.createModal('Privacy',  {
      message: [this.globals.i18n.messages.privacy.text1, this.globals.i18n.messages.privacy.text2, this.globals.i18n.messages.privacy.text3],
      callback: data => {
        if (data.informative){
          return;
        }else{

        }
      }

    });
  }


  navigateToHome(){
    this.router.navigate(['mylayoffbenef']);
  }

  paddingOfResult(dto,paddingNumber){
    dto.origWksQty = dto.origWksQty.toString();
    while(dto.origWksQty.length<paddingNumber){
      dto.origWksQty = "0"+dto.origWksQty;
    }

    dto.wksRemngQty = dto.wksRemngQty.toString();
    while(dto.wksRemngQty.length<paddingNumber){
      dto.wksRemngQty = "0"+dto.wksRemngQty;
    }

  }
}
