import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Globals } from '../../sharedComponents/global-variables/globals';
import {MatTableDataSource} from "@angular/material/table";
import {PaymentHistory} from "../../services/paymentHistory/paymentHistory";
import {DataSource} from '@angular/cdk/collections';
import {ScrollToTop} from "../../sharedComponents/scrollToTop/scroll-top";
import {MatPaginator} from "@angular/material/paginator";
import {ModalService} from "../../services/modal/modal.service";
import {Router} from "@angular/router";
import {MatSort, Sort} from '@angular/material/sort';
@Component({
  selector: 'app-paymenthistory',
  templateUrl: './paymenthistory.component.html',
  styleUrls: ['./paymenthistory.component.css']
})

export class PaymentHistoryComponent implements OnInit{

  constructor(
    public globals: Globals,
    public service: PaymentHistory,
    private scrollToTop: ScrollToTop,
    private modal: ModalService,
    private router: Router,
  ) {
    this.initializePage();
  }
  @ViewChild('paidTableSort', {read: MatSort}) set content(content: ElementRef) {

      this.paidsort = content;

    if (this.paidsort){
      this.paidsort.disableClear = true;
      if (this.paidsearchResults !==undefined){
        this.paidsearchResults.sort = this.paidsort;
      }

    }
  }

  @ViewChild('pendingTableSort', {read: MatSort}) set pendingcontent(content: ElementRef) {

    this.pendingsort = content;

    if (this.pendingsort){
      this.pendingsort.disableClear = true;
      if (this.searchResults !==undefined){
        this.searchResults.sort = this.pendingsort;
      }

    }
  }
  // customSorting(sort:Sort){
  //   this.paidsearchResults.sort=this.paidsort;
  // }
  @ViewChild('MatPaginator1') set paging(paging: ElementRef) {
    this.paidPaginator = paging;
  }

  @ViewChild('MatPaginator2') set paging1(paging: ElementRef) {
    this.paginator = paging;
  }
  @ViewChild('MatPaginator3') set paging2(paging: ElementRef) {
    this.rejectedPaginator = paging;
  }

  // @ViewChild(MatPaginator) set pendingPaging(paging: ElementRef) {
  //   this.paidPaginator = paging;
  // }


  pendingDraftDateStmt = "The following applications are scheduled to be transmitted to Payroll on ";
  pendingDraftDateStmtSecondHalf = '.  The funds should be available the following ';
  noPendingDraftDateStmt = "You have no pending applications in the system.";
  noPaidHistoryStmt = "You have no processed history in the system.";
  noRejectedStmnt = "You have no rejected application history in the system.";

  hasPendingTrns = false;
  hasProcessedTrns = false;
  hasRejectHistory = false;
  systemError = undefined;
  systemUnavailable = undefined;
  IdNotFound = undefined;
  goodReturn = undefined;
  loadingPage = false;
  paymentHistoryPage = false;
  dataSource: any[] = [];

  rejectHistory: any = undefined;
  searchResults: any = undefined;
  paidsearchResults: any = undefined;
  rejectSearchResults = undefined;
  showRejectTable = false;

  toggle = true;

  rejectEnabled = false;

  displayPendingColumns = ['benefit', 'weekEndDate'];
  displayPaidColumns = ['benefit', 'weekEndDate', 'draftDate', 'fundsAvailableDate'];
  searchModel = {
    gmin: this.globals.gminval,
  };

  paidPageSizeOptions = [5, 10];
  pendingPageSizeOptions;
  rejectedPageSizeOptions;
  pendingPageSize = 5;
  paidPageSize = 5 ;
  rejectedPageSize = 5 ;
  paidPaginator;
  rejectedPaginator;
  paginator;

  paginationChanged = false;
  scrollPosition = 0;

  paidsort;
  pendingsort;
  hasPaiddata: any = true;
  //returning column headers for pending table
  getPendingDisplayedColumns(){
    return this.displayPendingColumns;
  }

  //return column headers for processed table
  getPaidDisplayedColumns(){
    return this.displayPaidColumns;
  }

  ngOnInit() {

  }

  initializePage(){
    this.loadingPage = true;
    this.search();
  }
  /**
   * Populate matTable
   * @param response - response from service
   */
  setGridData(response){
    // if (response.length >= 1) {

    if (response.requestType === "H0"){
      if(response.pendingHistory.length > 0){
        this.pendingDraftDateStmt = this.pendingDraftDateStmt +
          this.globals.i18n.messages.dayOfWeek[response.pendingDraftDateDayOfWeek] + " " + response.pendingDraftDate;
        this.pendingDraftDateStmt += this.pendingDraftDateStmtSecondHalf +
          this.globals.i18n.messages.dayOfWeek[response.pendingDraftDateDayOfWeek] + ' ' + response.nextFridayDate + '.';
        this.hasPendingTrns = true;

        // for (let key in response)
        // {
        //   switch(response[key].payType){
        //     case "810": response[key].payType = "SUB adjustment";
        //       break;
        //     case "811": response[key].payType = "SUB beneficiary payment";
        //       break;
        //     case "812": response[key].payType = "SUBenefit payment";
        //       break;
        //     case "813": response[key].payType = "SUB refund";
        //       break;
        //     case "814": response[key].payType = "SUB replacement check";
        //       break;
        //     case "815": response[key].payType = "SUB separation payment";
        //       break;
        //     case "819": response[key].payType = "TSP adjustment";
        //       break;
        //     case "820": response[key].payType = "TSP beneficiary payment";
        //       break;
        //     case "821": response[key].payType = "TSP weekly payment";
        //       break;
        //     case "822": response[key].payType = "TSP replacement check";
        //       break;
        //     case "823": response[key].payType = "TSP lump sum payment";
        //       break;
        //
        //   }
        // }
      }

      if(response.paidHistory.length > 0){
        this.hasProcessedTrns = true;

        for (let i = 0; i < response.paidHistory.length; ++i) {
          const localDate = new Date(response.paidHistory[i].draftDate);
          localDate.setDate(localDate.getDate() + 7);
          const year = localDate.getFullYear();
          const month = localDate.getMonth() + 1;
          const day = localDate.getDate();
          response.paidHistory[i].fundsAvailableDate = (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year;
        }
      }

      this.paidsearchResults = new MatTableDataSource(response.paidHistory);
      this.paidsearchResults.sort = this.paidsort;
      this.paidsearchResults.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'weekEndDate': return new Date(item.weekEndDate);
          case 'draftDate': return new Date(item.draftDate);
          default: return item[property];
        }
      };
      this.searchResults = new MatTableDataSource(response.pendingHistory);
      this.searchResults.sort = this.pendingsort;
      this.searchResults.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'weekEndDate': return new Date(item.weekEndDate);
           default: return item[property];
        }
      };
      // if (this.paginator) {
      this.searchResults.paginator = this.paginator;
      // }

      // if (this.paidPaginator) {
      this.paidsearchResults.paginator = this.paidPaginator;
      // }

      this.setPendingPaginationOptions();
      this.setPaidPaginationOptions();
    }else if (response.requestType === "H1"){
      if(response.rejectHistory.length > 0){
        this.rejectHistory = response.rejectHistory;
        this.hasRejectHistory = true;
      }
      this.rejectSearchResults = new MatTableDataSource(response.rejectHistory);

      this.rejectSearchResults.paginator = this.rejectedPaginator;

      this.setRejectedPaginationOptions();
    }

    //this.searchResultData = response;
    //this.setPaginationOptions();
    //this.customDateSorting();
    // }
  }


  search(){
    // this.searchModel = {
    //   gmin: this.globals.gminval,
    // };
    this.service.getHistory(this.searchModel).subscribe(response => {
      console.log(response);

      this.loadingPage = false
      this.paymentHistoryPage = true;
      // response.returnCode
      // let test = "912";
      if (response.returnCode === "000"){
        // if (response.rejectHistory.length > 0){
        //   this.rejectHistory = response.rejectHistory;
        // }
        this.setGridData(response);
        this.goodReturn = true;
      }if(response.returnCode === "999"){
        //SYSTEM_ERROR
        this.systemError = true;
      }if(response.returnCode === "912"){
        //ID_NOT_FOUND
        this.searchModel.gmin = '';
        this.IdNotFound = true;
      }if(response.returnCode === "888"){
        this.systemUnavailable = true;
      }

    }, error => {
      console.log(error);
      this.systemError = true;
      this.loadingPage = false;
    });
  }

  getRejectHistory(){
    // if(!this.rejectEnabled){
    this.service.rejectHistoryH1(this.searchModel).subscribe(response => {
      // response.returnCode
      // let test = "912";
      if (response.returnCode === "000"){
        this.setGridData(response);
        if (this.rejectHistory !== undefined){
          this.toggle = false;
        }
        this.showRejectTable = true;
        this.scrollToTop.scrollToTop();
        this.goodReturn = true;
      }if(response.returnCode === "999"){
        //SYSTEM_ERROR
        this.systemError = true;
      }if(response.returnCode === "912"){
        //ID_NOT_FOUND
        this.searchModel.gmin = '';
        this.IdNotFound = true;
      }if(response.returnCode === "888"){
        this.systemUnavailable = true;
      }

    }, error => {
      console.log(error);
      this.systemError = true;
    });
    // this.rejectEnabled = true;
    // }

  }

  showRejectHistory(){
    if (this.showRejectTable === true){
      this.showRejectTable = false;
      this.scrollToTop.scrollToTop();
      this.toggle = true;
    }else{
      //hides top "close application error" button if table is empty
      this.getRejectHistory();
    }
  }

  //convert benefit type code to message
  getBenefit(payType){
    payType = parseInt(payType);
    return this.globals.i18n.messages.benefitType[payType];
  }

  //convert reason code to message
  getStatement(reasonCode){
    //
    if (reasonCode !== "" || reasonCode !== null || reasonCode!== undefined){
      reasonCode = parseInt(reasonCode);
      return this.globals.i18n.message.error[reasonCode];
    }

  }

  /**
   * Handles the scrolling for when the paging size is changed in the mat table.
   * Used to counter a defect where it scrolls the user to where the virtual data would be
   * on the page.
   */
  scroll = (): void => {
    if (this.paginationChanged === true) {
      window.scrollTo(0, this.scrollPosition);
      this.paginationChanged = false;
    }
  }


  paginationChange(event, id) {
    const newPageSize = event['pageSize'];
    if (id === 'paid'){
      if (newPageSize !== this.paidPageSize) {
        this.scrollPosition = window.scrollY;
        this.paidPageSize = newPageSize;
        this.paginationChanged = true;
      }
    }
    else if (id === 'pending'){
      if (newPageSize !== this.pendingPageSize) {
        this.scrollPosition = window.scrollY;
        this.pendingPageSize = newPageSize;
        this.paginationChanged = true;
      }
    }
    else if (id === 'rejected'){
      if (newPageSize !== this.rejectedPageSize) {
        this.scrollPosition = window.scrollY;
        this.rejectedPageSize = newPageSize;
        this.paginationChanged = true;
      }
    }
  }

  // paginationChange(event) {
  //   const newPageSize = event['pageSize'];
  //   if (newPageSize !== this.pageSize) {
  //     this.scrollPosition = window.scrollY;
  //     this.pageSize = newPageSize;
  //     this.paginationChanged = true;
  //   }
  // }


  /**
   * Sets the pagination options for the mat table depending on the size of the data.
   */
  setPendingPaginationOptions() {
    this.pendingPageSizeOptions = undefined;
    const dataSize = this.searchResults.data.length;
    if (dataSize < 5) {
      this.pendingPageSizeOptions = [dataSize];
    } else{
      this.pendingPageSizeOptions = [5, 10];
    }
  }
  setPaidPaginationOptions() {
    this.paidPageSizeOptions = undefined;
    const dataSize = this.paidsearchResults.data.length;
    if (dataSize < 5) {
      this.paidPageSizeOptions = [dataSize];
    } else{
      this.paidPageSizeOptions = [5, 10];
    }
  }
  setRejectedPaginationOptions() {
    this.rejectedPageSizeOptions = undefined;
    const dataSize = this.rejectSearchResults.data.length;
    if (dataSize < 5) {
      this.rejectedPageSizeOptions = [dataSize];
    } else{
      this.rejectedPageSizeOptions = [5, 10];
    }
  }

  setPaginationOptions() {
    this.pendingPageSizeOptions = undefined;
    const dataSize = this.searchResults.data.length;
    if (dataSize < 5) {
      this.pendingPageSizeOptions = [dataSize];
    } else if (dataSize <= 10) {
      this.pendingPageSizeOptions = [5, dataSize];
    } else if (dataSize > 10) {
      this.pendingPageSizeOptions = [10, 15, 20];
    }
    this.pendingPageSize = this.pendingPageSizeOptions[this.pendingPageSizeOptions.length - 1];
    if (this.pendingPageSize > 10){
      this.pendingPageSize = 10;
    }
  }

  showcaptchapage(){
    // if (this.gmincounter < 2){
    //   this.globals.gminattempt = false;
    //   this.globals.captchaverify = false
    //   this.getcaptcha();
    // } else{
    this.globals.gminattempt = false;
    this.globals.captchaverify = false;
    this.globals.systemerror = false;
    this.globals.gminverify = false;
    //window.sessionStorage.setItem('gmlogoclick', 'false');
    //this.globals.changegmlogoclick(false);
    this.router.navigate(['mylayoffbenef/cisaCaptcha']);
    // }
  }

  // getcaptcha(){
  //   this.globals.captchaverify = false;
  //   if(this.globals.captchaverify === false){
  //     this.service.getCaptchaImage().subscribe(response => {
  //       this.captchImage = response;
  //       console.log(this.captchImage);
  //       this.loadingPage = false;
  //     });
  //   }
  // }


  getprivacyinfo(){
    this.modal.createModal('Privacy',  {
      message: [this.globals.i18n.messages.privacy.text1, this.globals.i18n.messages.privacy.text2, this.globals.i18n.messages.privacy.text3],
      callback: data =>{
        if (data.informative){
          return;
        }else{

        }
      }

    });
  }
}
