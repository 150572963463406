import { Component, OnInit } from '@angular/core';
import {Globals} from  '../../../sharedComponents/global-variables/globals';
import {Router} from "@angular/router";
import {CaptchaService} from  '../../../services/my-layoff-benefits/captcha.service';
import {DomSanitizer} from  '@angular/platform-browser';
import {FormValidator} from "../../../sharedComponents/formValidation/formValidator";
import {ModalService} from "../../../services/modal/modal.service";

@Component({
  selector: 'app-my-layoff-benefits-home',
  templateUrl: './my-layoff-benefits-home.component.html',
  styleUrls: ['./my-layoff-benefits-home.component.css']
})
export class MyLayoffBenefitsHomeComponent implements OnInit {
  loadingPage = false;
  imageval: any = undefined;
  captchImage: any = undefined;
  captchAnswer: any = undefined;
  counter=0;
  gmincounter=0;
  choosen:boolean = false;
  validatorSvc = 'LoginValidator';
  searchValidator = undefined;
  searchModel = {  gmin: '',};
  searchResults: any = undefined;
  GOOD_RETURN = "000";
  SYSTEM_ERROR = "999";
  DATA_ERROR = "998";
  OUTSIDE_OF_BUSINESS_HOURS = "888";
  ID_NOT_FOUND = "912";
  message:[];
  error:[];
  static callCounter: number = 0;
  constructor(public globals: Globals, public router: Router, public service: CaptchaService,
              private sanitizer:DomSanitizer, private validator: FormValidator, private modal: ModalService)
  {
    //this.loadingPage = true;
    if ( window.sessionStorage.getItem('sesexpire') === 'true'){
      this.globals.captchaverify = false;
      this.globals.gminattempt = false;
      this.globals.systemunvailable = false;
      this.globals.gminverify = false;
      this.globals.systemerror = false;
      this.globals.gminval = '';
      window.sessionStorage.setItem('sesexpire', 'false');
      MyLayoffBenefitsHomeComponent.callCounter = 0;
    }
    MyLayoffBenefitsHomeComponent.callCounter++;
    if ( MyLayoffBenefitsHomeComponent.callCounter > 2 && this.globals.logoutval ===false &&
      (this.globals.captchaverify ===false || this.globals.gminverify ===false || this.globals.gminval ==='' )){
      MyLayoffBenefitsHomeComponent.callCounter = 0;
      window.sessionStorage.setItem('gmlogoclick', 'false');
      this.globals.changegmlogoclick(false);
      this.globals.captchaverify = false;
      this.globals.gminattempt = false;
      this.globals.systemunvailable = false;
      this.globals.gminverify = false;
      this.globals.systemerror = false;
      this.router.navigate(['']);

    }else if(MyLayoffBenefitsHomeComponent.callCounter >= 2 && this.globals.logoutval ===false &&
      (this.globals.captchaverify ===true && this.globals.gminverify ===true && this.globals.gminval !=='')){
      window.sessionStorage.setItem('gmlogoclick', 'true');
      this.globals.changegmlogoclick(true);
      this.globals.captchaverify = true;
      this.globals.gminattempt = false;
      this.globals.systemunvailable = false;
      this.globals.gminverify = true;
      this.globals.systemerror = false;
    } else {
      this.getcaptcha();
      if (this.globals.captchaverify === true &&  this.globals.gminverify=== false){
        this.searchValidator = this.validator.getForm(this.validatorSvc, 'search');
      }
    }

  }

  getcaptcha(){
    this.globals.captchaverify = false;
    this.globals.logoutval = false;
    if(this.globals.captchaverify === false){
      this.service.getCaptchaImage().subscribe(response => {
        this.captchImage = response;
        // console.log(this.captchImage);
        this.loadingPage = false;
      });
    }
  }

  ngOnInit(): void {
  }
  imageget(imageval){
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64,'+imageval);
  }
  submitanswer(captchAnswer){
    //*** line 98 is for performance testing purpose and needs to be commented after the testing is done
    //   captchAnswer.value = this.captchImage.captchaImage.value;
    //***
    this.counter++;
    if (this.counter <= 2){
      if (this.captchImage.captchaImage.value === captchAnswer.value){
        //this.router.navigate(['gmforms']);
        this.choosen = true;
        this.searchValidator = this.validator.getForm(this.validatorSvc, 'search');
        this.globals.captchaverify = true;

      }else if(this.counter <2){
        this.service.getCaptchaImage().subscribe(response => {
          this.captchImage = response;
         // console.log(this.captchImage);
          this.loadingPage = false;
        });
      }else{
        window.sessionStorage.setItem('gmlogoclick', 'false');
        this.globals.changegmlogoclick(false);
        this.router.navigate(['']);
      }
    }else{
      window.sessionStorage.setItem('gmlogoclick', 'false');
      this.globals.changegmlogoclick(false);
      this.router.navigate(['']);
    }

  }

  login(){
    // console.log("Search GMIN Works");
    var gminvalue : number = +this.searchModel.gmin;
    if (this.searchModel.gmin === '' || this.searchModel.gmin === null){
      alert('Please enter your GMIN.');
      return false;
    }
    if (this.searchModel.gmin.length < 9){
      alert('Your GMIN must be 9 numeric long.');
      return false;
    }
    if (isNaN(gminvalue)){
      alert('Your GMIN must be numeric.');
      return false;
    }
    // if(!this.validator.validateAll(this.searchValidator.allFields)){
    //   alert('Your GMIN must be numeric.');
    //   return false;
    // }

    this.gmincounter++;
    if (this.gmincounter <=2){
      this.service.searchGMIN(this.searchModel).subscribe(response =>{
        const success = this.globals.formChecking.checkResponse(response,this.message,this.error);
        if(success){
          // console.log(response);
          this.searchResults = response;
           // console.log(this.searchResults);
          if(this.searchResults === "000"){
            this.globals.gminverify = true;
            this.globals.gminval = this.searchModel.gmin;
            //  console.log("Login Successful");
          }else if(this.searchResults === "888"){
            this.globals.gminverify = false;
            this.globals.systemunvailable = true;
          }else if(this.searchResults  === "912") {
            this.searchModel.gmin = '';
            this.globals.gminverify = false;
            this.globals.gminattempt = true;
          }else{
            this.globals.systemerror = true;
            this.searchModel.gmin = '';
            // this.globals.gmlogoclick = false;
            this.globals.captchaverify = false;
            this.globals.gminattempt = false;
            this.globals.systemunvailable = false;
            this.globals.gminverify = false;
          }

        }else{
          this.globals.systemerror = true;
          this.searchModel.gmin = '';
          // this.globals.gmlogoclick = false;
          this.globals.captchaverify = false;
          this.globals.gminattempt = false;
          this.globals.systemunvailable = false;
          this.globals.gminverify = false;
        }
      },
        error => {
          this.globals.systemerror = true;
          this.searchModel.gmin = '';
          // this.globals.gmlogoclick = false;
          this.globals.captchaverify = false;
          this.globals.gminattempt = false;
          this.globals.systemunvailable = false;
          this.globals.gminverify = false;
        });
    }

  }
  showcaptchapage(){
    if (this.gmincounter < 2){
      this.globals.gminattempt = false;
      this.globals.captchaverify = false
      this.getcaptcha();
    } else{
      this.globals.gminattempt = false;
      this.globals.captchaverify = false;
      this.globals.systemerror = false;
      this.globals.gminverify = false;
      window.sessionStorage.setItem('gmlogoclick', 'false');
      this.globals.changegmlogoclick(false);
      this.router.navigate(['']);
    }
  }
  logout(){
    window.sessionStorage.setItem('gmlogoclick', 'false');
    this.globals.changegmlogoclick(false);
    MyLayoffBenefitsHomeComponent.callCounter = 0;
    this.globals.logoutval = true;
    this.globals.captchaverify = false;
    this.globals.gminattempt = false;
    this.globals.systemunvailable = false;
    this.globals.gminverify = false;
    this.globals.systemerror = false;
    this.globals.gminval = '';
    this.router.navigate(['']);
  }

  getprivacyinfo(){
    this.modal.createModal('Privacy',  {
      message: [this.globals.i18n.messages.privacy.text1, this.globals.i18n.messages.privacy.text2, this.globals.i18n.messages.privacy.text3],
      callback: data =>{
        if (data.informative){
          return;
        }else{

        }
      }

    });
  }
  getchrome(){
    window.open('https://www.google.com/chrome/index.html', "_blank");
  }
}
