<!-- The first row at the top of the screen, contains the logo and user controls -->
<div *ngIf="globals.i18n !== undefined">

  <mat-toolbar color="primary" fxFill fxFlexFill class="printSideNav">
    <mat-toolbar-row class = "wrapping">

      <button mat-icon-button (click)="sidenav.toggle()"  *ngIf="this.gmlogoclick  !== false">
        <mat-icon>menu</mat-icon>
      </button>
      <span class="rightAlignMenu"></span>
      <div class="navbar-title">
        <span class="titleText" *ngIf="isLargeScreen(); else smallTitle">{{globals.i18n.application.name}}</span>
        <ng-template #smallTitle>
          <span class="smallTitleText">{{globals.i18n.application.name}}</span>
        </ng-template>
      </div>
      <img src="../assets/images/GM-logo.png" width="50px" style="padding-left: 10px; padding-top: 10px">
      <span class="rightAlignMenu"></span>

<!--      <div class="c-user-menu userControlsContainer" aria-label="User Menu">-->
<!--        <button class=" c-circle-icon-container" aria-controls="user-menu" title="user-menu">-->
<!--          <i class="c-circle-icon fa fa-user colorWhite"></i>-->
<!--        </button>-->

<!--        &lt;!&ndash; Socidedad Selection &ndash;&gt;-->
<!--        <div class="languageSelect">-->
<!--          <button mat-menu-item [matMenuTriggerFor]="sociededId">-->
<!--            <span class="selectedValue">{{activeSociedad}}</span>-->
<!--            <i class="fa fa-caret-down" aria-hidden="true"></i></button>-->
<!--          <mat-menu #sociededId="matMenu">-->
<!--            <button *ngFor="let sociedad of sociedadList" mat-menu-item (click)="changeSociedad(sociedad)">-->
<!--              {{sociedad}}-->
<!--            </button>-->
<!--          </mat-menu>-->
<!--        </div>-->
<!--        &lt;!&ndash; User Name Display &ndash;&gt;-->
<!--        <div>-->
<!--          <div class="userName">{{globals.userInfo.firstName + ' ' + globals.userInfo.lastName}}</div>-->
<!--          &lt;!&ndash;<mat-menu #menu="matMenu">&ndash;&gt;-->
<!--            &lt;!&ndash;<button mat-menu-item (click)="changeLang('es')"><mat-icon svgIcon="flag_es"></mat-icon> Español</button>&ndash;&gt;-->
<!--            &lt;!&ndash;<button mat-menu-item (click)="changeLang('us')"><mat-icon svgIcon="flag_us"></mat-icon> English</button>&ndash;&gt;-->
<!--          &lt;!&ndash;</mat-menu>&ndash;&gt;-->
<!--        </div>-->

<!--        &lt;!&ndash; Language Selection &ndash;&gt;-->
<!--        <div class="languageSelect">-->
<!--          <button mat-menu-item [matMenuTriggerFor]="langMenu">-->
<!--            <mat-icon [svgIcon]="currentFlag()"></mat-icon>-->
<!--            <i class="fa fa-caret-down" aria-hidden="true"></i></button>-->
<!--          <mat-menu #langMenu="matMenu">-->
<!--            <button mat-menu-item (click)="changeLang('us')">-->
<!--              <mat-icon svgIcon="flag_us"></mat-icon>-->
<!--              {{globals.i18n.language.english}}-->
<!--            </button>-->
<!--            <button mat-menu-item (click)="changeLang('es')">-->
<!--              <mat-icon svgIcon="flag_es"></mat-icon>-->
<!--              {{globals.i18n.language.spanish}}-->
<!--            </button>-->
<!--          </mat-menu>-->
<!--        </div>-->


<!--      </div>-->

    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container autosize id="appContentContainer">

    <mat-sidenav class="printSideNav" [ngClass]="{'mobileSize': !isLargeScreen()}" #sidenav [mode]="isLargeScreen() ? 'side' : 'over'" [opened]="globals.gmlogoclick" style="height: 93vh;">
      <div *ngIf ="globals.gmlogoclick !== false">
      <mat-nav-list>

        <span *ngIf="!(router.url.includes('noAccess'))">
          <navbar-item *ngFor="let item of navItems" [item]="item"></navbar-item>
        </span>
      </mat-nav-list>
      </div>
      <br>
      <br>
      <br>
      <br>
    </mat-sidenav>


    <mat-sidenav-content id="appContentWrapper" class="appContentWrapper">
      <div style="height: 93vh;">
        <div id="appContent" class="appContentWrapper">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>

  </mat-sidenav-container>

</div>

<div id="printWrapper"></div>
