<app-loading *ngIf="loadingPage"></app-loading>
<div class="searchContainer">
  <app-expandable [expandable]="false" [title]="globals.i18n.title.mylayoffbenefits">
    <div *ngIf="captchImage !== undefined && globals.captchaverify === false">
      <div class="container">
        <div class="row">
          <h4>
            {{globals.i18n.title.message.text19}}
          </h4>
        </div>
        <div class="row">
          <img alt="" [src]="imageget(captchImage.captchaImage.image)">
        </div>
        <br>
        <div class="row " >


          <div class="col-md-2 col-sm-3" *ngFor="let answer of captchImage.captchaAnswerArrayList">
            <input type="image" name="{{answer.id}}" value="{{answer.id}}" [src]="imageget(answer.button)" (click)="submitanswer(answer)" />

          </div>


        </div>
        <div class="row" *ngIf="counter===1 && choosen === false">
          <h4>
            Incorrect choice, try again
          </h4>
        </div>
        <div  class="row centeredFlex">
          <a (click)="getprivacyinfo()">Privacy Information</a>
        </div>
      </div>

      <br>

    </div>
    <div *ngIf="globals.captchaverify === true &&  globals.gminverify=== false &&
                 globals.gminattempt === false && globals.systemunvailable === false
                  && globals.systemerror === false">
      <div class="searchContainer">
        <form class="searchForm">
          <div class="container">
            <div  class="row">
              The system is now using GMIN for logins. You must enter your &nbsp;<b> GM Identification Number (GMIN) </b> &nbsp; and press the &nbsp; <b> Login </b>&nbsp; button below if you would like to:
            </div>
            <div  class="row">
              <ul class="style1">
                <li class="style1">{{globals.i18n.title.message.text26}}</li>
                <li class="style1">{{globals.i18n.title.message.text27}}</li>
                <li class="style1">{{globals.i18n.title.message.text28}}</li>
                <li class="style1">{{globals.i18n.title.message.text29}}</li>
                <li class="style1">{{globals.i18n.title.message.text30}}</li>
                <li class="style1">{{globals.i18n.title.message.text31}}</li>
              </ul>
            </div>

            <div class="row col-md-6" >
              <app-textbox-control
                [labelText]="'GMIN'"
                [editable]='true'
                [(value)] = "searchModel.gmin"
                [maxLength]="9"
                [isButtonDisplayed]="false"
                [type]="'password'"
              ></app-textbox-control>
              <app-button  class="margin" type="primary" value="Login"
                          (click)="login()" (keyup.enter)="login()"
              ></app-button>
            </div>
            <div  class="row">
              <span>When you have completed your request and you are ready to leave this site, please be sure to &nbsp;</span><a (click)="logout()">logout</a>
            </div>
            <div class="rowContainer centeredFlex">
            </div>
            <div  class="row">
              This is a &nbsp;<b>secured</b>&nbsp; area of the Layoff Benefits Web site.
            </div>
            <div  class="row centeredFlex">
              <a (click)="getprivacyinfo()">Privacy Information</a>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="globals.captchaverify === true &&  globals.gminverify=== false &&
                 globals.gminattempt === true && globals.systemunvailable === false && globals.systemerror === false">
      <div class="searchContainer">
        <div class="container">
          <div class="row"></div>
          <h4>
            You have entered a GM Identification Number (GMIN) that is not found to be a GM employee
          </h4>

          <div class="row">
            <span>Please verify your GMIN and <a (click)="showcaptchapage()">login</a> again.</span>
          </div>
        </div>
        <br>
        <div  class="row centeredFlex">
          <a (click)="getprivacyinfo()">Privacy Information</a>
        </div>
      </div>
    </div>
    <div *ngIf="globals.captchaverify === true &&  globals.gminverify=== false &&
                 globals.gminattempt === false && globals.systemunvailable === true && globals.systemerror === false">
      <app-system-unavailable></app-system-unavailable>
<!--      <div class="searchContainer">-->
<!--        <div class="container">-->
<!--          <div class="row">-->
<!--            <h4>These feratures are unavailable at this time. Please refer to our <a routerLink="/hours">hours of availability</a></h4>-->
<!--          </div>-->
<!--          <div class="row">-->
<!--            <h4>If you are accessing this site during our regular <a routerLink="/hours">hours of availability</a>, then the feature is unavailable due to unscheduled maintenance.</h4>-->
<!--          </div>-->
<!--          <div class="row">-->
<!--            <app-button class="margin" type="primary" value="GoBack"-->
<!--                        (click)="logout()" (keyup.enter)="logout()"-->
<!--            ></app-button>-->
<!--            <p>Please use the button to return to the home page.</p>-->
<!--          </div>-->
<!--          <div  class="row centeredFlex">-->
<!--            <a (click)="getprivacyinfo()">Privacy Information</a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

    </div>
<!--    <div *ngIf="globals.captchaverify === true &&  globals.gminverify=== false &&-->
<!--                 globals.gminattempt === false && globals.systemunvailable === true && globals.systemerror === false">-->
<!--      <div class="searchContainer">-->
<!--        <div class="container">-->
<!--          <div class="row">-->
<!--            <h4>These feratures are unavailable at this time. Please refer to our <a routerLink="/hours">hours of availability</a></h4>-->
<!--          </div>-->
<!--          <div class="row">-->
<!--            <h4>If you are accessing this site during our regular <a routerLink="/hours">hours of availability</a>, then the feature is unavailable due to unscheduled maintenance.</h4>-->
<!--          </div>-->
<!--          <div class="row">-->
<!--            <app-button class="margin" type="primary" value="GoBack"-->
<!--                        (click)="logout()" (keyup.enter)="logout()"-->
<!--            ></app-button>-->
<!--            <p>Please use the button to return to the home page.</p>-->
<!--          </div>-->
<!--          <div  class="row centeredFlex">-->
<!--            <a (click)="getprivacyinfo()">Privacy Information</a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--    </div>-->
    <div  *ngIf="globals.captchaverify === true &&  globals.gminverify=== false &&
           globals.gminattempt === false && globals.systemunvailable === false && globals.systemerror === true">
      <app-system-error></app-system-error>
<!--      <div class="searchContainer">-->
<!--        <div class="container">-->
<!--          <div class="row">-->
<!--            <h4>We could not process your request because the system is experiencing problems, please try again later.</h4>-->
<!--            <h4>If you continue to experience this problem, please call the Layoffs and Unemployment Center at 1-800-584-2000</h4>-->
<!--          </div>-->
<!--          <div class="row">-->
<!--            <app-button class="margin" type="primary" value="GoBack"-->
<!--                        (click)="logout()" (keyup.enter)="logout()"-->
<!--            ></app-button>-->
<!--            <p>Please use the button to return to the home page.</p>-->
<!--          </div>-->
<!--          <div  class="row centeredFlex">-->
<!--            <a (click)="getprivacyinfo()">Privacy Information</a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

    </div>
    <div  *ngIf="globals.captchaverify === true &&  globals.gminverify=== true &&
           globals.gminattempt === false && globals.systemunvailable === false &&
           globals.systemerror === false && globals.gminval !==''">
      <div class="searchContainer">
        <div class="container">
          <div class="rowContainer">
            <h4>Welcome to My Layofff Benefits. Within this section you can:</h4>
          </div>
          <div *ngIf ="globals.gminval !==''" class="row">
            <ul>
              <li class="special">
                <span class="link" routerLink="/mylayoffbenef/cisaBenefitInit"  >{{globals.i18n.menu.mylayoffbenef.applyWklyBenfull}}</span>
              </li>
              <li  class="special2">
                {{globals.i18n.title.message.text20}}
              </li>
            </ul>
          </div>
          <div *ngIf ="globals.gminval !==''" class="row">
            <ul>
              <li class="special">
                <span class="link" routerLink="/mylayoffbenef/cisaMondetRequest"  >{{globals.i18n.menu.mylayoffbenef.viewMonetaryDeterfull}}</span>
              </li>
              <li  class="special2">
                {{globals.i18n.title.message.text21}}
              </li>
            </ul>
          </div>
          <div *ngIf ="globals.gminval !==''" class="row">
            <ul>
              <li class="special">
                <span class="link" routerLink="/mylayoffbenef/cisaGetHistory"  >{{globals.i18n.menu.mylayoffbenef.pendinghisfull}}</span>
              </li>
              <li  class="special2">
                {{globals.i18n.title.message.text22}}
              </li>
            </ul>
          </div>
          <div *ngIf ="globals.gminval !==''" class="row">
            <ul>
              <li class="special">
                <span class="link" routerLink="/mylayoffbenef/cisaGetCounters"  >{{globals.i18n.menu.mylayoffbenef.countersfull}}</span>
              </li>
              <li  class="special2">
                {{globals.i18n.title.message.text23}}
              </li>
            </ul>
          </div>
          <div *ngIf ="globals.gminval !==''" class="row">
            <ul>
              <li class="special">
                <span class="link" routerLink="/mylayoffbenef/cisaTspElection"  >{{globals.i18n.menu.mylayoffbenef.tspElectiobfull}}</span>
              </li>
              <li  class="special2">
                {{globals.i18n.title.message.text24}}
              </li>
            </ul>
          </div>
          <div *ngIf ="globals.gminval !==''" class="row">
            <ul>
              <li class="special">
                <span class="link" routerLink="/mylayoffbenef/fileUpload"  >{{globals.i18n.menu.mylayoffbenef.fileupload}}</span><span class="tab">   [</span><span class="shortTab">NEW</span><span class="shortTab">]</span>
              </li>
              <li  class="special2">
                {{globals.i18n.title.message.text33}}
              </li>
            </ul>
          </div>
          <div *ngIf ="globals.gminval !==''" class="row">
            <ul>
              <li class="special">
                <span class="link" routerLink="/mylayoffbenef/contactUs"  >{{globals.i18n.menu.mylayoffbenef.contactus}}</span>
              </li>
              <li  class="special2">
                {{globals.i18n.title.message.text25}}
              </li>
            </ul>
          </div>
          <div  class="row centeredFlex">
            <a (click)="getprivacyinfo()">Privacy Information</a>
          </div>
        </div>
      </div>
    </div>
  </app-expandable>
  <br>
  <br>
  <br>
  <br>
</div>


