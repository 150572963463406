<div class="row">
  <div [ngClass]="{'col-md-4': !bigInput && !modal, 'col-md-2': bigInput && !modal,
                    'col-modal-4': !bigInput && modal, 'col-modal-2': bigInput && modal}" *ngIf="label">
    <label><span *ngIf="mandatory" style="color: red">*</span>{{label + endingCharacter()}}</label>
  </div>
  <div [ngClass]="{ 'col-md-12': !label,
                    'col-md-8': label && (!bigInput && !modal),
                    'col-md-10': label && (bigInput && !modal),
                    'col-modal-8': label && (!bigInput && modal),
                    'col-modal-10': label && (bigInput && modal)}">
    <mat-form-field class="selectInput"
                    *ngIf="((fieldLevelSecurity === 'U' || fieldLevelSecurity === 'A') && editable) || (fieldLevelSecurity === undefined && editable)"
                    [ngClass]="{'inputErrorBackground': validatorMessages.length > 0,
                     'redArrow': !hideRequiredMarker,
                     'blackArrow': hideRequiredMarker,
                     'tableInput': tableInput}"
                    [hideRequiredMarker]="hideRequiredMarker">
      <mat-select [(value)]="selected" (selectionChange)="onSelect($event.value)" [compareWith]="compareFn"
                  [required]="mandatory" (blur)=" tooltip.hide()" [matTooltip]="toolTips" (focus)="tooltip.show()"
                  #tooltip="matTooltip" (keyup)="findOption($event)" (opened)="scrollToOption()" [id]="id">

        <cdk-virtual-scroll-viewport [itemSize]="15" [style.height.px]=getHeight() *ngIf="virtualScroll" >
<!--          <mat-option [value]="null" *ngIf="nullValue"></mat-option>-->
          <mat-option *cdkVirtualFor="let option of options" [value]="option[0]">
            {{showOption(option)}}
          </mat-option>
          <mat-option *ngIf="selected" [value]="selected">{{displayValue}}</mat-option>
        </cdk-virtual-scroll-viewport>

        <ng-container *ngIf="!virtualScroll">
<!--          <mat-option [value]="null" *ngIf="nullValue"></mat-option>-->
          <mat-option *ngFor="let option of options" [value]="option[0]">
            {{showOption(option)}}
          </mat-option>
        </ng-container>

      </mat-select>
    </mat-form-field>
    <mat-form-field
      *ngIf="(!editable && (fieldLevelSecurity === 'U' || fieldLevelSecurity === 'A')) ||
      (fieldLevelSecurity === undefined && !editable) || fieldLevelSecurity === 'I'" [ngClass]="{'tableInput': tableInput}"
      class="textInput">
      <input matInput [(ngModel)]="displayValue" [required]="mandatory" [disabled]="true"
             (blur)="offFocus(); tooltip.hide()" [matTooltip]="toolTips" (focus)="tooltip.show()" #tooltip="matTooltip">
    </mat-form-field>
    <mat-form-field *ngIf="fieldLevelSecurity === 'N'" class="textInput" [ngClass]="{'tableInput': tableInput}">
      <input matInput [disabled]="true">
    </mat-form-field>
    <div *ngFor="let message of validatorMessages" class="inputValidationMessage" [ngClass]="{'inputValidationMessageTable' : tableInput}">
      {{message}}
    </div>
  </div>
</div>
