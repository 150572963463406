<div class="row">
  <div [ngClass]="{'col-md-4': !bigInput && !modal, 'col-md-2': bigInput && !modal,
                    'col-modal-4': !bigInput && modal, 'col-modal-2': bigInput && modal}" *ngIf="labelText">
    <label (click)="onClickLinkLabel()" [ngClass]="{'linkItemLabel': clickableLabel}"><span *ngIf="mandatory" style="color: red">*</span>{{labelText + endingCharacter()}}</label>
  </div>
  <div [ngClass]="{ 'col-md-12': !labelText,
                    'col-md-8': labelText && (!bigInput && !modal),
                    'col-md-10': labelText && (bigInput && !modal),
                    'col-modal-8': labelText && (!bigInput && modal),
                    'col-modal-10': labelText && (bigInput && modal)}">
    <mat-form-field
      *ngIf="((fieldLevelSecurity === 'U' || fieldLevelSecurity === 'A') && editable) || (fieldLevelSecurity === undefined && editable)"
      [ngClass]="{'fullWidth': !isButtonDisplayed,
                                'alignRight': decimal || right,
                                'inputErrorBackground': validatorMessages.length > 0,
                                'linkItem': link,
                                'postFixingInput': postfixLabel !== undefined,
                                'textBold': bold === true,
                                'tableInput': tableInput}">
      <input matInput
             [ngModel]="decimal && (value !== undefined && value !== null) && (!editable || pipe) ? (value | toNumber): value"
             (ngModelChange)="value=$event" [ngStyle]="{'width': + inputWidth + 'px'}"
             [ngClass]="{'linkItem': link}"
             [disabled]="!editable" (blur)="offFocus(); tooltip.hide(); pipe=true;" [matTooltip]="toolTips"
             (focus)="tooltip.show(); pipe=false;" #tooltip="matTooltip"
             [required]="mandatory" [maxLength]="maxLength" [minLength]="minLength" [type]="type"
             (click)="onClickLink()">
      <div *ngIf="postfixLabel !== undefined" class="postfixLabel">
        {{postfixLabel}}
      </div>
    </mat-form-field>
    <mat-form-field (click)="onClickLink()"
                    *ngIf="(!editable && (fieldLevelSecurity === 'U' || fieldLevelSecurity === 'A')) || (fieldLevelSecurity === undefined && !editable) || fieldLevelSecurity === 'I'"
                    [ngClass]="{'fullWidth': !isButtonDisplayed,
                     'alignRight': decimal || right,
                     'postFixingInput': postfixLabel !== undefined,
                     'textBold': bold === true,
                     'tableInput': tableInput}">
      <input matInput
             [ngModel]="decimal && (value !== undefined && value !== null) ? (value | toNumber): value"
             [ngStyle]="{'width': + inputWidth + 'px'}"
             [ngClass]="{'linkItem': link, 'postFixingInput': postfixLabel !== undefined, 'textBold': bold === true}"
             [disabled]="true" (blur)="offFocus(); tooltip.hide()" [matTooltip]="toolTips" (focus)="tooltip.show()"
             #tooltip="matTooltip"
             [required]="mandatory" [maxLength]="maxLength" [minLength]="minLength" [type]="type">
      <div *ngIf="postfixLabel !== undefined" class="postfixLabel">
        {{postfixLabel}}
      </div>
    </mat-form-field>
    <mat-form-field *ngIf="fieldLevelSecurity === 'N'"
                    [ngClass]="{'fullWidth': true,
                     'alignRight': decimal || right,
                     'tableInput': tableInput}">
      <input matInput
             [ngStyle]="{'width': + inputWidth + 'px'}"
             [disabled]="true" [type]="type">
    </mat-form-field>
    <i class="fa fa-search" (click)="onClick($event)" *ngIf="fieldLevelSecurity !== 'I' && fieldLevelSecurity !== 'N' && isButtonDisplayed && !excel"></i>
    <i class="fa fa-download" (click)="onClick($event)" *ngIf="fieldLevelSecurity !== 'I' && fieldLevelSecurity !== 'N' && isButtonDisplayed && editable && excel" title="Download to Excel"></i>
    <div *ngFor="let message of validatorMessages" class="inputValidationMessage" [ngClass]="{'inputValidationMessageTable' : tableInput}">
      {{message}}
    </div>
  </div>
</div>

