import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class AppButtonComponent implements OnInit {

  // primary/secondary/tertiary/generic
  @Input() type: String = '';
  @Input() value: String = '';
  @Input() disabled: Boolean = false;
  constructor() { }

  getClass() {
    switch (this.type) {
      case 'primary':
        return 'primary';
      case 'secondary':
        return 'secondary';
      case 'tertiary':
        return 'tertiary';
      case 'generic':
        return 'generic';
      default:
        return 'primary';
    }
  }

  ngOnInit() {
  }
}
