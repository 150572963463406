let masks = {
  accountNumber: {
    mask: '^([A-Za-z0-9]{2}\\-[A-Za-z0-9]{4}\\-[A-Za-z0-9]{5}\\-[A-Za-z0-9]{5}\\-[A-Za-z0-9]{3}\\-[A-Za-z0-9]{3}\\-[A-Za-z0-9]{4}|[A-Za-z0-9]{26})$',
    maskMessage: 'errors.mask.accountNumberLabel',
    maskToolTip: 'tooltip.accountNumber',
  },
  alphanumeric: {
    mask: '^[a-zA-Z0-9]*$',
    maskMessage: 'errors.mask.alphanumericSingle',
    maskToolTip: 'tooltip.alphanumeric'
  },
  alphanumericWithSpace: {
    mask: '^[a-zA-Z0-9\\s]*$',
    maskMessage: 'errors.mask.alphanumericWithSpaceSingle',
    maskToolTip: 'tooltip.alphanumericWithSpace'
  },
  alphanumericWithSpaceAndWildCard: {
    mask: '^[A-Za-z0-9\\s]{2,}[\\*]?$|^[a-zA-Z0-9\\s]*$',
    maskMessage: 'errors.mask.alphanumericWithSpaceWildCard',
    maskToolTip: 'tooltip.alphanumericWithSpaceWildCard'
  },
  alphanumericWithSpaceAndWildCardAndHyphen: {
    mask: '^[A-Za-z0-9\\s\-]{2,}[\\*]?$|^[a-zA-Z0-9\\s\-]*$',
    maskMessage: 'errors.mask.alphanumericWithSpaceWildCardHyphen',
    maskToolTip: 'tooltip.alphanumericWithSpaceWildCardHyphen'
  },
  alphanumericWithSpaceAndHyphen: {
    mask: '^[a-zA-Z0-9\\s\-]*$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  alphanumericWithSpaceAndHyphenPeriod: {
    mask: '^[a-zA-Z0-9\\s\\-.,;:!@#$%^&*()_+=\\/[\\]{}|<>?"~`]*$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  alphanumericWithSpaceAndComma: {
    mask: '^[a-zA-Z0-9\\s\,]*$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  authCode: {
    mask: '^[A-Za-z0-9]{4}[0-9]{1,7}$',
    maskMessage: 'errors.mask.authCodeSingle',
    maskToolTip: 'tooltip.authCode'
  },
  date: {
    mask: "^(0{0,1}[1-9]|[12][0-9]|3[01])[-](0{0,1}[1-9]|1[012]|[jJ][aA][nN]|[fF][eE][bB]|[mM][aA][rR]|[aA][pP][rR]|[mM][aA][yY]|[jJ][uU][nN]|[jJ][uU][lL]|[aA][uU][gG]|[sS][eE][pP]|[oO][cC][tT]|[nN][oO][vV]|[dD][eE][cC])[-](19|20)\\d\\d$",
      //'^(0{0,1}[1-9]|[12][0-9]|3[01])[- /.](0{0,1}[1-9]|1[012]|([A-Z]{0,3}[a-z]{0,3}){1})([.]{0,1})[- /.](19|20)\\d\\d$',
    //'^(0{0,1}[1-9]|[12][0-9]|3[01])[- /.](0{0,1}[1-9]|1[012]|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)[- /.](19|20)\\d\\d$'
    maskMessage: 'errors.mask.dateCenturySingle',
    maskToolTip: 'tooltip.dateCentury'
  },
  decimalTwoPlace: {
    mask: '^(\\-)?[0-9]+(\\.[0-9]{1,2})?$',
    maskMessage: 'errors.mask.decimalTwoPlaceSingle',
    maskToolTip: 'tooltip.decimalTwoPlace'
  },
  decimalNineDigits: {
    mask: '^(\\-)?[0-9]{0,9}(\\.[0-9]{1,2})?$',
    maskMessage: 'errors.mask.decimalTwoPlaceSingle',
    maskToolTip: 'tooltip.decimalTwoPlace'
  },
  locationCode: {
    mask: '^([A-Za-z0-9]{5}|[A-Za-z0-9]{5}[\\s][-][\\s].{0,30})$',
    maskMessage: 'errors.mask.authCodeSingle',
    maskToolTip: 'tooltip.alphanumeric'
  },
  numeric: {
    mask: '^[0-9]*$',
    maskMessage: 'errors.mask.numericSingle',
    maskToolTip: 'tooltip.numeric'
  },
  numericWithNegative: {
    mask: '^(\\-)?[0-9]*$',
    maskMessage: 'errors.mask.numericSingle',
    maskToolTip: 'tooltip.numeric'
  },
  imputedIncomeNumeric: {
    mask: '^[0-9,]{0,11}\\.[0-9]{2}$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.imputedIncomeNumeric'
  },
  imputedAmountNumeric: {
    mask: '^[0-9,]{0,11}(\\.[0-9]{1,2})?$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.imputedIncomeNumeric'
  },
  status: {
    mask: '^([A-Za-z0-9]{1}[A-Za-z0-9]{1}|[A-Za-z0-9]{1}[A-Za-z0-9]{1}[\\s][-][\\s].{0,35})$',
    maskMessage: 'errors.mask.alphanumericSingle',
    maskToolTip: 'tooltip.alphanumeric'
  },
  maskCharSearch: {
    mask: '^([^*]{1,}|[^*]{2,}\\*{1})$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.alphanumeric'
  },
  authCodeSearch: {
    mask: '^([A-Za-z0-9]{4}[0-9]{1,7}|[A-Za-z0-9]{4,4}[\\*]{1}|[A-Za-z0-9]{4}[0-9]{1,7}\\*?)$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  taxId: {
    mask: '^(([A-Za-z0-9]{2})|([A-Za-z0-9]{2}[-][A-Za-z0-9]{7})|([a-zA-Z0-9]{9}))$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.taxId'
  },
  ssn: {
    mask: '^([A-Za-z0-9]{9}|[A-Za-z0-9]{4})$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  gmid: {
    mask: '^[A-Za-z0-9]{6,8}$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  authPref: {
    mask: '^[A-Pa-p]$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  maskSearch: {
    mask: '^([A-Za-z0-9]{1,}|[A-Za-z0-9]{2,}\\*{1})$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.search'
  },
  maskSingleCharSearch: {
    mask: '^[A-Za-z0-9]{1,}\\*?$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  currency: {
    mask: '^(\\-)?(\\$)?(\\d{1,3}(\\,\\d{3})*|(\\d+))(\\.\\d{0,2})?$',
    maskMessage: 'errors.mask.currency',
    maskToolTip: 'tooltip.currency'
  },
  maskVinSearch: {
    mask: '^([A-Za-z0-9]{5,7}[\\*]{1}|[A-Za-z0-9]{8}|[A-Za-z0-9]{17})$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.vinSearch'
  },
  maskVinNoWildCardSearch: {
    mask: '^([A-Za-z0-9]{8}|[A-Za-z0-9]{8}|[A-Za-z0-9]{17})$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.vinSearchNoWildcard'
  },
  maskWindowStickerSearch: {
    mask: '^([A-Za-z0-9]{1,8}|[A-Za-z0-9]{2,7}\\*{1})$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.windowStickerSearch'
  },
  maskWindowStickerNoWildCardSearch: {
    mask: '^([A-Za-z0-9]{1,8}|[A-Za-z0-9]{2,7})$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.windowStickerSearch'
  },
  maskVehiclePrimaryAuthCodeSearch: {
    mask: '^([A-Za-z0-9]{4}[0-9]{1,7}|[A-Za-z0-9]{4,10}\\*?)$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.vehiclePrimaryAuthCodeSearch'
  },
  maskOrderNumberSearch: {
    mask: '^[A-Za-z0-9]{2,}\\*|[A-Za-z0-9]{6}$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.OrderNumberSearch'
  },
  maskGmin: {
    mask: '^([0-9]{9})$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.gmin'
  },
  maskGmid: {
    mask: '^[A-Za-z0-9]{6,8}$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.gmid'
  },
  maskLastName: {
    mask: '^([^*]{1,}|[^*]{4,}\\*{1})$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  maskFirstName: {
    mask: '^([^*]{1,}|[^*]{2,}\\*{1})$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  maskNumericSearch: {
    mask: '^[0-9]*$',
    maskMessage: 'tooltip.numericSearch',
    maskToolTip: 'tooltip.numericSearch'
  },
  maskTime: {
    mask: '^((([0-1]{1}[0-9]{1})|([2]{1}[0-3]{1}))[:]{1}[0-5]{1}[0-9]{1})$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.time'
  },
  maskEmail: {
    mask: '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.email',
  },
  maskShipTo: {
    mask: '^([A-Za-z0-9]{5}|[A-Za-z0-9]{5}[\\s][-][\\s].{0,70})$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  maskVin: {
    mask: '^([A-Za-z0-9]{17})$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.vin'
  },
  maskModelYear: {
    mask: '^([1]{1}[9]{1}[0-9]{2}|[2]{1}[0]{1}[0-9]{2})$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.modelYear'
  },
  maskAlphanumericWithWildCard: {
    mask: '^[a-zA-Z0-9*]*$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.alphanumericWithWildCard'
  },
  maskGroupName: {
    mask: '^[A-Za-z0-9]{4,10}$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.groupName'
  },
  maskControlSearch: {
    mask: '^[A-Za-z0-9]{0,}\\*?$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.controlSearch'
  },
  maskControlTwoDigit: {
    mask: '^[A-Za-z0-9]{0,2}$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: ''
  },
  maskCorporationCode: {
    mask: '^[A-Za-z0-9]{0,10}$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  maskGmId: {
    mask: '^[A-Za-z0-9]{6,8}$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  maskSsn: {
    mask: '^([A-Za-z0-9]{9}|[A-Za-z0-9]{4})$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  maskOrderNumber: {
    mask: '^[A-Za-z0-9\\s]{6}|[A-Za-z0-9\\s]{10}$',
    maskMessage: 'errors.mask.orderNumber',
    maskToolTip: 'tooltip.generic'
  },
  alphanumericWithSpaceWildCard: {
    mask: '^[a-zA-Z0-9*\\s]*[*]?$',
    //maskMessage: 'errors.mask.alphanumericWithSpaceWildCard',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.alphanumericWithSpaceWildCard'
  },
  exportFileName: {
    mask: '^[a-zA-Z0-9\\s-]*[.]xls$',
    maskMessage: 'tooltip.excel',
    maskToolTip: 'tooltip.excel'
  },
  processSourceSearch: {
    mask: '^(\\*|[89]\\*?|[8][A-Za-z0-9]|[9][A-Za-z2-90])$',
    maskMessage: 'tooltip.processSourceSearch',
    maskToolTip: 'tooltip.processSourceSearch'
  },
  maskCaseNbr: {
    mask: '^([^*]{1,}|[^*]{4,}\\*{1})$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  maskName: {
    mask: '^([^*]{1,}|[^*]{4,}\\*{1})$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  maskStreetAddress: {
    mask: '^[a-zA-Z0-9\\s\\-.,:@#()\\/[\\]|<>"\']*$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  maskCity: {
    mask: '^[a-zA-Z0-9\\s\\-.\']*$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  maskState: {
    mask: '^[a-zA-Z\\s]*$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  maskZipcode: {
    mask: '^[0-9]*$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  },
  maskPhone:{
    mask: '^[0-9\\-\\s\\-()+]*$',
    maskMessage: 'errors.mask.generic',
    maskToolTip: 'tooltip.generic'
  }
};

export {masks};
