import { Component } from '@angular/core';

@Component({
  selector: 'app-salary-forms',
  templateUrl: './salary-forms.component.html',
  styleUrl: './salary-forms.component.css'
})
export class SalaryFormsComponent {
  constructor(
  ) {
  }
}
