<div class="searchContainer">
  <div class="container">
    <div class="row">
      <h4>These feratures are unavailable at this time. Please refer to our <a routerLink="/hours">hours of availability</a></h4>
    </div>
    <div class="row">
      <h4>If you are accessing this site during our regular <a routerLink="/hours">hours of availability</a>, then the feature is unavailable due to unscheduled maintenance.</h4>
    </div>
    <div class="row">
      <app-button class="margin" type="primary" value="Go Back"
                  (click)="goback()" (keyup.enter)="goback()"
      ></app-button>
      <p>Please use the button to return to the home page.</p>
    </div>
    <div  class="row centeredFlex">
      <a (click)="this.globals.getprivacyinfo()">Privacy Information</a>
    </div>
  </div>
</div>
