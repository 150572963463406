import { Component, Input } from '@angular/core';
import { Globals } from '../../../global-variables/globals';

@Component({
  selector: 'app-modal-error',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.css']
})

export class ErrorModalComponent {
  data: any;
  selfComponent: any;
  index: number;
  removalObservable: any;

  constructor(public globals: Globals) {
  }

  close() {
    this.removalObservable.next({ index: this.index, data: {}, callback: this.data.callback }); // update the base modal component about the removal
    this.selfComponent.destroy();
  }
}
