import { masks } from '../../masks';

let submit = {

  ucOneDollars: {
    minLen: null,
    inputMaxLen: 4,
    maxLen: 4,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  },
  ucOneCents: {
    minLen: null,
    inputMaxLen: 2,
    maxLen: 2,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  },
  otherEarningsDollars: {
    minLen: null,
    inputMaxLen: 4,
    maxLen: 4,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  },
  otherEarningsCents: {
    minLen: null,
    inputMaxLen: 2,
    maxLen: 2,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  },
};


export {submit};
