import { masks } from '../../masks';

let search = {

  gmin: {
    minLen: null,
    inputMaxLen: 9,
    maxLen: 9,
    mask: masks.maskGmin.mask,
    maskMessage: masks.maskGmin.maskMessage,
    maskToolTip: '',
    required: true
  },
};


export {search};
