import { Component, OnInit } from '@angular/core';
import { Globals } from '../../sharedComponents/global-variables/globals';


@Component({
  selector: 'app-hours',
  templateUrl: './hours.component.html',
  styleUrls: ['./hours.component.css']
})
export class HoursComponent implements OnInit {

  constructor(public globals: Globals) {

  }


  ngOnInit(): void {
  }

}
