// Catches any form errors and updates the passed in screen messages
import { Injectable } from '@angular/core';
import { Globals } from '../global-variables/globals';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import * as VioMaintenanceValidator from './pages/vehicle/vioMaintenance';
import * as EmpleadosValidator from './pages/mantenimientos/empleados';
import * as LoginValidator from './pages/gminlogin/login';
import * as MondetValidator from './pages/setupmondet/setupmondet';
import * as ContactUsValidator from './pages/contactUs/contactUs';
import * as BenefitInitValidator from './pages/benefitinit/benefitinit';
import * as FileUploadValidator from './pages/fileupload/fileupload';


@Injectable({
  providedIn: 'root'
})
export class FormValidator {
  private validatorRef = {
    'VioMaintenanceValidator': VioMaintenanceValidator,
    'EmpleadosValidator': EmpleadosValidator,
    'LoginValidator': LoginValidator,
    'MondetValidator': MondetValidator,
    'ContactUsValidator': ContactUsValidator,
    'BenefitInitValidator': BenefitInitValidator,
    'FileUploadValidator': FileUploadValidator
  };

  constructor(private globals: Globals) {}

  // instantiated at the field level and attached to the outbound validator object
  private buildValidatorFunction = function(rules, globals){
    this.rules = rules;
    this.global = globals;
    this.messages = [];
    this.toolTips = [];
    this.value = undefined;
    this.clear = false;

    this.bindValue = function(val) {
      this.value = val;
    };

    this.validate = function() {
      this.messages = [];
      if (this.clear) {
        this.clear = false;
        return this.messages;
      }
      //test for required
      if (rules.required === true) {
        if (this.value === null || this.value === undefined) {
          this.messages.push(this.global.i18n.errors.required.fieldNoRef);
          return this.messages;
        }
        else if (this.value.toString().length <= 0) {
          this.messages.push(this.global.i18n.errors.required.fieldNoRef);
          return this.messages;
        }
      }
      if (this.value === null || this.value === undefined) {
        this.value = '';
      }
      //test for min length
      if (rules.minLen !== null) {
        if ( (this.value.toString().length < rules.minLen) && (this.value.length !== 0) ) {
          this.messages.push(this.global.replaceToken(this.global.i18n.errors.minLengthSingle, rules.minLen));
          return this.messages;
        }
      }
      //test for max length
      if (rules.maxLen !== null) {
        if (this.value.toString().length > rules.maxLen) {
          this.messages.push(this.global.replaceToken(this.global.i18n.errors.maxlengthLabelSingle, rules.maxLen));
          return this.messages;
        }
      }
      //test for mask
      if (rules.mask !== null) {
        if (this.value.length > 0) {
          let re = new RegExp(rules.mask);
          if (re.test(this.value) === false) {
            this.messages.push(this.getProp(this.global.i18n, rules.maskMessage));
          }
        }
      }
      return this.messages;
    };

    //creates the array of tool tip items to be shown for the given control
    this.generateToolTips = function() {
      if (rules.minLen !== null) {
        this.toolTips.push(this.global.replaceToken(this.global.i18n.tooltip.minLength, rules.minLen));
      }
      if (rules.inputMaxLen !== null) {
        this.toolTips.push(this.global.replaceToken(this.global.i18n.tooltip.maxLength, rules.inputMaxLen));
      }
      if (rules.maskToolTip !== null) {
        this.toolTips.push(this.getProp(this.global.i18n, rules.maskToolTip));
      }
    };

    //fetches the value of stringProp from i18n
    this.getProp = function(i18n, stringProp){
      let props = stringProp.split('.');
      let val = i18n;
      for (let k = 0; k < props.length; k++) {
        val = val[props[k]];
      }
      return val;
    };
    this.generateToolTips();
  };

  //This function will create a data structure needed to validate a form at the field level
  //page - the page you need validation on, see the above validatorRef mapping - for example, 'VehiclePrimary'
  //section - if the page has a 'search' and 'form' section, pass in which ever one you need
  getForm(page: string, section: string) {
    try {
      let ref = this.validatorRef[page][section];
      let newValFunc = null;

      let validator = function(context, form) {
        this.context = context;
        this.form = form;
        this.allFields = [];

        //called when a validator property is bound to a control
        this.addProperty = function(propName, index) {
          if (index === undefined || index === null) {
            index = 0;
          }
          for (let n = 0; n < this.allFields.length; n++) {
            if (this.allFields[n].id === propName + index) {
              return this.allFields[n];
            }
          }

          newValFunc = new this.context.buildValidatorFunction(JSON.parse(JSON.stringify(this.form[propName])), this.context.globals);
          newValFunc['id'] = propName + index;
          this.allFields.push(newValFunc);
          newValFunc['validateObsr'] = new Subject<any>();
          let that = this;

          //On language change, we must update the tooltip values from the latest i18n value sets
          this.context.globals.getLocaleUpdate().subscribe(next => {
            that.allFields.forEach(elem => {
              if (elem.toolTips.length > 0) {
                elem.toolTips = [];
                elem.generateToolTips();
              }
            });
          });

          return newValFunc;
        };

        this.clearDynammicForm = function() {
          this.allFields = [];
        };

      };
      return new validator(this, ref);
    }
    catch (e) {
      throw new Error('Validator reference ' + page + ' does not exist!')
    }
  };


  //returns true if all fields are currently valid, if at least one field is invalid, it returns false
  validateAll(allFields) {
    let passedVal = true;
    for (let k = 0; k < allFields.length; k++) {
      allFields[k].validateObsr.next({type: 'validate'});
      if (allFields[k].validate().length !== 0) {
        passedVal = false;
      }
    }
    return passedVal;
  }

  clearAll(allFields, observe='clear') {
    for (let k = 0; k < allFields.length; k++) {
      this.clearField(allFields[k], observe);
      //allFields[k].messages = [];
      //allFields[k].clear = true;
      //allFields[k].validateObsr.next({type: observe});
    }
  }

  clearField(field, observe='clear') {

    field.messages = [];
    field.clear = true;
    field.validateObsr.next({type: observe});
  }

  //fields - array of "buildValidatorFunction" instances
  //Waits for the callstack to empty and then forces a vlidation on all given fields.
  markForValidation(fields) {
    for (let k = 0; k < fields.length; k++) {
      setTimeout(() => {
        fields[k].validateObsr.next({type: 'validate'});
      }, 0);
    }
  }
}

