import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {Globals} from '../../../sharedComponents/global-variables/globals';
import {FileUploadService} from '../../../services/my-layoff-benefits/fileUpload.service';
import {FormValidator} from '../../../sharedComponents/formValidation/formValidator';
import {Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {UploadPopupComponent} from './upload-popup/upload-popup.component';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

  @ViewChild('fileLoad') fileLoad;
  file = undefined;
  loadingPage = true;
  loadingUpload = false;

  innerWidth: any = undefined;

  localGminVal = undefined;

  currentFiles = undefined;

  siteClosedPage = false;
  uploadPage = true;
  applicationErrorPage = false;

  screenErrors = [];
  screenMessages = [];

  displayColumns = ['uploadTimestamp', 'fileName', 'comments', 'checkbox'];

  // session vars
  fileCounter = 0;
  sizeCounter = 0;
  numberOfFilesLimit;
  sizeLimit;

  constructor(
    public globals: Globals,
    public service: FileUploadService,
    private validator: FormValidator,
    public router: Router,
    public dialog: MatDialog
  ) {
    // this.loadingPage = false;
    if (this.globals !== undefined) {
      this.localGminVal = this.globals.gminval;
    }

    // obtain random number for session file amount limit
    // current limit between 10-15
    this.numberOfFilesLimit = Math.floor(Math.random() * 6 + 10);

    // obtain startup info from server
    this.service.startupPage(this.localGminVal).subscribe(response => {
      this.loadingPage = false;
      if (response != null) {
        if (!response.businessIsOpen) {
          this.siteClosedPage = true;
          this.uploadPage = false;
        }
        else {
          this.sizeLimit = response.sizeSessionLimit;

          const responseFileList = response.fileList;
          if (responseFileList.length > 0) {
            for (let i = 0; i < responseFileList.length; ++i) {
              responseFileList[i].uploadTimestamp = this.convertTime(new Date(responseFileList[i].uploadTimestamp));
              responseFileList[i].comments = responseFileList[i].comments == null ? responseFileList[i].comments = '' : responseFileList[i].comments;
            }
            this.currentFiles = new MatTableDataSource(responseFileList);
          }
        }
      }
      else {
        this.applicationErrorPage = true;
        this.uploadPage = false;
      }
    }, error => {
      this.loadingPage = false;
    });
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  openUploadPopup() {
    this.clearMessages();

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'backRed';

    const dialogRef = this.dialog.open(UploadPopupComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(response => {
      if (response != null && response.size > 0) {
        this.fileCounter++;
        this.sizeCounter += response.size;

        // file scan shows potential infection
        if (response.scanResult) {
          this.screenErrors.push(this.globals?.i18n.errors.fileInfectionFound);
        }
      }

      if (this.fileCounter > this.numberOfFilesLimit) {
        alert('You have reached the maximum number of file uploads in a single session.  If you have additional files to upload, log back in to continue.');
        this.globals?.logout();
      }

      if (this.sizeCounter > this.sizeLimit) {
        alert('You have reached the maximum total file size in a single session.  If you have additional files to upload, log back in to continue.');
        this.globals?.logout();
      }

      // refresh table
      this.loadingPage = true;
      this.service.getCurrentFiles(this.localGminVal).subscribe(res => {
        this.loadingPage = false;
        if (res != null) {
          for (let i = 0; i < res.length; ++i) {
            res[i].uploadTimestamp = this.convertTime(new Date(res[i].uploadTimestamp));
          }
          this.currentFiles = new MatTableDataSource(res);
        }
      }, err => {
        this.loadingPage = false;
      });
    });
  }

  getDisplayColumns(){
    return this.displayColumns;
  }

  convertTime(date: Date) {
    let hours = date.getHours();
    let minutes: any = date.getMinutes();
    let seconds: any = date.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    return hours + ':' + minutes + ':' + seconds + ' ' + ampm;
  }

  clearMessages() {
    this.screenMessages = [];
    this.screenErrors = [];
  }
}
