import { Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-expandable',
  templateUrl: './expandable-container.component.html',
  styleUrls: ['./expandable-container.component.css'],
  animations: [
    trigger('expandTrigger', [
      state('collapsedState', style({transform: 'translateY(-100%)', display: 'none', height: '0px'})),
      state('expandedState', style({height: '*'})),
      transition('expandedState <=> collapsedState', animate('400ms ease-in')),
    ]),
  ]
})
export class ExpandableContainerComponent{
  @Input() title: String = '';
  @Input() expanded: Boolean = true;
  @Input() expandable: Boolean = true;
  @Input() wideExpandable: Boolean = false;


  constructor() { }

}
