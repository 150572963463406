import { Injectable } from '@angular/core';
import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { Globals } from 'src/app/sharedComponents/global-variables/globals';

@Injectable({
  providedIn: 'root'
})

export class DataSharingService {
  clearURLPatterns = ['/controlTables/', '/advancedSearch', '/homeView', '/upload', '/release-jv',
                      '/action-transmission', '/group-maintenance', '/contact-maintenance'];
  constructor() {
    if (window.sessionStorage.getItem('sharedInformation') !== null) {
      console.log(window.sessionStorage.getItem('sharedInformation'));
      this.sharedInformation = new BehaviorSubject(JSON.parse(window.sessionStorage.getItem('sharedInformation')));
      this.currentInformation = this.sharedInformation.asObservable();
    }
  }

  private vinSource = new ReplaySubject <string>();
  private personIdSource = new ReplaySubject <string>();
  private eventIdSource = new ReplaySubject <string>();
  private authCodeSource = new ReplaySubject <string>();
  private orderNumberSource = new ReplaySubject <string>();
  private licensePlateNumberSource = new ReplaySubject <string>();
  private taxIdSource = new ReplaySubject <string>();
  private licensePlateStateCodeSource = new ReplaySubject <string>();
  private licensePlateCountryCodeSource = new ReplaySubject <string>();
  private poolCodeSource = new ReplaySubject <string>();
  private regionCodeSource = new ReplaySubject <string>();
  private gmInSource = new ReplaySubject <string>();
  private gmIdSource = new ReplaySubject <string>();

  currentVinMessage = this.vinSource.asObservable();
  currentPersonIdMessage = this.vinSource.asObservable();
  currentEventIdMessage = this.vinSource.asObservable();
  currentAuthCodeMessage = this.vinSource.asObservable();
  currentOrderNumberMessage = this.vinSource.asObservable();
  currentLicensePlateNumberMessage = this.vinSource.asObservable();
  currentTaxIdMessage = this.vinSource.asObservable();
  currentLicensePlateStateCodeMessage = this.vinSource.asObservable();
  currentLicensePlateCountryCodeMessage = this.vinSource.asObservable();
  currentPoolCodeMessage = this.vinSource.asObservable();
  currentRegionCodeMessage = this.vinSource.asObservable();
  currentgmInMessage = this.gmInSource.asObservable();


  private sharedInformation = new BehaviorSubject({
    vin: '',
    personId: '',
    eventId: '',
    authCode: '',
    orderNumber: '',
    licensePlateNumber: '',
    taxId: '',
    licensePlateStateCode: '',
    licensePlateCountryCode: '',
    poolCode: '',
    regionCode: '',
    gmIn: '',
    gmId: '',
    reservationNumber: '',
    startDate: '',
    endDate: '',
    vinList: []
  });
  currentInformation = this.sharedInformation.asObservable();

  changeInformation(newInformation) {
    this.clearInformation();
    let newObj = this.sharedInformation.getValue();
    // overwriting existing properties
    for(let attribute in newObj) {
      if(newInformation[attribute] !== undefined) {
        if(newInformation[attribute] === null) {
          newObj[attribute] =  '';
        } else {
          newObj[attribute] =  newInformation[attribute];
        }
      }
    }
    // adding new ones, this is necessary if they never close out of chrome
    // make sure you add your new property to the initialization as well
    for(let newAttribute in newInformation) {
      if(newObj[newAttribute] === undefined) {
        if(newInformation[newAttribute] === null) {
          newObj[newAttribute] =  '';
        } else {
          newObj[newAttribute] =  newInformation[newAttribute];
        }
      }
    }
    if (newObj['orderNumber'] !== ''){
      newObj['orderNumber'] = this.formatOrderNumber(newObj['orderNumber']);
    }
    if (!this.isNullorEmptyArray(newObj['vinList'])){
      const vins = [];
      const data = newObj['vinList'];
      for (let i = 0; i < data.length; i++)
      {
        if (!this.isNullorEmpty(data[i].vin))
        {
          vins.push({vin: data[i].vin});
        }
      }
      newObj['vinList'] = vins;
    }
    window.sessionStorage.setItem('sharedInformation', JSON.stringify(newObj));
    // this.sharedInformation.next(newObj);
  }
  isNullorEmpty(data){
    return (data === null || data === undefined || data === '' );
  }
  isNullorEmptyArray(data){
    return (data === null || data === undefined || data.length === 0 );
  }
  formatOrderNumber(orderNumber: string){
    orderNumber = orderNumber.trim();
    if (orderNumber.length > 6){
      orderNumber = orderNumber.substr(orderNumber.length - 6);
    }
    return orderNumber;
  }
  clearInformationByURLPattern(url) {
    // Look for Complete Clear patterns
    for (const urlPattern of this.clearURLPatterns) {
      if ( url.indexOf( urlPattern) >= 0 ){
        this.clearInformation();
        console.log('Data Sharing Cleared for ' + url );
      }
    }
  }

  clearInformation() {
    let newObj = this.sharedInformation.getValue();
    for(let attribute in newObj) {
      newObj[attribute] = '';
    }
    // this.sharedInformation.next(newObj);
  }

 changeVinMessage(message: string)
 {
   this.vinSource.next(message);
 }
  changePersonIdSourceMessage(message: string)
  {
    this.personIdSource.next(message);
  }
  changeEventIdSourceMessage(message: string)
  {
    this.eventIdSource.next(message);
  }
  changeAuthCodeSourceMessage(message: string)
  {
    this.authCodeSource.next(message);
  }
  changeOrderNumberSourceMessage(message: string)
  {
    this.orderNumberSource.next(message);
  }
  changeLicensePlateNumberSourceMessage(message: string)
  {
    this.licensePlateNumberSource.next(message);
  }
  changeTaxIdSourceMessage(message: string)
  {
    this.taxIdSource.next(message);
  }
  changeLicensePlateStateCodeSourceMessage(message: string)
  {
    this.licensePlateStateCodeSource.next(message);
  }
  changeLicensePlateCountryCodeSourceMessage(message: string)
  {
    this.licensePlateCountryCodeSource.next(message);
  }
  changePoolCodeSourceMessage(message: string)
  {
    this.poolCodeSource.next(message);
  }
  changeRegionCodeSourceMessage(message: string)
  {
    this.regionCodeSource.next(message);
  }
  changeGMINMessage(message: string)
  {
    this.gmInSource.next(message);
  }
  changeGMIDMessage(message: string)
  {
    this.gmIdSource.next(message);
  }
}
