import {Pipe, PipeTransform} from "@angular/core";


@Pipe({
  name: 'toNegativeCurrency'
})
export class ToNegativeCurrencyPipe implements PipeTransform
{
  transform(value: string):any
  {
    let temp = String(value);
    let tempRet = "(";

    if(temp[0] === '-')
    {
      tempRet += temp.substring(1, temp.length);
      tempRet += ')';

      return tempRet;
    }
    return temp;
  }
}
