<div *ngIf="errors.length > 0" class="errors">
  {{globals.i18n.errors.header}}
</div>
<ul class="errors" *ngFor="let error of errors">
    {{error}}
</ul>

<ul class="messages" *ngFor="let message of messages">
  {{message}}
</ul>
