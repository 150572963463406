// Catches any form errors and updates the passed in screen messages
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmailGenerator {

  constructor() {}

  generate(to, cc, subject, content) {
    const mailBody = content === '' ? '' : '&body=' + content;
    const ccVal = cc === '' ? '' : '?cc=' + cc;
    const email = 'mailto:' + to + ccVal + '&subject=' + subject + mailBody;
    window.location.href = email;
    /*
      const emailContent = 'To: ' + to + '\n' +
    'CC: ' + cc + '\n' +
    'Subject:' + subject + '\n' +
    'X-Unsent: 1' + '\n' +
    'Content-Type: text' + '\n' +
    '\n' +
    content;
    const emailData = new Blob([emailContent], {type: 'text/plain'});
    const file = window.URL.createObjectURL(emailData);
    const a = document.createElement('a');
    const link = document.createTextNode('emailLink');
    a.appendChild(link);
    a.href = file;
    a.id = 'emailLink';
    a.download = subject + '.eml';
    a.style.visibility = 'hidden';
    document.body.appendChild(a);
   // document.getElementById('emailLink').click();
    */

  }
}
