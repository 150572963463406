import { Component, OnInit } from '@angular/core';
import {Globals} from "../../../sharedComponents/global-variables/globals";
import {Router} from "@angular/router";

@Component({
  selector: 'app-system-error',
  templateUrl: './system-error.component.html',
  styleUrls: ['./system-error.component.css']
})
export class SystemErrorComponent implements OnInit {

  constructor(public globals: Globals, public router: Router) { }

  ngOnInit(): void {
  }
  goback() {

    if(this.globals.gminval !==''){
      this.router.navigate(['mylayoffbenef']);
    }else{
      this.globals.captchaverify = false;
      this.globals.systemunvailable === false
    }

  }
}
