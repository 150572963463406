import { Injectable, Inject, ComponentFactoryResolver } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import {} from '@angular/common/http';

// Import of all modals
import { ErrorModalComponent } from '../../sharedComponents/modal/modalComponents/error/error-modal.component';
import { ConfirmationModalComponent } from '../../sharedComponents/modal/modalComponents/confirmation/confirmation-modal.component';
import { InformativeModalComponent } from '../../sharedComponents/modal/modalComponents/informative/informative-modal.component';
import {PrivacyModalComponent} from '../../sharedComponents/modal/modalComponents/privacy-modal/privacy-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modalComponents = {
    'Error': ErrorModalComponent,
    'Confirmation': ConfirmationModalComponent,
    'Informative': InformativeModalComponent,
    'Privacy': PrivacyModalComponent
  };
  private modalChange = new Subject<any>();
  factoryResolver = undefined;
  rootViewContainer = undefined;

  constructor(@Inject(ComponentFactoryResolver) factoryResolver) {
    this.factoryResolver = factoryResolver;
  }

  // attaches the modal to the DOM and initializes the component
  addModal(identifier, data, index) {
    const factory = this.factoryResolver.resolveComponentFactory(this.modalComponents[identifier]);
    const component = factory.create(this.rootViewContainer.parentInjector, data);
    component.instance.data = data; // attaches the data object to the new instance
    component.instance.selfComponent = component;
    component.instance.index = index;
    const removalObservable = new Subject<any>();
    component.instance.removalObservable = removalObservable;
    this.rootViewContainer.insert(component.hostView);
    return removalObservable;
  }

  createModal(identifier: String, data: any) {
    this.modalChange.next({ identifier: identifier, data: data });
  }

  // returns the observable which is used to create new modals
  getModalUpdate(): Observable<any> {
    return this.modalChange.asObservable();
  }

  setRootViewContainerRef(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }
}
