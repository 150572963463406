<div class="modalContainer">
  <div class="headerContainer">
    <div class="modalClose">
      <i (click)="confirmation = false; close();" class="closeBtn" class="fa fa-2x fa-times" aria-hidden="true"></i>
    </div>
  </div>
  <div class="messageContainer">
    {{data.message}}
  </div>
  <div class="controlsContainer">
    <app-button type="primary" class="margin" value="Ok" (click)="confirmation = true; close();"></app-button>
    <app-button type="secondary" class="margin" value="Cancel" (click)="confirmation = false; close();"></app-button>
  </div>
</div>
