import { masks } from '../../masks';

let search = {
  gmId: {
    minLen: null,
    inputMaxLen: 8,
    maxLen: 8,
    mask: masks.maskGmid.mask,
    maskMessage: masks.maskGmid.maskMessage,
    maskToolTip: masks.maskGmid.maskToolTip,
    required: false
  },
  gmIn: {
    minLen: null,
    inputMaxLen: 9,
    maxLen: 9,
    mask: masks.maskGmin.mask,
    maskMessage: masks.maskGmin.maskMessage,
    maskToolTip: masks.maskGmin.maskToolTip,
    required: false
  },
  name: {
    minLen: null,
    inputMaxLen: 50,
    maxLen: 50,
    mask: masks.alphanumericWithSpace.mask,
    maskMessage: masks.alphanumericWithSpace.maskMessage,
    maskToolTip: masks.alphanumericWithSpace.maskToolTip,
    required: false
  },
  cedula: {
    minLen: null,
    inputMaxLen: 9,
    maxLen: 9,
    mask: masks.numeric.mask,
    maskMessage: masks.numeric.maskMessage,
    maskToolTip: masks.numeric.maskToolTip,
    required: false
  },
};

let form = {
  cedula: {
    minLen: null,
    inputMaxLen: 9,
    maxLen: 9,
    mask: masks.maskGmin.mask,
    maskMessage: masks.maskGmin.maskMessage,
    maskToolTip: masks.maskGmin.maskToolTip,
    required: true
  },
  nombre: {
    minLen: null,
    inputMaxLen: 15,
    maxLen: 15,
    mask: masks.maskCaseNbr.mask,
    maskMessage: masks.maskCaseNbr.maskMessage,
    maskToolTip: masks.maskCaseNbr.maskToolTip,
    required: false
  },
  carne: {
    minLen: null,
    inputMaxLen: 50,
    maxLen: 50,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  },
  area: {
    minLen: null,
    inputMaxLen: null,
    maxLen: null,
    mask: masks.date.mask,
    maskMessage: masks.date.maskMessage,
    maskToolTip: masks.date.maskToolTip,
    required: true
  },

};

export {search, form};
