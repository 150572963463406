import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Output} from '@angular/core';
import {Globals} from "../global-variables/globals";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";

@Component({
  selector: 'app-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.css']
})

export class InputSelectComponent implements OnInit {

  @HostBinding('class.col-md-6') smallInputClass: Boolean = true;
  @HostBinding('class.col-md-12') bigInputClass: Boolean = false;
  @HostBinding('class.col-modal-6') smallInputClassModal: Boolean = false;
  @HostBinding('class.col-modal-12') bigInputClassModal: Boolean = false;

  selectedValue: any = undefined;

  @Output()
  selectedChange = new EventEmitter<string>();

  @Input()
  get selected(){
    return this.selectedValue;
  }

  hideRequiredMarker = true;

  checkOptions(val) {
    /*
    for(let i = 0; i < this._options.length; i++) {
      if(this._options[i][0] === val) {
        return true;
      }
    }
    return false;
    */
    return true;
  }

  set selected(val) {
    /*
    if (val !== null && val !== undefined) {
      this.selectedValue = val[0];
    }
    else {
      this.selectedValue = val;
    } */
    if(!this.checkOptions(val)) {
      val = null;
      console.error("Error: Value received not in options for dropdown, please handle this value.");
    }
    this.selectedValue = val;
    this.selectedChange.emit(this.selectedValue);
    if (this.validator !== undefined) {
      this.validator.bindValue(val);

      let now: any = new Date();
      if (now - this.lastClearTime > this.lastClearTimeDelta) {
        this.validate();
      }
    }
    this.displayValue = this.getValue(val);
  }

  onClick() {
    if (this.clickHandler !== undefined) {
      this.clickHandler(() => {
        this.requiresVal = true;
      });
    }
  }

  @Input() mandatory: Boolean = false;
  @Input() label: String = '';
  @Input() fieldLevelSecurity: any = undefined;
  @Input() editable: Boolean = true;
  @Input() name: String = '';

  @Input() selectHandler: any = undefined;
  @Input() clickHandler: any = undefined;
  @Input() showKey: Boolean = false;
  @Input() showValue: boolean = true;
  @Input() bigInput: Boolean = false;
  @Input() modal: Boolean = false;
  @Input() validator: any = undefined;
  @Input() maxLength: Number = 1000;
  @Input() minLength: Number = 0;
  @Input() nullValue: Boolean = true;
  @Input() specialRowSpanningInputs: boolean = false;
  @Input() tableInput: boolean = false;
  @Input() virtualScroll: boolean = false;

  @Input() set options(values) {
    this._options = values;
    this.displayValue = this.getValue(this.selected);
  }
  get options() {
    return this._options;
  }

  private _options: any = undefined;

  displayValue: any = undefined;

  validatorMessages = [];
  toolTips = '';
  changedValue: string;
  requiresVal = false;
  lastClearTime: any = new Date(); //used to fix weird issue with the validator kicking in on recently cleared
  lastClearTimeDelta = 100; //time in ms, used to disable the setter validator for this duration

  constructor(private globals: Globals) {
    this.displayValue = this.getValue(this.selected);
  }

  compareFn: ((option: any, value: any) => boolean) | null = this.compareByValue;

  compareByValue(option: any, value: any) {
    if (option === undefined || option === null || value === undefined || value === null) {
      return false;
    }
    return option === value;
  }

  onSelect(event) {
    if (this.selectHandler !== undefined) {
      this.selectHandler(event);
    }
  }


  public validate() {
    //console.log(this.validator);
    if (this.validator !== undefined) {
      if(this.validator.clear || (this.selectedValue !== null && this.mandatory)) {
        // get rid of the red arrow being dumb
        this.hideRequiredMarker = true;
      } else {
        this.hideRequiredMarker = false;
      }
      this.validatorMessages = undefined;
      this.validatorMessages = this.validator.validate(this.selectedValue);
    }
  }

  endingCharacter() {
    if (this.label[this.label.length - 1] === ':'){
      return '';
    }
    else {
      return ':';
    }
  }

  showOption(elem) {
    if (this.showKey) {
      // need to fix that, don't want to see the thing here, but they are not the same...
      if(!this.showValue) {
        if(elem[0] === '*') {
          return elem[1];
        }
        return elem[0];
      }
      return elem[0] + ' - ' + elem[1];
    }
    else {
      return elem[1];
    }
  }

  getValue(val) {
    if(this.options !== undefined) {
      for(let i = 0; i < this.options.length; i++) {
        if(this.options[i][0] === val) {
          return this.showOption(this.options[i]);
        }
      }
      //return null;
    }
    //return val;
    return null;
  }

  isDisabledTextField() {
    return (!this.editable && (this.fieldLevelSecurity === 'U' || this.fieldLevelSecurity === 'A')) ||
      (this.fieldLevelSecurity === undefined && !this.editable) || this.fieldLevelSecurity === 'I';
  }

  getHeight() {
    let numOptions = 6;
    if(this.options.length < 6) {
      numOptions = this.options.length;
      if(this.nullValue) {
        numOptions += 1;
      }
    }
    return this.tableInput ? 36 * numOptions : 42 * numOptions;
  }

  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
  previousTime = new Date().getTime();
  previousStr = '';

  findOption(event: any) {
    if(this.virtualScroll) {
      let currentTime = new Date().getTime();
      let evalStr = currentTime - this.previousTime > 500 ? event.key : this.previousStr + event.key;

      for (let i = 0; i < this.options.length; i++) {
        let displayValue = this.showOption(this.options[i]);
        if (displayValue.toUpperCase().startsWith(evalStr.toUpperCase())) {
          this.selected = this.options[i];
          this.viewPort.scrollToIndex(i);
          break;
        }
      }
      this.previousTime = currentTime;
      this.previousStr = evalStr;
    }
  }

  scrollToOption() {
    if(this.virtualScroll) {
      if(this.selectedValue === null || this.selectedValue === "") {
        this.viewPort.scrollToIndex(0);
      } else {
        //document.getElementById(this.selectedValue).scrollIntoView();
        for(let i = 0; i < this.options.length; i++) {
          if (this.selectedValue === this.options[i][0]) {
            this.viewPort.scrollToIndex(i);
            break;
          }
        }
      }
    }
  }

  ngOnInit() {
    let validate = this.validate;
    let that = this;
    if (this.specialRowSpanningInputs === true) {
      this.bigInputClass = true;
      this.smallInputClass = false;
    }
    else if (this.bigInput === true && this.modal === false) {
      this.bigInputClass = true;
      this.smallInputClass = false;
    }
    else if (this.bigInput === true && this.modal === true) {
      this.bigInputClassModal = true;
      this.smallInputClass = false;
    }
    else if (this.bigInput === false && this.modal === true) {
      this.smallInputClass = false;
      this.smallInputClassModal = true;
    }
    //set up the validator
    if (this.validator !== undefined) {

      this.validator.bindValue(this.selectedValue);

      this.validator.validateObsr.subscribe(flag => {
        if (flag.type === 'clear') {
          this.validatorMessages = [];
          // this.validatorMessages = this.validator.validate(null);
          this.lastClearTime = new Date();
        }
        else {
          this.validate();
        }
      });
      if (this.validator.rules.required === true) {
        this.mandatory = true;
      }
      if (this.validator.rules.inputMaxLen !== null) {
        this.maxLength = this.validator.rules.maxLen;
      }
      if (this.validator.toolTips.length > 0) {
        this.toolTips = this.validator.toolTips.join('\n');
      }
    }
  }

}

