import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {HomeService} from "../../services/home/home.service";

@Component({
  selector: 'app-app-noaccess',
  templateUrl: './noaccess.component.html',
  styleUrls: ['./noaccess.component.css']
})
export class NoaccessComponent implements OnInit {

  constructor(public home: HomeService) {
    this.home.badRequest().subscribe();
  }

  ngOnInit() {
  }

}
