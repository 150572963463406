import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  constructor(private http: HttpClient) { }

  private originUrl = window.location.origin;
  private url = environment.apiURL;

  getProperties(language: String) {
    let langPath = 'us';
    switch (language) {
      case 'us': {
        langPath = 'us';
        break;
      }
      case 'es': {
        langPath = 'es';
        break;
      }
      default: {
        langPath = 'us';
        break;
      }
    }
    return this.http.get(this.originUrl + '/assets/i18n/messages.properties_' + langPath + '.json');
  }
  changeLanguage(language): any {
    // return this.http.post(this.url + '/security/changeLanguage', language);
    return true;
  }

}
