import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {Globals} from '../../../../sharedComponents/global-variables/globals';
import {FileUploadService} from '../../../../services/my-layoff-benefits/fileUpload.service';
import {FormValidator} from '../../../../sharedComponents/formValidation/formValidator';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-upload-popup',
  templateUrl: './upload-popup.component.html',
  styleUrls: ['./upload-popup.component.css']
})
export class UploadPopupComponent implements OnInit {

  @ViewChild('fileLoad') fileLoad;
  file = undefined;
  loadingPage = true;
  loadingUpload = false;

  innerWidth: any = undefined;

  localGminVal = undefined;

  comment = '';
  currentFileSize = '0';

  submitValidator = undefined;
  validatorSvc = 'FileUploadValidator';

  validFileExtensions = [
    'pdf',
    'jpg',
    'jpeg',
    'png',
    'tiff',
    'tif'
  ];

  screenErrors = [];
  screenMessages = [];

  constructor(
    public globals: Globals,
    public service: FileUploadService,
    public router: Router,
    private dialogRef: MatDialogRef<UploadPopupComponent>,
    private dialog: MatDialog,
    private validator: FormValidator
  ) {
    this.loadingPage = false;
    if (this.globals !== undefined) {
      this.localGminVal = this.globals.gminval;
    }
    this.submitValidator = this.validator.getForm(this.validatorSvc, 'submit');
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  addFile() {
    this.fileLoad.nativeElement.click();
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.clearMessages();
      this.file = event.target.files[0];
      this.currentFileSize = (this.file.size / 1000000).toFixed(2);

    }
  }

  fileSubmit() {
    this.clearMessages();

    if (!this.validator.validateAll(this.submitValidator.allFields)) {
      return;
    }

    if (this.file === undefined) {
      this.screenErrors.push(this.globals?.i18n.errors.fileNotAttached);
      return;
    }

    // reject files >25MB (26,214,400 bytes)
    if (this.file.size > 26214400) {
      this.screenErrors.push(this.globals?.i18n.errors.largeFileSize);
      return;
    }

    // reject if file extension not in validFileExtensions
    const fileExtension: string = this.file.name.split('.').pop();
    if (fileExtension == null || fileExtension === '' || !this.validFileExtensions.includes(fileExtension.toLowerCase())) {
      this.screenErrors.push(this.globals?.replaceToken(this.globals?.i18n.errors.invalidFileExtension, fileExtension));
      return;
    }

    // replace special char in file name
    if ((new RegExp(/[^A-Za-z-0-9_.() ]/)).test(this.file.name)) {
      alert('File name must contain only letters, numbers, or accepted characters: - . ( ) _');
      return;
    }

    // prompt user to confirm file name truncation
    if (this.file.name.length > 50) {
      if (!confirm(this.globals?.i18n.messages.fileNameTruncation)) {
        return;
      }
    }

    this.loadingUpload = true;
    let commentVal = '';
    if (this.comment != null && this.comment !== '') {
      commentVal = this.comment.replace(/[^A-Za-z-0-9()./ ]+/g, ' ');
    }

    const fileData = new FormData();

    if (this.file !== undefined) {
      fileData.append('file', this.file);
      fileData.append('comment', commentVal);
    }

    this.service.fileUpload(fileData, this.localGminVal).subscribe(data => {
        this.loadingUpload = false;
        if (data != null) {
          /*
           * Error codes:
           * 0 - normal response
           * 1 - file scan failed
           * 2 - file infection found
           * 3 - upload error
           */
          switch (data) {
            case 0:
              this.dialogRef.close(this.file.size);
              this.dialogRef.close({
                size: this.file.size,
                scanResult: false
              });
              break;
            case 1:
              // scan failed
              this.screenErrors.push(this.globals?.i18n.errors.fileScanFailed);
              break;
            case 2:
              // potential infection found
              this.dialogRef.close({
                size: this.file.size,
                scanResult: true
              });
              break;
            case 3:
              // upload error
              this.screenErrors.push(this.globals?.i18n.error.appErrorContactUs);
              break;
            default:
          }
        }
      },
      error => {
        this.loadingUpload = false;
        this.screenErrors.push(this.globals?.i18n.error.appErrorContactUs);
      });
  }

  clearFile() {
    this.file = undefined;
    this.fileLoad.nativeElement.value = null;
    this.currentFileSize = '0';
    this.clearMessages();
  }

  clearMessages() {
    this.screenMessages = [];
    this.screenErrors = [];
  }

  clearAll() {
    this.clearFile();
  }

  cancelPopup() {
    if (this.loadingUpload) {
      return;
    }
    this.dialogRef.close(0);
  }

}
