<div style="display: block" class="container" [ngClass]="{'wideExpandable': wideExpandable}">
  <div class="bannerBar">
    <i *ngIf="expandable === true" class="fa fa-lg collapseIcon" [ngClass]="{'fa-plus': expanded === false, 'fa-minus': expanded === true}" aria-hidden="true" (click)="expanded = !expanded"></i>
    <div class="title">
      {{title}}
    </div>
  </div>

  <div class="content" style="display: block" [@expandTrigger]="expanded === true ? 'expandedState' : 'collapsedState'">
   <ng-content></ng-content>
  </div>
</div>


