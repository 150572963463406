import { Component, OnDestroy, Inject, ViewContainerRef, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from '../../services/modal/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['./modal.component.css']
})


export class ModalComponent implements OnDestroy {
  subscription: Subscription;
  public activeModals = [];

  constructor(@Inject(ModalService) service, @Inject(ViewContainerRef) viewContainerRef, public element: ElementRef) {
    service.setRootViewContainerRef(viewContainerRef);
    console.log(element.nativeElement.childElementCount);
    const that = this;
    // subscribe to home component messages
    this.subscription = service.getModalUpdate().subscribe(data => {
      console.log(this.element);
      console.log(that);
        // listen to the service adding modals
      service.addModal(data.identifier, data.data, this.activeModals.length).subscribe(remove => {
        // called when a modal is closed
        if (remove.hasOwnProperty('callback')) {
          remove.callback(remove.data);
        }
        this.activeModals.splice(remove.index, 1);
      });
      this.activeModals.push({
        id: data.identifier
      });
      that.element.nativeElement.parentElement.children[that.activeModals.length].addEventListener('wheel', function(e) {
        e.stopPropagation();
        e.preventDefault();
      });
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
