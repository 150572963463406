<div class="backRed">
<div class="modalClose">
  <i (click)="cancelPopup()" class="closeBtn" class="fa fa-2x fa-times" aria-hidden="true"></i>
</div>
<mat-dialog-content>
  <div class="tableContainer detailArea wideExpandable bottomSpace">

    <app-loading *ngIf="loadingPage"></app-loading>
    <div *ngIf="!loadingPage && globals?.gminval !== ''">
      <app-expandable class="wideExpandable" [wideExpandable]="true" [expandable]="false" [title]="globals?.i18n.title.fileupload">
        <br>
        <!-- Notes -->
        <div class="rowContainer centeredFlex" style="font-style: italic">
          <p> <b>Allowed file types: PDF, JPG, JPEG, PNG, TIFF, TIF</b> </p>
        </div>

        <!--File upload desktop-->
<!--        <div *ngIf="innerWidth > 800" class="rowContainer centeredFlex">-->
<!--          <div class="pushDown">{{file?.name || 'NO FILE'}}</div>-->
<!--          <app-button type="primary" class="margin1" value="{{'Select File'}}"-->
<!--                      [disabled]="loadingPage"-->
<!--                      (click)="addFile()"></app-button>-->
<!--          <input type="file" #fileLoad style="display: none" name="avatar"-->
<!--                 (click)="clearFile()"-->
<!--                 (change)="onFileChange($event)"/>-->
<!--          <app-button type="secondary" class="margin1" value="{{'Clear File'}}"-->
<!--                      (click)="clearFile()"></app-button>-->
<!--        </div>-->

        <!--File upload mobile-->
<!--        <div *ngIf="innerWidth <= 800" class="container">-->
<!--          <div class="rowContainer centeredFlex">-->
<!--            <div class="pushDown">{{file?.name || 'NO FILE'}}</div>-->
<!--          </div>-->
<!--          <div class="rowContainer centeredFlex">-->
<!--          <app-button type="primary" class="margin1" value="{{'Select File'}}"-->
<!--                      [disabled]="loadingPage"-->
<!--                      (click)="addFile()"></app-button>-->
<!--          </div>-->
<!--          <input type="file" #fileLoad style="display: none" name="avatar"-->
<!--                 (click)="clearFile()"-->
<!--                 (change)="onFileChange($event)"/>-->
<!--          <div class="rowContainer centeredFlex">-->
<!--          <app-button type="secondary" class="margin1" value="{{'Clear File'}}"-->
<!--                      (click)="clearFile()"></app-button>-->
<!--          </div>-->
<!--        </div>-->

        <!-- File size indicator -->
        <div class="rowContainer centeredFlex">
          <div class="pushDown marginRight">{{file?.name || 'NO FILE'}}</div>
          <p>File Size: {{currentFileSize}} MB</p>
        </div>

        <!-- Comments -->
        <div class="container rowContainer centeredFlex">
<!--          <app-textarea [editable]="!loadingUpload"-->
<!--                        [label] = "globals?.i18n.field.label.comments"-->
<!--                        [(value)]="comment"-->
<!--                        [bigInput]="true"-->
<!--                        [validator]="submitValidator.addProperty('comment')"-->
<!--          ></app-textarea>-->
          <label>{{globals?.i18n.field.label.commentsNotRequired}}:</label>
          <app-textbox-control
            [(value)]="comment"
            [validator]="submitValidator.addProperty('comment')"
            [editable]="!loadingUpload"
            [bigInput]="true"
            [maxLength]="50"
          ></app-textbox-control>
        </div>

        <!-- Character Limit -->
        <div class="rowContainer1 centeredFlex">
          <p>Character Limit: {{comment.length}} / 50</p>
        </div>

        <!-- Buttons for desktop -->
        <div class="container" *ngIf="innerWidth > 800">
          <div class="rowContainer centeredFlex">
            <app-button type="primary" class="margin1 pushDown1" value="{{'Select File'}}"
                        [disabled]="loadingPage || (file != null)"
                        (click)="addFile()"></app-button>
            <input type="file" #fileLoad style="display: none" name="avatar"
                   (click)="clearFile()"
                   (change)="onFileChange($event)"/>
            <app-button type="secondary" class="margin1 pushDown1" value="{{'Clear File'}}"
                        (click)="clearFile()"></app-button>

            <app-button class="margin" type="primary" value="{{globals.i18n.application.button.upload}}"
                        (click)="fileSubmit()"
                        [disabled]="loadingUpload"
            ></app-button>
            <app-button class="margin" type="secondary" value="{{globals.i18n.application.button.cancel}}"
                        (click)="cancelPopup()"
                        [disabled]="loadingUpload"
            ></app-button>
          </div>
        </div>

        <!-- Buttons for mobile -->
        <div class="container" *ngIf="innerWidth <= 800">
          <div class="rowContainer centeredFlex">
            <app-button type="primary" class="margin1 pushDown1" value="{{'Select File'}}"
                        [disabled]="loadingPage || (file != null)"
                        (click)="addFile()"></app-button>
            <input type="file" #fileLoad style="display: none" name="avatar"
                   (click)="clearFile()"
                   (change)="onFileChange($event)"/>
            <app-button type="secondary" class="margin1 pushDown1" value="{{'Clear File'}}"
                        (click)="clearFile()"></app-button>
          </div>
          <div class="rowContainer centeredFlex">
            <app-button class="margin" type="primary" value="{{globals.i18n.application.button.upload}}"
                        (click)="fileSubmit()"
                        [disabled]="loadingUpload"
            ></app-button>
            <app-button class="margin" type="secondary" value="{{globals.i18n.application.button.cancel}}"
                        (click)="cancelPopup()"
                        [disabled]="loadingUpload"
            ></app-button>
          </div>
        </div>


        <div class="rowContainer centeredFlex">
          <app-screen-message [errors]="screenErrors" [messages]="screenMessages"></app-screen-message>
        </div>

        <app-loading *ngIf="loadingUpload"></app-loading>

        <!-- Notes -->
        <div class="rowContainer centeredFlex" style="font-style: italic">
          <p> <b>*If you require additional assistance, please submit a 'Contact Us' through this website</b> </p>
        </div>

      </app-expandable>
    </div>
  </div>
</mat-dialog-content>
</div>
