import {Component, Input, OnInit} from '@angular/core';
import {Globals} from '../../../global-variables/globals';

@Component({
  selector: 'app-modal-informative-dialog',
  templateUrl: './informative-modal.component.html',
  styleUrls: ['./informative-modal.component.css']
})

export class InformativeModalComponent implements OnInit {

  message: any;
  data: any;
  selfComponent: any;
  index: number;
  removalObservable: any;

  constructor(public globals: Globals) {}

  close() {
    this.removalObservable.next({index: this.index, data: {}, callback: this.data.callback}); // update the base modal component about the removal
    this.selfComponent.destroy();
  }

  ngOnInit() {
    console.log(this.data);
  }
}
