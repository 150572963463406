import {Component, EventEmitter, HostBinding, Inject, Input, OnInit, Output} from '@angular/core';
import {ToNumberPipe} from "../pipes/number.pipe";
import {ToNegativeCurrencyPipe} from "../pipes/negativeCurrency.pipe";
import {DecimalPipe} from "@angular/common"
import {Globals} from "../global-variables/globals";

@Component({
  selector: 'app-textbox-control',
  templateUrl: './textBox-control.component.html',
  styleUrls: ['./textBox-control.component.css', '../sharedStyling/shared-styling.component.css'],
  providers: [DecimalPipe, ToNegativeCurrencyPipe, ToNumberPipe]
})

export class TextBoxControlComponent implements OnInit {

  @HostBinding('class.col-md-6') smallInputClass: Boolean = true;
  @HostBinding('class.col-md-12') bigInputClass: Boolean = false;
  @HostBinding('class.col-modal-6') smallInputClassModal: Boolean = false;
  @HostBinding('class.col-modal-12') bigInputClassModal: Boolean = false;

  @Input() isButtonDisplayed: Boolean = false;
  @Input() labelText: String = '';
  @Input() fieldLevelSecurity: any = undefined;
  @Input() editable: Boolean = true;
  @Input() clickHandler: any = undefined;
  @Input() inputWidth: Number = 200;
  @Input() mandatory: Boolean = false;
  @Input() bigInput: Boolean = false;
  @Input() modal: Boolean = false;
  @Input() maxLength: Number = 1000;
  @Input() minLength: Number = 0;
  @Input() type: String = 'text';
  @Input() specialRowSpanningInputs = false;
  @Input() decimal: Boolean = false;
  @Input() right: Boolean = false;
  @Input() validator: any = undefined;
  @Input() link: Boolean = false;
  @Input() postfixLabel: String = undefined;
  @Input() isLabelDisplayed: Boolean = true;
  @Input() isValueCurrency: Boolean = false;
  @Input() tableInput: Boolean = false;
  @Input() excel: Boolean = false;
  @Input() bold: Boolean = false;
  @Input() clickableLabel: Boolean = false;

  pipe = true;

  validatorMessages = [];
  toolTips = '';
  changedValue: string;
  requiresVal = false;
  private clearCall = false;

  @Output()
  valueChange = new EventEmitter<string>();

  @Input()
  get value(){
    return this.changedValue;
  }

  set value(val) {
    this.changedValue = val;
    this.valueChange.emit(this.changedValue);
    if (this.validator !== undefined) {
      this.validator.bindValue(val);
      //fixes race condition when the modals set the value
      if (this.requiresVal === true) {
        this.validate();
      }
    }
  }

  onClickLink() {
    if (this.link === true) {
      if (this.clickHandler !== undefined) {
        this.clickHandler(() => {
          this.requiresVal = true;
        });
      }
    }
  }

  onClickLinkLabel() {
    if (this.clickableLabel === true) {
      if (this.clickHandler !== undefined) {
        this.clickHandler(() => {
          this.requiresVal = true;
        });
      }
    }
  }


  onClick() {
    if (this.clickHandler !== undefined) {
      this.clickHandler(() => {
        this.requiresVal = true;
      });
    }
  }

  labelString(){
    if(this.labelText === 'thisisareallylongandsomewhatuniquestringtoindicateanemptylabel'){
      return '';
    }
    else
    {
      return this.labelText;
    }

  }

  endingCharacter() {
    if (this.labelText[this.labelText.length - 1] === ':'){
      return '';
    }
    if(this.labelText === 'thisisareallylongandsomewhatuniquestringtoindicateanemptylabel'){
      return '';
    }
    else {
      return ':';
    }
  }

  public validate() {
    if (this.validator !== undefined) {
      this.validatorMessages = [];
      if (this.clearCall !== true) {
        this.validatorMessages = this.validator.validate(this.value);
      }
      this.clearCall = false;
    }
  }

  public updateValidatorMessages() {
    if (this.validatorMessages !== undefined && this.validatorMessages.length !== 0) {
      this.validatorMessages = this.validator.validate(this.value);
    }
  }

  public formatCurrency(){

  }

  //calls validate function when the user focuses off the input element
  offFocus() {
      this.validate();
  }

  constructor(public globals: Globals) {
    let that = this;
    this.globals.getLocaleUpdate().subscribe(next => {
      setTimeout(() => {
        console.log(next);
        that.updateValidatorParams(that.validate, that);
        that.updateValidatorMessages();
      }, 0)

    });
  }

  updateValidatorParams(validate, context) {
    if (context.validator !== undefined) {
      context.validator.bindValue(context.value);
      context.validator.validateObsr.subscribe(data => {
        if (data.type === 'clear') {
          context.clearCall = true;
        }
        validate.bind(context)();
      });
      if (context.validator.rules.required === true) {
        context.mandatory = context;
      }
      if (context.validator.rules.inputMaxLen !== null) {
        context.maxLength = context.validator.rules.inputMaxLen;
      }
      if (context.validator.toolTips.length > 0) {
        context.toolTips = context.validator.toolTips.join('\n');
      }
    }
  }

  ngOnInit() {
    let validate = this.validate;
    let that = this;
    if (this.specialRowSpanningInputs === true) {
      this.bigInputClass = true;
      this.smallInputClass = false;
    }
    else if (this.bigInput === true && this.modal === false) {
      this.bigInputClass = true;
      this.smallInputClass = false;
    }
    else if (this.bigInput === true && this.modal === true) {
      this.bigInputClassModal = true;
      this.smallInputClass = false;
    }
    else if (this.bigInput === false && this.modal === true) {
      this.smallInputClass = false;
      this.smallInputClassModal = true;
    }
    //set up the validator
    this.updateValidatorParams(this.validate, that);

    if(this.isValueCurrency)
    {

    }
  }
}


//[ngStyle]="{'width': + inputWidth + 'px'}"
