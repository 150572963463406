import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Globals} from '../../sharedComponents/global-variables/globals';

@Injectable({
  providedIn: 'root'
})
export class SetupMondetService {
  private url = this.globals.url;

  constructor(private http: HttpClient, private globals: Globals) {
  }

  startMondetM0(searchModel): any {
    return this.http.post(this.url + '/CISAws/startMondetWeb', searchModel);
  }

  createMondetM0(createModel): any {
    return this.http.post(this.url + '/CISAws/updateMondetWeb', createModel, {responseType: 'text'});
  }

}
