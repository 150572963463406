import { Component, OnInit } from '@angular/core';
import { Globals } from '../../../sharedComponents/global-variables/globals';
import {Router} from '@angular/router';
import {combineLatest} from 'rxjs';
import {environment} from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {HomeService} from '../../../services/home/home.service';
import {UbetelctService} from "../../../services/my-layoff-benefits/ubetelct/ubetelct.service";
import {MatTableDataSource} from "@angular/material/table";
import {ModalService} from "../../../services/modal/modal.service";


@Component({
  selector: 'app-landing',
  templateUrl: './tsp-election.component.html',
  styleUrls: ['./tsp-election.component.css']
})
export class TspElectionComponent implements OnInit {
  dataForView = [
    {
      typeCode: undefined,
      returnCode: undefined
    }
  ];
  returnCode: any = undefined;
  searchResults: any = undefined;
  displayColumns = ['tspTypeCode'];
  searchModel = {
    gmin: '',
  };
  constructor(public service: UbetelctService, public globals: Globals, public router: Router,private modal: ModalService) {
    this.initializePage();
  }
  loadingPage = false;
  tspPage = false;
  ngOnInit(): void {
  }
  /**
   * Gets columns displayed in matTable
   */
  getDisplayedColumns(){
    return this.displayColumns;
  }
  initializePage(){
    this.loadingPage = true;
    this.searchModel = {
      gmin: this.globals.gminval,
    };
    this.service.getTspTypeCode(this.searchModel).subscribe(response=>{
      this.loadingPage = false;
      this.dataForView=[];
      this.dataForView.push(response);
      this.searchResults = new MatTableDataSource(this.dataForView);
      this.returnCode = response.returnCode;
      this.tspPage = true;
      //this.returnCode = "999";
    },error=>{console.log(error);this.returnCode = "999";this.loadingPage = false;})

  }

  getprivacyinfo(){
    this.modal.createModal('Privacy',  {
      message: [this.globals.i18n.messages.privacy.text1, this.globals.i18n.messages.privacy.text2, this.globals.i18n.messages.privacy.text3],
      callback: data =>{
        if (data.informative){
          return;
        }else{

        }
      }

    });
  }

  navigateToHome(){
    this.router.navigate(['mylayoffbenef']);
  }

}
