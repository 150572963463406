<app-loading *ngIf="loadingPage"></app-loading>
<app-expandable *ngIf="tspPage" [expandable]="false" [title]="globals.i18n.title.message.tspPage" >
  <div class="resultsContainer"  >
<div class="tableContainer1" >
  <ng-container *ngIf="returnCode==='000'">
  <table mat-table matSort  multiTemplateDataRows [dataSource]="searchResults" class="mat-elevation-z2 tableContainer1">
    <!--<table mat-table matSort  multiTemplateDataRows [dataSource]="searchResults" class="mat-elevation-z2 tableContainer1">-->
    <ng-container matColumnDef="tspTypeCode">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>TSP Payment Frequency</th>
      <!--<th  *matHeaderCellDef class="tableHeader1">TSP Payment Frequency</th>-->

      <td mat-cell *matCellDef="let data"><div [ngClass]="{noTspTxtColor:data.typeCode==='TSP Election Data Not Available'}"><b>{{ data.typeCode }}</b></div></td>

      </ng-container>
    <tr mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky:true" class="tableHeader1">
    <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"
        class="tableRow" ></tr>
  </table>
    <br>
    <div  class="tableContainer1">
      Click to return to My Layoff Benefits Menu
    </div>
  <div class="tableContainer1">
    <app-button class="margin" type="primary" value="My Layoff Benefits"
                (click)="navigateToHome()" (keyup.enter)="navigateToHome()"
    ></app-button>
  </div>
  <br>
  <div  class="linkCustom">
    <a (click)="getprivacyinfo()">Privacy Information</a>
  </div>
  </ng-container>
  <ng-container *ngIf="returnCode==='999'">
    <app-system-error></app-system-error>
  </ng-container>
  <ng-container *ngIf="returnCode==='888'">
    <app-system-unavailable></app-system-unavailable>
  </ng-container>
  <ng-container *ngIf="returnCode==='912'">
    <app-gmin-invalid></app-gmin-invalid>
  </ng-container>
</div>
    </div>
  </app-expandable>

