import { Component, OnInit } from '@angular/core';
import {Globals} from  '../../../sharedComponents/global-variables/globals';
import {Router} from  '@angular/router';
import {ModalService} from "../../../services/modal/modal.service";

@Component({
  selector: 'app-gmin-invalid',
  templateUrl: './gmin-invalid.component.html',
  styleUrls: ['./gmin-invalid.component.css']
})
export class GminInvalidComponent implements OnInit {
  systemunvailable
  constructor(public globals: Globals, public router: Router, private modal: ModalService) { }

  ngOnInit(): void {
  }
  showcaptchapage(){

      this.globals.gminattempt = false;
      this.globals.captchaverify = false;
      this.globals.systemerror = false;
      this.globals.gminverify = false;
      this.globals.systemunvailable = false;
      this.globals.gminval = '';
      this.globals.logoutval = false;
      this.router.navigate(['mylayoffbenef']);

  }

}
