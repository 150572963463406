<div class="row">
  <div *ngIf="label" [ngClass]="{'col-md-4': !bigInput, 'col-md-2': bigInput}">
    <label>{{endingCharacter(label)}}</label>
  </div>
  <div [ngClass]="{'col-md-12': !label, 'col-md-8': label && !bigInput, 'col-md-10': label && bigInput}">
    <mat-radio-group *ngIf="buttonValuesString !== undefined" class="radioButtonGroup" [(ngModel)]="value" [labelPosition]="labelPosition"
                     [required]="mandatory"   [disabled]="!editable || fieldLevelSecurity === 'I' || fieldLevelSecurity === 'N'"
                     [ngClass]="{'fullWidth': !isButtonDisplayed,
                                'inputErrorBackground': validatorMessages.length > 0,
                                 'tableInput': tableInput}">
      <mat-radio-button class="radioButtonSpacing" *ngFor="let button of buttonValuesString" [value]="button[0]" (click)="onSelct()">{{endingCharacter(button[1])}}</mat-radio-button>

    </mat-radio-group>
    <mat-radio-group *ngIf="buttonValuesBoolean !== undefined" class="radioButtonGroup" [(ngModel)]="value" [labelPosition]="labelPosition"
                     [required]="mandatory"  [disabled]="!editable || fieldLevelSecurity === 'I' || fieldLevelSecurity === 'N'">
      <mat-radio-button class="radioButtonSpacing" *ngFor="let button of buttonValuesBoolean" [value]="button[0]">{{endingCharacter(button[1])}}</mat-radio-button>
    </mat-radio-group>
    <div *ngFor="let message of validatorMessages" class="inputValidationMessage" [ngClass]="{'inputValidationMessageTable' : tableInput}">
      {{message}}
    </div>

  </div>

</div>
