import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Globals} from "../../sharedComponents/global-variables/globals";

@Injectable({
  providedIn: 'root'
})
export class BenefitApplicationService {
  private url = this.globals.url;

  constructor(private http: HttpClient, private globals: Globals) {
  }

  processBenefitB1(processModel): any {
    return this.http.post(this.url + '/CISAws/processBenefitWeb', processModel);
  }

  updateBenefitB2(updateModel): any {
    return this.http.post(this.url + '/CISAws/updateBenefitWeb', updateModel);
  }

}
