import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ModalService } from '../modal/modal.service';
import { Router } from '@angular/router';
import {SessionTimer} from "../../sharedComponents/sessionTimer/sessionTimer";
// import {noUndefined} from "@angular/compiler/src/util";


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(public modal: ModalService, private router: Router, private sessionTimer: SessionTimer, public http: HttpClient) {}
  // intercepts all HTTP requests, checks for error codes and displays a modal
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap( (event: any) => {
      //Sorin - timeout code
      this.sessionTimer.restartTimer();
    }, (error: any) => {

      if (error.hasOwnProperty('status')) {
        if (error.status === 403) {
          let flag = sessionStorage.getItem("noAccessFlag");
          if (flag === null || flag === undefined) {
            // sessionStorage.setItem("noAccessFlag", "true");
            // sessionStorage.setItem("noAccessExpiration", String(new Date((new Date).getTime() + 30000).getTime()));
            // this.log403(JSON.stringify(error)).subscribe(data => {
            //   console.log(data);
            // });
            // location.reload();
          }
          else if (Boolean(flag) === true) {
            // sessionStorage.clear();
            // this.router.navigate(['/noAccess']);
            // let expString = sessionStorage.getItem("noAccessExpiration");
            // let exp = new Date(Number(expString));
            // let now = new Date();
            // if (now > exp) {
            //   sessionStorage.clear();
            //   this.router.navigate(['/noAccess']);
            // }
          }

        }
        if (error.status !== 403 && error.status !== 200) {
          this.modal.createModal('Error', {
            message: error.message,
            callback: data => {}
          });
        }
      }
    }));
  }

  log403(data): any{
    return this.http.get(document.location.origin + '/logUser');
  }
}
