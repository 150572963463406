<div class="modalContainer">
  <div class="headerContainer">
    <div class="modalClose">
      <i (click)="close();" class="closeBtn" class="fa fa-2x fa-times" aria-hidden="true"></i>
    </div>
  </div>
  <div class="messageContainer">
    {{data.message}}
  </div>
  <div class="controlsContainer">
    <app-button type="primary" class="margin" value="Ok" (click)="close();"></app-button>
  </div>
</div>
