<app-loading *ngIf="loadingPage"></app-loading>
<div class="bottomSpace" *ngIf="!loadingPage && globals?.gminval !== ''">
  <!-- Site closed page -->
  <app-expandable *ngIf="siteClosedPage" [expandable]="false" [title]="globals?.i18n.title.mylayoffbenefits">
    <br>
    <app-system-unavailable></app-system-unavailable>
  </app-expandable>

  <!-- Application error page -->
  <app-expandable *ngIf="applicationErrorPage" [expandable]="false" [title]="globals?.i18n.title.mylayoffbenefits">
    <br>
    <app-system-error></app-system-error>
  </app-expandable>

  <app-expandable *ngIf="uploadPage" [expandable]="false" [title]="globals?.i18n.title.fileupload">
    <br>

    <!-- Table message -->
    <div class="rowContainer centeredFlex" style="font-style: italic">
      <p> <b>Files displayed represent uploads for the current day only</b> </p>
    </div>

    <!-- Results table -->
    <div class="tableContainer">
      <table mat-table multiTemplateDataRows [dataSource]="currentFiles"
             class="mat-elevation-z2 searchResultsTable">
        <ng-container matColumnDef="uploadTimestamp">
          <th mat-header-cell class="centerText boldText" *matHeaderCellDef>{{globals?.i18n.field.label.uploadtimestamp}}</th>
          <td mat-cell class="centerText" *matCellDef="let data" >{{data.uploadTimestamp}}</td>
        </ng-container>
        <ng-container matColumnDef="fileName">
          <th mat-header-cell class="centerText boldText" *matHeaderCellDef>{{globals?.i18n.field.label.filename}}</th>
          <td mat-cell class="centerText" *matCellDef="let data" >{{data.fileName}}</td>
        </ng-container>
        <ng-container matColumnDef="comments">
          <th mat-header-cell class="centerText boldText" *matHeaderCellDef>{{globals?.i18n.field.label.commentonly}}</th>
          <td mat-cell class="centerText" *matCellDef="let data" >{{data.comments}}</td>
        </ng-container>
        <ng-container matColumnDef="checkbox">
          <th mat-header-cell class="centerText boldText" *matHeaderCellDef>{{globals?.i18n.field.label.uploadsuccess}}</th>
          <td mat-cell class="centerText" *matCellDef="let data" >
            <mat-checkbox class="centerText" [checked]="true" [disabled]="true"></mat-checkbox>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="getDisplayColumns(); sticky:true" class="tableHeader1 blueBack">
        <tr mat-row *matRowDef="let row; columns: getDisplayColumns();"
            class="tableRow"></tr>
      </table>
    </div>

    <!-- Upload popup -->
    <div class="rowContainer centeredFlex">
      <app-button class="margin" type="primary" value="{{globals.i18n.application.button.addAFile}}"
                  (click)="openUploadPopup()"
                  [disabled]="loadingUpload"
      ></app-button>
    </div>
    <div class="rowContainer centeredFlex">
      <app-screen-message [errors]="screenErrors" [messages]="screenMessages"></app-screen-message>
    </div>
<!--    <div class="rowContainer centeredFlex">-->
<!--      <p>Counter: {{fileCounter}} / {{numberOfFilesLimit}}</p>-->
<!--    </div>-->
<!--    <div class="rowContainer centeredFlex">-->
<!--      <p>Size: {{sizeCounter}} / {{sizeLimit}}</p>-->
<!--    </div>-->

    <div *ngIf="innerWidth <= 800">
      <br>
      <br>
      <br>
      <br>
      <br>
    </div>

  </app-expandable>
</div>
